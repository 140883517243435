import PropTypes from 'prop-types';
import React from 'react';
import {navigate} from 'shared-utils';

import header from 'mindset-mentor/src/assets/images/header/img-header-visualisierung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Image from 'shared-ui/components/Elements/Image';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import {cardRadius, queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import Video from 'components/Elements/Video';
import References from 'components/Layout/References';

import {page, pageFields, pageRoute} from 'routes/pages';

import {lang} from 'lang/global.lang';

import {coursesSteps} from 'data/courses-list.data';

import {usePostCompletedSteps} from 'services/data-hooks/course/usePostCompletedSteps';

import {useCurrentIdentifier} from 'hooks/useCurrentIdentifier';

const VisualizationTemplate = ({
    identifier,
    step,
    handleCompletedStepsMutate,
}) => {
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    const currentCourse = coursesSteps[step];
    if (!currentCourse) return null;

    return (
        <PageLayout
            pageName="Visualisierung"
            pageIcon="calendar"
            subLine="Stell dir mal vor ..."
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {currentCourse.mainTitle[identifier]}
                </Headline>
                <Paragraph textAlign={textAlign}>
                    {currentCourse.mainParagraph[identifier]}
                </Paragraph>
                <Paragraph bold margin="16px 0" textAlign={textAlign}>
                    {currentCourse.mainDescription[identifier]}
                </Paragraph>
                <Paragraph textAlign={textAlign}>
                    {currentCourse.subDescription[identifier]}
                </Paragraph>
                <Headline type="h4" textAlign={textAlign}>
                    {currentCourse.imageTitle[identifier]}
                </Headline>
                <Flex justifyContent="center" width="100%">
                    <Card>
                        <Image
                            src={currentCourse.image[identifier]}
                            alt="current-course"
                            radius={cardRadius}
                        />
                    </Card>
                </Flex>

                <Paragraph margin="32px 0 16px 0" textAlign={textAlign}>
                    {currentCourse.imageDescription[identifier]}
                </Paragraph>
                <Headline type="h4" textAlign={textAlign}>
                    {currentCourse.videoTitle[identifier]}
                </Headline>
                <Flex justifyContent="center" width="100%">
                    <Card>
                        <Video videoId={currentCourse.videoId[identifier]} />
                        <Headline type="h4">
                            {currentCourse.videoSubTitle[identifier]}
                        </Headline>
                        <Paragraph>
                            {currentCourse.videoSubParagraph[identifier]}
                        </Paragraph>
                    </Card>
                </Flex>
                <Headline
                    type="h4"
                    marginBottom="48px"
                    marginTop="48px"
                    textAlign={textAlign}
                >
                    {currentCourse.bottomTitle[identifier]}
                </Headline>
                <Button
                    label={lang.onToTheVision}
                    onClick={handleCompletedStepsMutate}
                    maxWidth="320px"
                    margin="0 auto"
                />

                <References items={currentCourse.references[identifier]} />
            </Flex>
        </PageLayout>
    );
};

VisualizationTemplate.propTypes = {
    identifier: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    handleCompletedStepsMutate: PropTypes.func.isRequired,
};

export const ConnectedVisualizationTemplate = ({step}) => {
    const {mutate: completedStepsMutate} = usePostCompletedSteps();
    const {identifier} = useCurrentIdentifier();

    const completedStep = pageFields.chapter1.visualization;

    if (!identifier) {
        return null;
    }

    const handleCompletedStepsMutate = () => {
        completedStepsMutate(completedStep, {
            onSuccess: () => {
                navigate(pageRoute.courseWeekItem(page.vision));
            },
        });
    };

    return (
        <VisualizationTemplate
            identifier={identifier}
            step={step}
            handleCompletedStepsMutate={handleCompletedStepsMutate}
        />
    );
};

ConnectedVisualizationTemplate.propTypes = {
    step: PropTypes.string.isRequired,
};

export default VisualizationTemplate;
