import React from 'react';

export const step3_2 = {
    mainTitle: {
        fitness: 'Was könnte dich von der Zielerreichung abhalten?',
        nutrition: 'Was könnte dich von der Zielerreichung abhalten?',
        relax: 'Was könnte dich von der Zielerreichung abhalten?',
        energy: 'Was könnte dich von der Zielerreichung abhalten?',
        sleep: 'Was könnte dich von der Zielerreichung abhalten?',
        balance: 'Was könnte dich von der Zielerreichung abhalten?',
    },
    mainParagraph: {
        fitness: (
            <>
                Um deiner Zielerreichung eine höchstmögliche Chance zu bieten,
                ist es wichtig, dass du dir bereits jetzt zu überlegst, was dich
                von deinen Zielen abhalten könnte. So kannst du direkt
                Lösungsideen entwickeln und deinem „inneren Schweinehund“
                entschlossen entgegentreten.
                <br />
                <br />
                Überlege genau, was dich sonst von gesunden Verhaltensweisen
                abhält? In welchen Situationen lässt deine Motivation nach?
                <br />
                <br />
                Trage nun die möglichen Hürden in das Textfeld ein. Du kannst
                auch gerne die Vorschläge verwenden. Wähle ruhig ein paar mehr
                Hürden aus als zu wenig, damit du gut gewappnet bist.
            </>
        ),
        nutrition: (
            <>
                Um deiner Zielerreichung eine höchstmögliche Chance zu bieten,
                ist es wichtig, dass du dir bereits jetzt zu überlegst, was dich
                von deinen Zielen abhalten könnte. So kannst du direkt
                Lösungsideen entwickeln und deinem „inneren Schweinehund“
                entschlossen entgegentreten.
                <br />
                <br />
                Überlege genau, was dich sonst von gesunden Verhaltensweisen
                abhält? In welchen Situationen lässt deine Motivation nach?
                <br />
                <br />
                Trage nun die möglichen Hürden in das Textfeld ein. Du kannst
                auch gerne die Vorschläge verwenden. Wähle ruhig ein paar mehr
                Hürden aus als zu wenig, damit du gut gewappnet bist.
            </>
        ),
        relax: (
            <>
                Um deiner Zielerreichung eine höchstmögliche Chance zu bieten,
                ist es wichtig, dass du dir bereits jetzt zu überlegst, was dich
                von deinen Zielen abhalten könnte. So kannst du direkt
                Lösungsideen entwickeln und deinem „inneren Schweinehund“
                entschlossen entgegentreten.
                <br />
                <br />
                Überlege genau, was dich sonst von gesunden Verhaltensweisen
                abhält? In welchen Situationen lässt deine Motivation nach?
                <br />
                <br />
                Trage nun die möglichen Hürden in das Textfeld ein. Du kannst
                auch gerne die Vorschläge verwenden. Wähle ruhig ein paar mehr
                Hürden aus als zu wenig, damit du gut gewappnet bist.
            </>
        ),
        energy: (
            <>
                Um deiner Zielerreichung eine höchstmögliche Chance zu bieten,
                ist es wichtig, dass du dir bereits jetzt zu überlegst, was dich
                von deinen Zielen abhalten könnte. So kannst du direkt
                Lösungsideen entwickeln und deinem „inneren Schweinehund“
                entschlossen entgegentreten.
                <br />
                <br />
                Überlege genau, was dich sonst von gesunden Verhaltensweisen
                abhält? In welchen Situationen lässt deine Motivation nach?
                <br />
                <br />
                Trage nun die möglichen Hürden in das Textfeld ein. Du kannst
                auch gerne die Vorschläge verwenden. Wähle ruhig ein paar mehr
                Hürden aus als zu wenig, damit du gut gewappnet bist.
            </>
        ),
        sleep: (
            <>
                Um deiner Zielerreichung eine höchstmögliche Chance zu bieten,
                ist es wichtig, dass du dir bereits jetzt zu überlegst, was dich
                von deinen Zielen abhalten könnte. So kannst du direkt
                Lösungsideen entwickeln und deinem „inneren Schweinehund“
                entschlossen entgegentreten.
                <br />
                <br />
                Überlege genau, was dich sonst von gesunden Verhaltensweisen
                abhält? In welchen Situationen lässt deine Motivation nach?
                <br />
                <br />
                Trage nun die möglichen Hürden in das Textfeld ein. Du kannst
                auch gerne die Vorschläge verwenden. Wähle ruhig ein paar mehr
                Hürden aus als zu wenig, damit du gut gewappnet bist.
            </>
        ),
        balance: (
            <>
                Um deiner Zielerreichung eine höchstmögliche Chance zu bieten,
                ist es wichtig, dass du dir bereits jetzt zu überlegst, was dich
                von deinen Zielen abhalten könnte. So kannst du direkt
                Lösungsideen entwickeln und deinem „inneren Schweinehund“
                entschlossen entgegentreten.
                <br />
                <br />
                Überlege genau, was dich sonst von gesunden Verhaltensweisen
                abhält? In welchen Situationen lässt deine Motivation nach?
                <br />
                <br />
                Trage nun die möglichen Hürden in das Textfeld ein. Du kannst
                auch gerne die Vorschläge verwenden. Wähle ruhig ein paar mehr
                Hürden aus als zu wenig, damit du gut gewappnet bist.
            </>
        ),
    },
    actionCardOptions: {
        fitness: [
            {
                id: 1,
                value: 'Unerwartete Termine',
            },
            {
                id: 2,
                value: 'keine nötigen Utensilien vorhanden',
            },
            {
                id: 3,
                value: 'keine Lust auf die Aktivität',
            },
            {
                id: 4,
                value: 'zu viel zu tun',
            },
            {
                id: 5,
                value: 'eine vollen Terminkalender',
            },
            {
                id: 6,
                value: 'andere Sachen zu erledigen',
            },
            {
                id: 7,
                value: 'keine Lust beim dem Wetter',
            },
            {
                id: 8,
                value: 'keine Zeit',
            },
            {
                id: 9,
                value: 'Lust auf Schokolade und eine gemütliche Zeit auf der Couch',
            },
            {
                id: 10,
                value: 'Lust auf Enspannung',
            },
        ],
        nutrition: [
            {
                id: 1,
                value: 'Unerwartete Termine',
            },
            {
                id: 2,
                value: 'keine nötigen Utensilien vorhanden',
            },
            {
                id: 3,
                value: 'keine Lust auf die Aktivität',
            },
            {
                id: 4,
                value: 'zu viel zu tun',
            },
            {
                id: 5,
                value: 'eine vollen Terminkalender',
            },
            {
                id: 6,
                value: 'andere Sachen zu erledigen',
            },
            {
                id: 7,
                value: 'keine Lust beim dem Wetter',
            },
            {
                id: 8,
                value: 'keine Zeit',
            },
            {
                id: 9,
                value: 'Lust auf Schokolade und eine gemütliche Zeit auf der Couch',
            },
            {
                id: 10,
                value: 'Lust auf Enspannung',
            },
        ],
        relax: [
            {
                id: 1,
                value: 'Unerwartete Termine',
            },
            {
                id: 2,
                value: 'keine nötigen Utensilien vorhanden',
            },
            {
                id: 3,
                value: 'keine Lust auf die Aktivität',
            },
            {
                id: 4,
                value: 'zu viel zu tun',
            },
            {
                id: 5,
                value: 'eine vollen Terminkalender',
            },
            {
                id: 6,
                value: 'andere Sachen zu erledigen',
            },
            {
                id: 7,
                value: 'keine Lust beim dem Wetter',
            },
            {
                id: 8,
                value: 'keine Zeit',
            },
            {
                id: 9,
                value: 'Lust auf Schokolade und eine gemütliche Zeit auf der Couch',
            },
            {
                id: 10,
                value: 'Lust auf Enspannung',
            },
        ],
        energy: [
            {
                id: 1,
                value: 'Unerwartete Termine',
            },
            {
                id: 2,
                value: 'keine nötigen Utensilien vorhanden',
            },
            {
                id: 3,
                value: 'keine Lust auf die Aktivität',
            },
            {
                id: 4,
                value: 'zu viel zu tun',
            },
            {
                id: 5,
                value: 'eine vollen Terminkalender',
            },
            {
                id: 6,
                value: 'andere Sachen zu erledigen',
            },
            {
                id: 7,
                value: 'keine Lust beim dem Wetter',
            },
            {
                id: 8,
                value: 'keine Zeit',
            },
            {
                id: 9,
                value: 'Lust auf Schokolade und eine gemütliche Zeit auf der Couch',
            },
            {
                id: 10,
                value: 'Lust auf Enspannung',
            },
        ],
        sleep: [
            {
                id: 1,
                value: 'Unerwartete Termine',
            },
            {
                id: 2,
                value: 'keine nötigen Utensilien vorhanden',
            },
            {
                id: 3,
                value: 'keine Lust auf die Aktivität',
            },
            {
                id: 4,
                value: 'zu viel zu tun',
            },
            {
                id: 5,
                value: 'eine vollen Terminkalender',
            },
            {
                id: 6,
                value: 'andere Sachen zu erledigen',
            },
            {
                id: 7,
                value: 'keine Lust beim dem Wetter',
            },
            {
                id: 8,
                value: 'keine Zeit',
            },
            {
                id: 9,
                value: 'Lust auf Schokolade und eine gemütliche Zeit auf der Couch',
            },
            {
                id: 10,
                value: 'Lust auf Enspannung',
            },
        ],
        balance: [
            {
                id: 1,
                value: 'Unerwartete Termine',
            },
            {
                id: 2,
                value: 'keine nötigen Utensilien vorhanden',
            },
            {
                id: 3,
                value: 'keine Lust auf die Aktivität',
            },
            {
                id: 4,
                value: 'zu viel zu tun',
            },
            {
                id: 5,
                value: 'eine vollen Terminkalender',
            },
            {
                id: 6,
                value: 'andere Sachen zu erledigen',
            },
            {
                id: 7,
                value: 'keine Lust beim dem Wetter',
            },
            {
                id: 8,
                value: 'keine Zeit',
            },
            {
                id: 9,
                value: 'Lust auf Schokolade und eine gemütliche Zeit auf der Couch',
            },
            {
                id: 10,
                value: 'Lust auf Enspannung',
            },
        ],
    },
    subParagraph: {
        fitness: (
            <>
                Je intensiver du dich mit dir selbst auseinandersetzt und dir
                deine Handlungen bewusst machst, desto genauer und
                erfolgsorientierter kannst du planen.
            </>
        ),
        nutrition: (
            <>
                Je intensiver du dich mit dir selbst auseinandersetzt und dir
                deine Handlungen bewusst machst, desto genauer und
                erfolgsorientierter kannst du planen.
            </>
        ),
        relax: (
            <>
                Je intensiver du dich mit dir selbst auseinandersetzt und dir
                deine Handlungen bewusst machst, desto genauer und
                erfolgsorientierter kannst du planen.
            </>
        ),
        energy: (
            <>
                Je intensiver du dich mit dir selbst auseinandersetzt und dir
                deine Handlungen bewusst machst, desto genauer und
                erfolgsorientierter kannst du planen.
            </>
        ),
        sleep: (
            <>
                Je intensiver du dich mit dir selbst auseinandersetzt und dir
                deine Handlungen bewusst machst, desto genauer und
                erfolgsorientierter kannst du planen.
            </>
        ),
        balance: (
            <>
                Je intensiver du dich mit dir selbst auseinandersetzt und dir
                deine Handlungen bewusst machst, desto genauer und
                erfolgsorientierter kannst du planen.
            </>
        ),
    },
};
