import PropTypes from 'prop-types';
import React from 'react';
import {ErrorBoundary as ErrorBoundaryCore} from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { navigate } from 'shared-utils';



import Button from 'shared-ui/components/Elements/Button';
import Flex from 'shared-ui/components/Layout/Flex';



import { pageRoute } from 'routes/pages';



const ErrorBoundary = ({children}) => (
    <QueryErrorResetBoundary>
        {({reset}) => (
            <ErrorBoundaryCore
                onReset={reset}
                fallbackRender={({resetErrorBoundary}) => (
                    <Flex
                        flexDirection="column"
                        alignItems="center"
                        marginLeft="auto"
                        marginRight="auto"
                    >
                        <Button
                            label="versuchen Sie es nochmal"
                            maxWidth="320px"
                            margin="0 0 16px"
                            onClick={() => {
                                resetErrorBoundary();
                            }}
                        />
                        <Button
                            label="Zurück zur Startseite"
                            maxWidth="320px"
                            margin="0 0 16px"
                            onClick={() => {
                                navigate(pageRoute.mainPath);
                            }}
                        />
                    </Flex>
                )}
            >
                {children}
            </ErrorBoundaryCore>
        )}
    </QueryErrorResetBoundary>
);

ErrorBoundary.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ErrorBoundary;