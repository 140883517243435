import {useCallback, useEffect, useState} from 'react';

import {calculateServerDate} from 'helpers/server-date';

export const useCalendarDate = (dayLimit) => {
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [serverDate, setServerDate] = useState(null);
    const [today, setToday] = useState(null);
    const [tomorrow, setTomorrow] = useState(null);
    const [nextDays, setNextDays] = useState(null);

    const handleOnChange = (currentDate) => {
        setCalendarDate(currentDate);
    };

    const fetchData = useCallback(async () => {
        const date = await calculateServerDate();
        setServerDate(date);
        setToday(new Date(date));

        const tomorrow = new Date(
            new Date(date).setDate(new Date(date).getDate() + 1)
        );
        setTomorrow(tomorrow);

        const nextDays = new Date(
            new Date(date).setDate(new Date(date).getDate() + dayLimit)
        );
        setCalendarDate(tomorrow);
        setNextDays(nextDays);
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    return {
        serverDate,
        calendarDate,
        handleOnChange,
        today,
        tomorrow,
        nextDays,
    };
};
