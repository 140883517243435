import styled, {css} from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Icon from 'shared-ui/components/Elements/Icon';
import {color, queries, shadow} from 'shared-ui/styles/variables';

export const StyledCard = styled(Card)`
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    min-height: 86px;
    cursor: pointer;
    padding-right: 64px;
    @media ${queries.mdMax} {
        margin-bottom: 15px;
    }
    ${({isDisabled}) =>
        isDisabled &&
        css`
            opacity: 0.5;
            border: none !important;
            background: white !important;
            cursor: not-allowed;
        `}
`;

export const StyledIcon = styled(Icon)`
    margin-right: 20px;
`;

export const CheckMark = styled.div`
    border-radius: 10px;
    box-shadow: ${shadow};
    background-color: ${color.alertGreen};
    height: 40px;
    width: 40px;
    position: absolute;
    right: 32px;

    &::after {
        content: '';
        position: absolute;
        left: 16px;
        top: 9px;
        width: 8px;
        height: 16px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg) scale(1.5);
        -ms-transform: rotate(45deg) scale(1.5);
        transform: rotate(45deg) scale(1.5);
    }
`;
