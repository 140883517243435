import {useMutation} from 'react-query';
import {notify} from 'shared-utils';

import {completedSteps} from 'services/config/endpoints';
import {makeRequest} from 'services/config/mindset-mentor-api';
import {COMPLETED_STEPS} from 'services/config/query-keys';

import {useSetQueryData} from 'hooks/useSetQueryData';

import {setErrorMessage} from 'helpers/error-message';

const mutateCompletedSteps = async (values) => {
    const data = {
        step_identifier: values,
    };
    const response = await makeRequest(completedSteps, 'POST', data);
    return response;
};

export function usePostCompletedSteps() {
    const {setData, getData} = useSetQueryData();

    const mutate = useMutation((values) => mutateCompletedSteps(values), {
        onError: (error, variables, previousValue) => {
            const errorMessage = setErrorMessage(error);
            notify.show(errorMessage, 'error');
            setData(COMPLETED_STEPS, previousValue);
        },
        onMutate: async (data) => {
            const cacheData = await getData(COMPLETED_STEPS);
            const previousValue = cacheData || [];
            const newCompletedStepsData = [
                ...new Set([...previousValue, data]),
            ];
            setData([COMPLETED_STEPS], newCompletedStepsData);

            return previousValue;
        },
    });
    return mutate;
}
