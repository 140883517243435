import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Headline from 'shared-ui/components/Elements/Headline';
import Flex from 'shared-ui/components/Layout/Flex';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {reminder} from 'lang/settings.lang';

import {StyledInput, StyledIcon} from './styles';

import {color, queries} from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import CheckBox from 'shared-ui/components/Elements/CheckBox';
import Button from 'shared-ui/components/Elements/Button';
import {usePatchReminderSettings} from 'services/data-hooks/settings/usePatchReminderSettings';
import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';
import {useNotificationsReducer} from 'hooks/useNotificationsReducer';

const ReminderSettings = ({
    handleMutateReminder,
    isLoading,
    emailNotification,
    emailTime,
    smsNotification,
    smsTime,
    isPrivacyPolicy,
    isErrorPrivacyPolicy,
}) => {
    const {notifications, setNotifications} = useNotificationsReducer({
        emailNotification,
        emailTime,
        smsNotification,
        smsTime,
        isPrivacyPolicy,
    });

    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    return (
        <>
            <Headline type="h4" textAlign={textAlign}>
                {reminder.mainTitle}
            </Headline>
            <Paragraph textAlign={textAlign} bold>
                {reminder.mainParagraph}
            </Paragraph>

            <StyledIcon name="envelope" color={color.red} size="80px" />
            <Paragraph textAlign={textAlign}>
                {reminder.emailText}
                <StyledInput
                    theme="secondary"
                    type="time"
                    width="128px"
                    value={notifications.emailTime}
                    onChange={(value) => setNotifications({emailTime: value})}
                />
                {reminder.clock}
            </Paragraph>
            <CheckBox
                label={reminder.reminderEmail}
                theme="black"
                isLabelSuffix
                checked={notifications.emailNotification}
                onChange={(e) =>
                    setNotifications({emailNotification: e.target.checked})
                }
            />

            <StyledIcon name="mobilePhone" color={color.red} size="64px" />
            <Paragraph textAlign={textAlign}>
                {reminder.smsText}
                <StyledInput
                    theme="secondary"
                    type="tel"
                    width="192px"
                    placeholder="Ihre Handynummer"
                />
                {reminder.at}
                <StyledInput
                    theme="secondary"
                    type="time"
                    width="128px"
                    value={notifications.smsTime}
                    onChange={(value) => setNotifications({smsTime: value})}
                />
                {reminder.clock}
            </Paragraph>

            <Flex
                width="100%"
                flexDirection={isMd ? 'row' : 'column'}
                justifyContent={isMd ? 'space-around' : 'flex-start'}
            >
                <CheckBox
                    label={reminder.reminderSms}
                    theme="black"
                    isLabelSuffix
                    checked={notifications.smsNotification}
                    onChange={(e) =>
                        setNotifications({smsNotification: e.target.checked})
                    }
                />

                <CheckBox
                    label={reminder.dataPrivacy}
                    theme="black"
                    isLabelSuffix
                    checked={notifications.isPrivacyPolicy}
                    onChange={(e) =>
                        setNotifications({isPrivacyPolicy: e.target.checked})
                    }
                />
            </Flex>

            <Button
                label="Bestätigen"
                maxWidth="320px"
                margin="32px auto"
                disabled={isLoading}
                onClick={() => handleMutateReminder(notifications)}
            />

            {isErrorPrivacyPolicy && (
                <Paragraph theme="red">
                    Bitte akzeptieren Sie die Datenschutzbestimmung
                </Paragraph>
            )}
        </>
    );
};

export const ConnectedReminderSettings = () => {
    const [isErrorPrivacyPolicy, setIsErrorPrivacyPolicy] = useState(false);
    const {mutate, isLoading} = usePatchReminderSettings();
    const {data} = useGetCurrentWeek();

    const handleMutateReminder = (notifications) => {
        if (notifications.isPrivacyPolicy) {
            mutate(notifications);
            setIsErrorPrivacyPolicy(false);
        } else {
            setIsErrorPrivacyPolicy(true);
        }
    };

    if (!data) return null;

    const isPrivacyPolicy =
        !data.emailNotification &&
        !data.emailTime &&
        !data.smsNotification &&
        !data.smsTime
            ? false
            : true;

    return (
        <ReminderSettings
            handleMutateReminder={handleMutateReminder}
            isLoading={isLoading}
            emailNotification={data.emailNotification}
            emailTime={data.emailTime}
            smsNotification={data.smsNotification}
            smsTime={data.smsTime}
            isPrivacyPolicy={isPrivacyPolicy}
            isErrorPrivacyPolicy={isErrorPrivacyPolicy}
        />
    );
};

export default ReminderSettings;

ReminderSettings.propTypes = {
    handleMutateReminder: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    emailNotification: PropTypes.bool.isRequired,
    emailTime: PropTypes.string.isRequired,
    smsNotification: PropTypes.bool.isRequired,
    smsTime: PropTypes.string.isRequired,
    isPrivacyPolicy: PropTypes.bool.isRequired,
    isErrorPrivacyPolicy: PropTypes.bool.isRequired,
};
