export const page = {
    mainPath: 'mindset-mentor',
    settings: 'einstellungen',
    cancelCourse: 'kurs-abbrechen',
    serviceCenter: 'service-center',
    courseStartPage: 'course-start-page',
    courseDashboard: 'course-dashboard',
    reminderSettings: 'reminder-settings',
    courseWeek: 'course-week',
    visualization: 'visualization',
    vision: 'vision',
    lessonTarget: 'lesson-target',
    individualAction: 'individual-action',
    habitCheck1: 'habit-check-1',
    habitCheck2: 'habit-check-2',
    timePlan: 'time-plan',
    smartTarget: 'smart-target',
    rubicon: 'rubicon',
    goalPlanning: 'goal-planning',
    ifPlanning: 'if-planning',
    thenPlanning: 'then-planning',
    firstSteps: 'first-steps',
    generationPlan: 'generation-plan',
    weeksOfPreparation: 'weeks-of-preparation',
    evaluation: 'evaluation',
    award: 'award',
    startDate: 'start-date',
    overviewWeekStart: 'overview-week-start',
    bulletBoard: 'pinnwand',
    motivation: 'motivation',
    preparationEnd: 'preparation-end',
    reflection: 'reflection',

};
export const pageRoute = {
    mainPath: '/mindset-mentor',
    cancelCourse: `/${page.mainPath}/${page.cancelCourse}`,
    serviceCenter: 'service-center',
    settings: `/${page.mainPath}/${page.settings}`,
    courseStartPage: `/${page.mainPath}/${page.courseStartPage}`,
    courseDashboard: `/${page.mainPath}/${page.courseDashboard}`,
    reminderSettings: `/${page.mainPath}/${page.reminderSettings}`,
    courseWeek: `/${page.mainPath}/${page.courseWeek}`,
    individualVision: `/${page.mainPath}/${page.courseWeek}/${page.vision}`,
    settingGoal: `/${page.mainPath}/${page.courseWeek}/${page.lessonTarget}`,
    individualAction: `/${page.mainPath}/${page.courseWeek}/${page.individualAction}`,
    habitCheck1: `/${page.mainPath}/${page.courseWeek}/${page.habitCheck1}`,
    habitCheck2: `/${page.mainPath}/${page.courseWeek}/${page.habitCheck2}`,
    goalSpeed: `/${page.mainPath}/${page.courseWeek}/${page.timePlan}`,
    target: `/${page.mainPath}/${page.courseWeek}/${page.smartTarget}`,
    rubicon: `/${page.mainPath}/${page.courseWeek}/${page.rubicon}`,
    goalPlanning: `/${page.mainPath}/${page.courseWeek}/${page.goalPlanning}`,
    ifPlanning: `/${page.mainPath}/${page.courseWeek}/${page.ifPlanning}`,
    thenPlanning: `/${page.mainPath}/${page.courseWeek}/${page.thenPlanning}`,
    firstSteps: `/${page.mainPath}/${page.courseWeek}/${page.firstSteps}`,
    evaluation: `/${page.mainPath}/${page.courseWeek}/${page.evaluation}`,
    award: `/${page.mainPath}/${page.courseWeek}/${page.award}`,
    startDate: `/${page.mainPath}/${page.courseWeek}/${page.startDate}`,
    bulletBoard: `/${page.mainPath}/${page.courseWeek}/${page.bulletBoard}`,
    overviewWeekStart: `/${page.mainPath}/${page.courseWeek}/${page.overviewWeekStart}`,
    motivation: `/${page.mainPath}/${page.courseWeek}/${page.motivation}`,
    preparationEnd: `/${page.mainPath}/${page.courseWeek}/${page.preparationEnd}`,
    reflection: `/${page.mainPath}/${page.courseWeek}/${page.reflection}`,
    courseWeekItem: (slug) =>
        `/${page.mainPath}/${page.courseWeek}/${page[slug] ?? slug}`,
};

export const pageFields = {
    chapterPersonInfos: {
        dashboard: 'dashboard',
    },
    chapter1: {
        vision: 'vision',
        visualization: 'visualization',
        lessonTarget: 'lesson_target',
    },
    chapter2: {
        individualAction: 'habit',
        timePlan: 'habit_check1',
        goal: 'goal',
        smartTarget: 'amounts',
        rubicon: 'rubicon',
    },
    chapter3: {
        goalPlanning: 'goal_planing',
        ifPlanning: 'obstacles',
        thenPlanning: 'solutions',
        firstSteps: 'todos',
        startDate: 'start_date',
        overviewWeekStart: 'start_date',
        weeksOfPreparation: 'weeks_of_preparation',
    },
};

export const protectedRoutes = {
    part1Final: pageFields.chapter1.lessonTarget,
    part2Final: pageFields.chapter3.firstSteps,
    steps: [
        pageFields.chapter1.visualization,
        pageFields.chapter1.vision,
        pageFields.chapter1.lessonTarget,
        pageFields.chapter2.individualAction,
        pageFields.chapter2.timePlan,
        pageFields.chapter2.smartTarget,
        pageFields.chapter2.rubicon,
        pageFields.chapter3.goalPlanning,
        pageFields.chapter3.ifPlanning,
        pageFields.chapter3.thenPlanning,
        pageFields.chapter3.firstSteps,
        pageFields.chapter3.startDate,
        pageFields.chapter3.overviewWeekStart,
    ],
};
