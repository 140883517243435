import Check from 'assets/gifs/check.gif';
import React from 'react';
import { navigate } from 'shared-utils';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';
import Button from 'shared-ui/components/Elements/Button';
import Flex from 'shared-ui/components/Layout/Flex';
import { pageRoute } from 'routes/pages';
import { StyledImage } from './styles';

const MotivationPageTemplate = () => {
    return (
        <PageLayout pageName="Yay! Du hast es geschafft!">
            <Flex
                flexDirection="column"
                alignItems="center"
                marginLeft="auto"
                marginRight="auto"
            >
                <StyledImage src={Check} alt="Motivation GIF" />

                <Button
                    label="Jetzt zu meinem Dashboard"
                    maxWidth="320px"
                    margin="0 0 16px"
                    onClick={() => {
                        navigate(pageRoute.preparationEnd);
                    }}
                />
            </Flex>
        </PageLayout>
    );
};

export default MotivationPageTemplate;
