import {useMemo} from 'react';

import {pageFields} from 'routes/pages';

const steps = {
    personalVision: [pageFields.chapter1.vision],
    vorbereitung: [
        pageFields.chapter2.individualAction,
        pageFields.chapter2.timePlan,
        pageFields.chapter2.smartTarget,
        pageFields.chapter2.rubicon,
        pageFields.chapter3.goalPlanning,
        pageFields.chapter3.ifPlanning,
        pageFields.chapter3.thenPlanning,
        pageFields.chapter3.firstSteps,
    ],
};

export const useWeeklyOverviewData = ({
    completedSteps,
    weekNumber,
    startDate,
    confirmedAt,
}) => {
    function isDoneChapter(item) {
        const mapped = steps[item]
            .map((step) => completedSteps.find((x) => x === step))
            .filter(Boolean);
        return mapped.length === steps[item].length;
    }
    const normalizedItems = useMemo(() => {
        const items = [
            {
                label: 'Deine persönliche Vision',
                isActive: true,
                isDone:
                    completedSteps.length > 0
                        ? completedSteps.includes(pageFields.chapter1.vision)
                        : false,
            },
            ...[...Array(4).keys()].slice(1).map((value) => ({
                label: `${value}. Motivationswoche`,
                content: [
                    {
                        label: 'Vorbereitung',
                        link: 'vorbereitung',
                        isActive: weekNumber === value,
                        isDone:
                            weekNumber < value
                                ? false
                                : weekNumber > value
                                ? true
                                : isDoneChapter('vorbereitung'),
                    },
                    {
                        label: 'Umsetzung',
                        link: 'umsetzung',
                        isActive: weekNumber === value,
                        isDone:
                            weekNumber < value
                                ? false
                                : weekNumber > value
                                ? true
                                : !!startDate,
                    },
                    {
                        label: 'Auswertung',
                        link: 'auswertung',
                        isActive: weekNumber === value,
                        isDone:
                            weekNumber < value
                                ? false
                                : weekNumber > value
                                ? true
                                : !!confirmedAt,
                    },
                ],
                isDone: weekNumber > value ? true : false,
            })),
        ];
        return items;
    }, [completedSteps]);

    return {normalizedItems};
};
