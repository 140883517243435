import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
    const [isDisableOnboarding, setIsDisableOnboarding] = useState(null);
    const value = localStorage.getItem('isOnboarding');
    const isOnboarding = value === 'isOnboarding';

    useEffect(() => {
        if (isOnboarding) {
            setIsDisableOnboarding(true);
        }
    }, []);

    return (
        <OnboardingContext.Provider
            value={{ isDisableOnboarding, setIsDisableOnboarding }}
            displayName="Authentication"
        >
            {children}
        </OnboardingContext.Provider>
    );
};

OnboardingProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export const useOnboarding = () => {
    return useContext(OnboardingContext);
};
