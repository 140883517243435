import PropTypes from 'prop-types';
import React from 'react';
import {Draggable} from 'react-beautiful-dnd';

import {StyledBadge} from './styles';

const AnimatedItem = ({value, id, onClick}) => {
    return (
        <Draggable draggableId={value} index={id} type="vision">
            {(provided) => (
                <div
                    onClick={() => {
                        onClick(value, id);
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <StyledBadge text={`... ${value}`} />
                </div>
            )}
        </Draggable>
    );
};

AnimatedItem.propTypes = {
    value: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default AnimatedItem;
