import React from 'react';

const Gold = () => {
    return (
        <svg id="g-a" viewBox="0 0 40 57.73">
            <defs>
                <linearGradient
                    id="g-d"
                    x1="3.89"
                    y1="177.54"
                    x2="7.74"
                    y2="177.54"
                    gradientTransform="translate(0 207.39) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#eb6742" stopOpacity=".2" />
                    <stop offset=".1" stopColor="#eb653f" stopOpacity=".24" />
                    <stop offset=".28" stopColor="#ec6136" stopOpacity=".34" />
                    <stop offset=".49" stopColor="#ed5929" stopOpacity=".51" />
                    <stop offset=".75" stopColor="#ee4e16" stopOpacity=".74" />
                    <stop offset="1" stopColor="#ef4200" />
                </linearGradient>
                <linearGradient
                    id="g-f"
                    x1="62.47"
                    y1="175.37"
                    x2="100.69"
                    y2="175.37"
                    gradientTransform="translate(-61.42 197.71) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#fdd993" />
                    <stop offset=".07" stopColor="#dead60" />
                    <stop offset=".16" stopColor="#cd9443" />
                    <stop offset=".48" stopColor="#fdca6e" />
                    <stop offset=".53" stopColor="#fdd17a" />
                    <stop offset=".61" stopColor="#fee49b" />
                    <stop offset=".64" stopColor="#feeaa4" />
                    <stop offset=".75" stopColor="#fdca6e" />
                    <stop offset=".99" stopColor="#fdd07a" />
                    <stop offset="1" stopColor="#fdd07b" />
                </linearGradient>
            </defs>
            <g id="g-b">
                <g id="g-c">
                    <rect
                        x="4.69"
                        y="2.13"
                        width="3.85"
                        height="55.44"
                        fill="#fff"
                    />
                    <rect
                        x="3.89"
                        y="2.13"
                        width="3.85"
                        height="55.44"
                        fill="#eb6742"
                    />
                    <rect
                        x="3.89"
                        y="2.13"
                        width="3.85"
                        height="55.44"
                        fill="url(#g-d)"
                    />
                    <rect
                        x="3.9"
                        y="35.42"
                        width="3.84"
                        height=".78"
                        fill="#ce4c2e"
                    />
                    <circle cx="5.81" cy="3.84" r="3.42" fill="#ce4c2e" />
                    <circle cx="5.81" cy="3.42" r="3.42" fill="#eb6742" />
                </g>
                <g id="g-e">
                    <path
                        d="M2.33,34.93c-.68,.03-1.25-.5-1.28-1.18,0,0,0,0,0-.01V10.94c.02-.68,.59-1.21,1.27-1.19,0,0,0,0,.01,0H37.77c.53-.01,1.01,.3,1.21,.79,.17,.44,.04,.94-.32,1.25l-10.43,9.35s-.06,.07-.06,.11c-.01,.03-.01,.07,0,.1l10.75,11.62c.31,.33,.4,.81,.22,1.22-.22,.46-.68,.75-1.19,.74H2.33Z"
                        fill="url(#g-f)"
                    />
                    <path
                        d="M37.77,10.25c.37-.05,.72,.21,.77,.58,.03,.22-.05,.44-.21,.59l-10.43,9.35c-.27,.22-.3,.62-.08,.89,0,.01,.02,.02,.03,.03l10.74,11.62c.25,.28,.22,.71-.07,.96-.15,.13-.34,.19-.53,.16H2.33c-.41,.02-.75-.29-.78-.69V10.94c.03-.4,.37-.71,.78-.69H37.77m0-1H2.33c-.96-.02-1.75,.73-1.78,1.69v22.8c.03,.96,.82,1.71,1.78,1.69H37.99c.7,.02,1.34-.37,1.64-1,.27-.59,.15-1.29-.3-1.76l-10.5-11.35,10.16-9.12c.52-.45,.7-1.17,.45-1.81-.27-.68-.94-1.12-1.67-1.11v-.03Z"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Gold;
