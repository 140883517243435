import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import {color, fastTransition, transition} from 'shared-ui/styles/variables';

export const StyledCard = styled(Card)`
    display: block;
    position: relative;
    transition: ${transition};
    max-height: ${(props) => (props.isOpen ? '216px' : '72px')};
    height: 100%;
    overflow: hidden;
`;

export const StyledDiv = styled.div`
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    transition: ${transition};
`;

export const Arrow = styled.div`
    position: absolute;
    top: 28px;
    right: 16px;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid ${color.red};
    border-radius: 4px;
    transition: ${fastTransition};
    transform: ${(props) => `rotate(${props.isOpen ? '180deg' : '0'})`};
`;
