import PropTypes from 'prop-types';
import React from 'react';
import {navigate} from 'shared-utils';

import header from 'mindset-mentor/src/assets/images/header/img-header-zielgeschwindigkeit.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import {queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import List from 'components/Elements/List';
import InfoListing from 'components/Layout/InfoListing';

import {page, pageFields, pageRoute} from 'routes/pages';

import {coursesSteps} from 'data/courses-list.data';

import {usePostCompletedSteps} from 'services/data-hooks/course/usePostCompletedSteps';

import {useCurrentIdentifier} from 'hooks/useCurrentIdentifier';

const GoalSpeed = ({identifier, step, handleCompletedStepsMutate}) => {
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    const currentCourse = coursesSteps[step];
    if (!currentCourse) return null;

    return (
        <PageLayout
            pageName="Zielgeschwindigkeit"
            pageIcon="pen"
            subLine="Zeit einplanen"
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {currentCourse.mainTitle[identifier]}
                </Headline>
                <Paragraph margin="0 0 16px 0" textAlign={textAlign}>
                    {currentCourse.mainParagraph[identifier]}
                </Paragraph>
                <List
                    numeric
                    italic
                    bold
                    items={currentCourse.listItems[identifier]}
                />
                <Paragraph margin="16px 0 16px 0" textAlign={textAlign}>
                    {currentCourse.subParagraph[identifier]}
                </Paragraph>
            </Flex>
            <InfoListing
                items={currentCourse.infoListItems[identifier]}
                iconShadow={isMd}
            />
            <Flex justifyContent="center" width="100%" marginTop="48px">
                <Button
                    label="Weiter zum SMART-Ziel"
                    maxWidth="320px"
                    onClick={handleCompletedStepsMutate}
                />
            </Flex>
        </PageLayout>
    );
};

GoalSpeed.propTypes = {
    identifier: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    handleCompletedStepsMutate: PropTypes.func.isRequired,
};

export const ConnectedGoalSpeed = ({step}) => {
    const {mutate: completedStepsMutate} = usePostCompletedSteps();
    const {identifier} = useCurrentIdentifier();
    const completedStep = pageFields.chapter2.timePlan;

    if (!identifier) {
        return null;
    }

    const handleCompletedStepsMutate = () => {
        completedStepsMutate(completedStep, {
            onSuccess: () => {
                navigate(pageRoute.courseWeekItem(page.smartTarget));
            },
        });
    };

    return (
        <GoalSpeed
            identifier={identifier}
            step={step}
            handleCompletedStepsMutate={handleCompletedStepsMutate}
        />
    );
};

ConnectedGoalSpeed.propTypes = {
    step: PropTypes.string.isRequired,
};

export default GoalSpeed;
