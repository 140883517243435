import PropTypes from 'prop-types';
import React from 'react';
import header from 'mindset-mentor/src/assets/images/header/img-header-auswertung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';
import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import { queries } from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import { ConnectedNotificationSettings } from 'components/Layout/NotificationSettings';
import { evaluation } from 'lang/global.lang';
import { useGetCurrentUser } from 'services/data-hooks/user/useGetCurrentUser';

const Evaluation = ({ userName }) => {
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    return (
        <PageLayout
            pageName="Auswertung"
            pageIcon="fireWorks"
            pageImage={header}
            subLine="Deine Auswertung"
        >
            <Flex
                flexDirection="column"
                alignItems="center"
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline marginTop="0" textAlign={textAlign}>
                    {evaluation.mainTitle(userName)}
                </Headline>
                <Paragraph textAlign={textAlign}>
                    {evaluation.mainParagraph}
                </Paragraph>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Button
                        label="Erfolge nachtragen"
                        maxWidth="320px"
                        margin="32px 0 16px"
                        theme="outline"
                        onClick={() => {}}
                    />

                    <Button
                        label="Auswertung anzeigen"
                        maxWidth="320px"
                        onClick={() => {}}
                    />
                </Flex>
                <Paragraph margin="32px 0" textAlign={textAlign}>
                    {evaluation.subParagraph}
                </Paragraph>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Button
                        label="Woche 2 starten"
                        maxWidth="320px"
                        margin="0 0 16px"
                        onClick={() => {}}
                    />
                    <Button
                        label="Ziele für Woche 2 ändern"
                        maxWidth="320px"
                        theme="outline"
                        onClick={() => {}}
                    />
                </Flex>
                <ConnectedNotificationSettings />
            </Flex>
        </PageLayout>
    );
};

Evaluation.propTypes = {
    userName: PropTypes.string.isRequired,
};

export const ConnectedEvaluation = () => {
    const { data: currentUserData } = useGetCurrentUser();

    if (!currentUserData) {
        return null;
    }

    return <Evaluation userName={currentUserData.userName} />;
};

export default Evaluation;
