import {useQuery} from 'react-query';

import {makeRequest as requestAccountData} from 'services/config/account-api';
import {currentUser, currentWeek} from 'services/config/endpoints';
import {makeRequest} from 'services/config/mindset-mentor-api';
import {CURRENT_WEEK} from 'services/config/query-keys';
import {normalizeCurrentWeek} from 'services/normalize/current-week';
import {normalizeAccount} from 'services/normalize/normalize-account';

const getCurrentWeek = async () => {
    const response = await makeRequest(currentWeek, 'GET');
    const accountResponse = await requestAccountData(currentUser, 'GET');
    const accountNormalize = await normalizeAccount(accountResponse);
    const currentWeekNormalized = await normalizeCurrentWeek(response);
    const normalized = {
        ...accountNormalize,
        ...currentWeekNormalized,
    };
    return normalized;
};

export function useGetCurrentWeek() {
    return useQuery([CURRENT_WEEK], getCurrentWeek, {
        staleTime: Infinity,
    });
}
