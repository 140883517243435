import {useMutation} from 'react-query';

import {notify} from 'shared-utils';

import {startCourse} from 'services/config/endpoints';
import {makeRequest} from 'services/config/mindset-mentor-api';

import {setErrorMessage} from 'helpers/error-message';

const mutateCurrentCourse = async (values) => {
    const response = await makeRequest(startCourse, 'POST', values);
    return response;
};

export function useUpdateCurrentCourse() {
    const mutate = useMutation((values) => mutateCurrentCourse(values), {
        onError: (error) => {
            const errorMessage = setErrorMessage(error);
            notify.show(errorMessage, 'error');
        },
    });
    return mutate;
}
