import {color, font, lightShadow, shadow} from 'shared-ui/styles/variables';

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',
        padding: 20,
    }),
    control: () => ({
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 40,
        display: 'flex',
        fontFamily: font.type.mulishRegular,
        boxShadow: `inset ${shadow}`,
    }),
    multiValue: () => ({
        backgroundColor: 'white',
        display: 'flex',
        boxShadow: lightShadow,
        borderRadius: 100,
        border: '1px solid',
        borderColor: color.orange,
        margin: 5,
    }),
    multiValueLabel: () => ({
        padding: '10px 20px',
        fontFamily: font.type.mulishRegular,
        whiteSpace: 'normal',
        fontSize: '16px',
    }),
    multiValueRemove: () => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        cursor: 'pointer',
        fontSize: '56px',
        '&:hover': {
            background: 'transparent',
        },
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {...provided, opacity, transition};
    },
};
