import PropTypes from 'prop-types';
import React from 'react';

import header from 'mindset-mentor/src/assets/images/header/img_header_reminder.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Infobox from 'shared-ui/components/Elements/Infobox';
import Flex from 'shared-ui/components/Layout/Flex';
import {queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {lang} from 'lang/global.lang';

import {useDeleteCurrentCourse} from 'services/data-hooks/course/useDeleteCurrentCourse';

const CancelCourse = ({handleCancelCourse}) => {
    const isLg = useMediaQuery(queries.lg);

    return (
        <PageLayout pageName="Kurs abbrechen" pageImage={header}>
            <Flex
                alignItems="center"
                flexDirection="column"
                marginLeft="auto"
                marginRight="auto"
                width={isLg ? '70%' : ''}
            >
                <Infobox text={lang.cancelCourse} />
                <Headline
                    type="h4"
                    textAlign="center"
                    marginBottom="48px"
                    marginTop="32px"
                >
                    {lang.confirmCancel}
                </Headline>
                <Button
                    label={lang.restartCourse}
                    maxWidth="400px"
                    onClick={handleCancelCourse}
                />
            </Flex>
        </PageLayout>
    );
};

CancelCourse.propTypes = {
    handleCancelCourse: PropTypes.func.isRequired,
};

export const ConnectedCancelCourse = () => {
    const {mutate} = useDeleteCurrentCourse();

    return <CancelCourse handleCancelCourse={mutate} />;
};

export default CancelCourse;
