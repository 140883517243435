export const currentUser = '/me';
export const courses = '/courses';
export const coursesSuggestions = (id) => `/courses/${id}/suggestions`;
export const currentCourse = '/me/courses/current';
export const startCourse = '/me/courses';
export const currentWeek = '/me/courses/current/current-week';
export const completedSteps = '/me/courses/current/completed-steps';
export const completeWeekPlan = '/me/courses/current/current-week/complete';
export const confirmWeekPlan = '/me/courses/current/current-week/confirm';
export const currentWeekCompletedSteps =
    '/me/courses/current/current-week/completed-steps';
export const trackedHabits = '/me/courses/current/current-week/tracked-habits';
export const reminderSettings =
    '/me/courses/current/current-week/notifications';
export const login = '/login';
