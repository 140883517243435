export const step1_2 = {
    mainTitle: {
        fitness:
            'Was ist deine persönliche Vision für mehr Fitness und Bewegung im Alltag?',

        nutrition:
            'Was ist deine persönliche Vision für mehr Fitness und Bewegung im Alltag?',

        relax: 'Was ist deine persönliche Vision für mehr Fitness und Bewegung im Alltag?',

        energy: 'Was ist deine persönliche Vision für mehr Fitness und Bewegung im Alltag?',
        sleep: 'Was ist deine persönliche Vision für mehr Fitness und Bewegung im Alltag?',
        balance:
            'Was ist deine persönliche Vision für mehr Fitness und Bewegung im Alltag?',
    },
    mainParagraph: {
        fitness:
            'Du befindest dich jetzt in deiner persönlichen Wunschfabrik. Hier kannst du deine Vision und Ziele für diesen Kurs festhalten. Diese Vision wirst du bis zum Ende deines Kurses – und darüber hinaus – verfolgen.',
        nutrition:
            'Du befindest dich jetzt in deiner persönlichen Wunschfabrik. Hier kannst du deine Vision und Ziele für diesen Kurs festhalten. Diese Vision wirst du bis zum Ende deines Kurses – und darüber hinaus – verfolgen.',
        relax: 'Du befindest dich jetzt in deiner persönlichen Wunschfabrik. Hier kannst du deine Vision und Ziele für diesen Kurs festhalten. Diese Vision wirst du bis zum Ende deines Kurses – und darüber hinaus – verfolgen.',
        energy: 'Du befindest dich jetzt in deiner persönlichen Wunschfabrik. Hier kannst du deine Vision und Ziele für diesen Kurs festhalten. Diese Vision wirst du bis zum Ende deines Kurses – und darüber hinaus – verfolgen.',
        sleep: 'Du befindest dich jetzt in deiner persönlichen Wunschfabrik. Hier kannst du deine Vision und Ziele für diesen Kurs festhalten. Diese Vision wirst du bis zum Ende deines Kurses – und darüber hinaus – verfolgen.',
        balance:
            'Du befindest dich jetzt in deiner persönlichen Wunschfabrik. Hier kannst du deine Vision und Ziele für diesen Kurs festhalten. Diese Vision wirst du bis zum Ende deines Kurses – und darüber hinaus – verfolgen.',
    },
    subParagraph: {
        fitness:
            'Du bist dir noch unsicher, wie deine persönliche Vision aussieht? Dann stell dir zuerst folgende Fragen:',
        nutrition:
            'Du bist dir noch unsicher, wie deine persönliche Vision aussieht? Dann stell dir zuerst folgende Fragen:',
        relax: 'Du bist dir noch unsicher, wie deine persönliche Vision aussieht? Dann stell dir zuerst folgende Fragen:',
        energy: 'Du bist dir noch unsicher, wie deine persönliche Vision aussieht? Dann stell dir zuerst folgende Fragen:',
        sleep: 'Du bist dir noch unsicher, wie deine persönliche Vision aussieht? Dann stell dir zuerst folgende Fragen:',
        balance:
            'Du bist dir noch unsicher, wie deine persönliche Vision aussieht? Dann stell dir zuerst folgende Fragen:',
    },
    list: {
        fitness: [
            'Was wünschst du dir in Bezug auf Fitness und Bewegung?',
            'Gibt es etwas, was du schon länger umsetzen möchtest?',
            'Was bedeutet mehr Fitness und Bewegung im Alltag für dich?',
            'Aus welchem Grund möchtest du gerne fitter werden?',
            'Hast du vielleicht einen bestimmten Geruch oder ein Geräusch im Kopf, wenn du an deinen Wunsch denkst?',
        ],
        nutrition: [
            'Was wünschst du dir in Bezug auf Fitness und Bewegung?',
            'Gibt es etwas, was du schon länger umsetzen möchtest?',
            'Was bedeutet mehr Fitness und Bewegung im Alltag für dich?',
            'Aus welchem Grund möchtest du gerne fitter werden?',
            'Hast du vielleicht einen bestimmten Geruch oder ein Geräusch im Kopf, wenn du an deinen Wunsch denkst?',
        ],
        relax: [
            'Was wünschst du dir in Bezug auf Fitness und Bewegung?',
            'Gibt es etwas, was du schon länger umsetzen möchtest?',
            'Was bedeutet mehr Fitness und Bewegung im Alltag für dich?',
            'Aus welchem Grund möchtest du gerne fitter werden?',
            'Hast du vielleicht einen bestimmten Geruch oder ein Geräusch im Kopf, wenn du an deinen Wunsch denkst?',
        ],
        energy: [
            'Was wünschst du dir in Bezug auf Fitness und Bewegung?',
            'Gibt es etwas, was du schon länger umsetzen möchtest?',
            'Was bedeutet mehr Fitness und Bewegung im Alltag für dich?',
            'Aus welchem Grund möchtest du gerne fitter werden?',
            'Hast du vielleicht einen bestimmten Geruch oder ein Geräusch im Kopf, wenn du an deinen Wunsch denkst?',
        ],
        sleep: [
            'Was wünschst du dir in Bezug auf Fitness und Bewegung?',
            'Gibt es etwas, was du schon länger umsetzen möchtest?',
            'Was bedeutet mehr Fitness und Bewegung im Alltag für dich?',
            'Aus welchem Grund möchtest du gerne fitter werden?',
            'Hast du vielleicht einen bestimmten Geruch oder ein Geräusch im Kopf, wenn du an deinen Wunsch denkst?',
        ],
        balance: [
            'Was wünschst du dir in Bezug auf Fitness und Bewegung?',
            'Gibt es etwas, was du schon länger umsetzen möchtest?',
            'Was bedeutet mehr Fitness und Bewegung im Alltag für dich?',
            'Aus welchem Grund möchtest du gerne fitter werden?',
            'Hast du vielleicht einen bestimmten Geruch oder ein Geräusch im Kopf, wenn du an deinen Wunsch denkst?',
        ],
    },
    actionDescription: {
        fitness:
            'Wir haben eine Reihe an Vorschlägen für eine mögliche Vision für mehr Fitness und Bewegung im Alltag. Vielleicht spricht dich direkt einer an oder inspiriert dich, deine eigene Vision zu formulieren.',
        nutrition:
            'Wir haben eine Reihe an Vorschlägen für eine mögliche Vision für mehr Fitness und Bewegung im Alltag. Vielleicht spricht dich direkt einer an oder inspiriert dich, deine eigene Vision zu formulieren.',
        relax: 'Wir haben eine Reihe an Vorschlägen für eine mögliche Vision für mehr Fitness und Bewegung im Alltag. Vielleicht spricht dich direkt einer an oder inspiriert dich, deine eigene Vision zu formulieren.',
        energy: 'Wir haben eine Reihe an Vorschlägen für eine mögliche Vision für mehr Fitness und Bewegung im Alltag. Vielleicht spricht dich direkt einer an oder inspiriert dich, deine eigene Vision zu formulieren.',
        sleep: 'Wir haben eine Reihe an Vorschlägen für eine mögliche Vision für mehr Fitness und Bewegung im Alltag. Vielleicht spricht dich direkt einer an oder inspiriert dich, deine eigene Vision zu formulieren.',
        balance:
            'Wir haben eine Reihe an Vorschlägen für eine mögliche Vision für mehr Fitness und Bewegung im Alltag. Vielleicht spricht dich direkt einer an oder inspiriert dich, deine eigene Vision zu formulieren.',
    },
    actionCardOptions: {
        fitness: [
            {
                id: 1,
                value: 'mich im Alltag regelmäßig bewegen',
            },
            {
                id: 2,
                value: 'meine Körperspannung aufrechterhalten / steigern',
            },
            {
                id: 3,
                value: 'die Vorteile eines aktiven Lebensstils genießen',
            },
            {
                id: 4,
                value: 'durch einen fitteren Alltag meine MS unterstützen',
            },
            {
                id: 5,
                value: 'langfristig meine Gesundheit fördern',
            },
            {
                id: 6,
                value: 'während der Einheiten meine Gedanken baumeln lassen',
            },
        ],
        nutrition: [
            {
                id: 1,
                value: 'mich im Alltag regelmäßig bewegen',
            },
            {
                id: 2,
                value: 'meine Körperspannung aufrechterhalten / steigern',
            },
            {
                id: 3,
                value: 'die Vorteile eines aktiven Lebensstils genießen',
            },
            {
                id: 4,
                value: 'durch einen fitteren Alltag meine MS unterstützen',
            },
            {
                id: 5,
                value: 'langfristig meine Gesundheit fördern',
            },
            {
                id: 6,
                value: 'während der Einheiten meine Gedanken baumeln lassen',
            },
        ],
        relax: [
            {
                id: 1,
                value: 'mich im Alltag regelmäßig bewegen',
            },
            {
                id: 2,
                value: 'meine Körperspannung aufrechterhalten / steigern',
            },
            {
                id: 3,
                value: 'die Vorteile eines aktiven Lebensstils genießen',
            },
            {
                id: 4,
                value: 'durch einen fitteren Alltag meine MS unterstützen',
            },
            {
                id: 5,
                value: 'langfristig meine Gesundheit fördern',
            },
            {
                id: 6,
                value: 'während der Einheiten meine Gedanken baumeln lassen',
            },
        ],
        energy: [
            {
                id: 1,
                value: 'mich im Alltag regelmäßig bewegen',
            },
            {
                id: 2,
                value: 'meine Körperspannung aufrechterhalten / steigern',
            },
            {
                id: 3,
                value: 'die Vorteile eines aktiven Lebensstils genießen',
            },
            {
                id: 4,
                value: 'durch einen fitteren Alltag meine MS unterstützen',
            },
            {
                id: 5,
                value: 'langfristig meine Gesundheit fördern',
            },
            {
                id: 6,
                value: 'während der Einheiten meine Gedanken baumeln lassen',
            },
        ],
        sleep: [
            {
                id: 1,
                value: 'mich im Alltag regelmäßig bewegen',
            },
            {
                id: 2,
                value: 'meine Körperspannung aufrechterhalten / steigern',
            },
            {
                id: 3,
                value: 'die Vorteile eines aktiven Lebensstils genießen',
            },
            {
                id: 4,
                value: 'durch einen fitteren Alltag meine MS unterstützen',
            },
            {
                id: 5,
                value: 'langfristig meine Gesundheit fördern',
            },
            {
                id: 6,
                value: 'während der Einheiten meine Gedanken baumeln lassen',
            },
        ],
        balance: [
            {
                id: 1,
                value: 'mich im Alltag regelmäßig bewegen',
            },
            {
                id: 2,
                value: 'meine Körperspannung aufrechterhalten / steigern',
            },
            {
                id: 3,
                value: 'die Vorteile eines aktiven Lebensstils genießen',
            },
            {
                id: 4,
                value: 'durch einen fitteren Alltag meine MS unterstützen',
            },
            {
                id: 5,
                value: 'langfristig meine Gesundheit fördern',
            },
            {
                id: 6,
                value: 'während der Einheiten meine Gedanken baumeln lassen',
            },
        ],
    },
    infoBoxText: {
        fitness:
            'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        nutrition:
            'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        relax: 'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        energy: 'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        sleep: 'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        balance:
            'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
    },
};
