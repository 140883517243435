import styled, {css} from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {color, fastTransition, queries} from 'shared-ui/styles/variables';

const sizes = css`
    width: 32px;
    height: 32px;
    margin: 0 4px;

    @media ${queries.md} {
        width: 48px;
        height: 48px;
        margin: 0 6px;
    }

    @media ${queries.lg} {
        width: 64px;
        height: 64px;
        margin: 0 8px;
    }
`;

export const StyledSelectionBox = styled.label`
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    ${sizes}
`;

export const Input = styled.input.attrs({type: 'checkbox'})`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;

    &:checked ~ span::before {
        transform: scale(1);
        opacity: 1;
    }

    &:checked ~ span p,
    &:checked ~ span div::after {
        color: ${color.white};
    }
`;

export const Box = styled.span`
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    border: 1px solid ${color.red};
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 100px;
        transition: ${fastTransition};
        opacity: 0.5;
        transform: scale(0);
        border: 1px solid ${color.red};
        background-color: ${color.red};
    }
`;

export const Label = styled(Paragraph)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: ${fastTransition};
    color: ${color.red};
    font-size: 100%;
`;

export const LabelBox = styled(Card)`
    margin-bottom: 8px !important;
    height: 64px;
    width: 64px;
    padding: 0;
    justify-content: center;

    @media ${queries.md} {
        margin-bottom: 16px !important;
    }

    ${sizes}
`;

export const CheckMark = styled.div`
    width: 8px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg) translate(-2px, -2px);

    @media ${queries.sm} {
        width: 12px;
        height: 24px;
    }

    @media ${queries.md} {
        width: 16px;
        height: 32px;
    }
`;
