import React from 'react';

import FitnessOnboardingImage from 'mindset-mentor/src/assets/images/zielsetzung.jpg';

export const step1_3 = {
    mainTitle: {
        fitness: 'Gestalte deine persönliche Motivationswoche',
        nutrition: 'Gestalte deine persönliche Motivationswoche',
        relax: 'Gestalte deine persönliche Motivationswoche',
        energy: 'Gestalte deine persönliche Motivationswoche',
        sleep: 'Gestalte deine persönliche Motivationswoche',
        balance: 'Gestalte deine persönliche Motivationswoche',
    },
    mainDescription: {
        fitness:
            'Gratulation! Du bist in dich gegangen und hast bereits deine Vision erstellt. Das ist bereits der erste wichtige Schritt in die richtige Richtung! Um deine Vision nun motiviert umzusetzen, kannst du dir überlegen, welche Schritte du dafür gehen solltest. Woche für Woche kommst du ihr damit näher.',
        nutrition:
            'Gratulation! Du bist in dich gegangen und hast bereits deine Vision erstellt. Das ist bereits der erste wichtige Schritt in die richtige Richtung! Um deine Vision nun motiviert umzusetzen, kannst du dir überlegen, welche Schritte du dafür gehen solltest. Woche für Woche kommst du ihr damit näher.',
        relax: 'Gratulation! Du bist in dich gegangen und hast bereits deine Vision erstellt. Das ist bereits der erste wichtige Schritt in die richtige Richtung! Um deine Vision nun motiviert umzusetzen, kannst du dir überlegen, welche Schritte du dafür gehen solltest. Woche für Woche kommst du ihr damit näher.',
        energy: 'Gratulation! Du bist in dich gegangen und hast bereits deine Vision erstellt. Das ist bereits der erste wichtige Schritt in die richtige Richtung! Um deine Vision nun motiviert umzusetzen, kannst du dir überlegen, welche Schritte du dafür gehen solltest. Woche für Woche kommst du ihr damit näher.',
        sleep: 'Gratulation! Du bist in dich gegangen und hast bereits deine Vision erstellt. Das ist bereits der erste wichtige Schritt in die richtige Richtung! Um deine Vision nun motiviert umzusetzen, kannst du dir überlegen, welche Schritte du dafür gehen solltest. Woche für Woche kommst du ihr damit näher.',
        balance:
            'Gratulation! Du bist in dich gegangen und hast bereits deine Vision erstellt. Das ist bereits der erste wichtige Schritt in die richtige Richtung! Um deine Vision nun motiviert umzusetzen, kannst du dir überlegen, welche Schritte du dafür gehen solltest. Woche für Woche kommst du ihr damit näher.',
    },
    subTitle: {
        fitness: `${"So geht's weiter"}`,
        nutrition: `${"So geht's weiter"}`,
        relax: `${"So geht's weiter"}`,
        energy: `${"So geht's weiter"}`,
        sleep: `${"So geht's weiter"}`,
        balance: `${"So geht's weiter"}`,
    },
    subDescription: {
        fitness: (
            <>
                Um deine Vision zu erreichen, suchst du dir in den folgenden
                drei Wochen jeweils eine Gewohnheit (Habit) aus, die dich deiner
                Vision ein Stückchen näherbringen. Anschließend legst du fest,
                wie oft du deinen Habit umsetzen möchtest. Am Anfang jeder Woche
                setzt du dich intensiv mit deinem Wochenziel auseinander. Am
                Ende erhältst du deinen Wochenplan, der dich in den nächsten 7
                Tagen dabei unterstützen wird, dein Ziel anzusteuern.
                <br />
                <br />
                Damit du dein Ziel sicher erreichst, ist es wichtig, Woche für
                Woche zu prüfen, wie gut du mit deinem Plan zurechtkommst.
                Merkst du eventuell, dass die Umsetzung nicht so klappt, wie du
                es dir vorgenommen hast? Dann kannst du gerne noch Anpassungen
                vornehmen und frisch in eine neue Woche starten. Läuft alles
                super, dann nur weiter so!
            </>
        ),
        nutrition: (
            <>
                Um deine Vision zu erreichen, suchst du dir in den folgenden
                drei Wochen jeweils eine Gewohnheit (Habit) aus, die dich deiner
                Vision ein Stückchen näherbringen. Anschließend legst du fest,
                wie oft du deinen Habit umsetzen möchtest. Am Anfang jeder Woche
                setzt du dich intensiv mit deinem Wochenziel auseinander. Am
                Ende erhältst du deinen Wochenplan, der dich in den nächsten 7
                Tagen dabei unterstützen wird, dein Ziel anzusteuern.
                <br />
                <br />
                Damit du dein Ziel sicher erreichst, ist es wichtig, Woche für
                Woche zu prüfen, wie gut du mit deinem Plan zurechtkommst.
                Merkst du eventuell, dass die Umsetzung nicht so klappt, wie du
                es dir vorgenommen hast? Dann kannst du gerne noch Anpassungen
                vornehmen und frisch in eine neue Woche starten. Läuft alles
                super, dann nur weiter so!
            </>
        ),
        relax: (
            <>
                Um deine Vision zu erreichen, suchst du dir in den folgenden
                drei Wochen jeweils eine Gewohnheit (Habit) aus, die dich deiner
                Vision ein Stückchen näherbringen. Anschließend legst du fest,
                wie oft du deinen Habit umsetzen möchtest. Am Anfang jeder Woche
                setzt du dich intensiv mit deinem Wochenziel auseinander. Am
                Ende erhältst du deinen Wochenplan, der dich in den nächsten 7
                Tagen dabei unterstützen wird, dein Ziel anzusteuern.
                <br />
                <br />
                Damit du dein Ziel sicher erreichst, ist es wichtig, Woche für
                Woche zu prüfen, wie gut du mit deinem Plan zurechtkommst.
                Merkst du eventuell, dass die Umsetzung nicht so klappt, wie du
                es dir vorgenommen hast? Dann kannst du gerne noch Anpassungen
                vornehmen und frisch in eine neue Woche starten. Läuft alles
                super, dann nur weiter so!
            </>
        ),
        energy: (
            <>
                Um deine Vision zu erreichen, suchst du dir in den folgenden
                drei Wochen jeweils eine Gewohnheit (Habit) aus, die dich deiner
                Vision ein Stückchen näherbringen. Anschließend legst du fest,
                wie oft du deinen Habit umsetzen möchtest. Am Anfang jeder Woche
                setzt du dich intensiv mit deinem Wochenziel auseinander. Am
                Ende erhältst du deinen Wochenplan, der dich in den nächsten 7
                Tagen dabei unterstützen wird, dein Ziel anzusteuern.
                <br />
                <br />
                Damit du dein Ziel sicher erreichst, ist es wichtig, Woche für
                Woche zu prüfen, wie gut du mit deinem Plan zurechtkommst.
                Merkst du eventuell, dass die Umsetzung nicht so klappt, wie du
                es dir vorgenommen hast? Dann kannst du gerne noch Anpassungen
                vornehmen und frisch in eine neue Woche starten. Läuft alles
                super, dann nur weiter so!
            </>
        ),
        sleep: (
            <>
                Um deine Vision zu erreichen, suchst du dir in den folgenden
                drei Wochen jeweils eine Gewohnheit (Habit) aus, die dich deiner
                Vision ein Stückchen näherbringen. Anschließend legst du fest,
                wie oft du deinen Habit umsetzen möchtest. Am Anfang jeder Woche
                setzt du dich intensiv mit deinem Wochenziel auseinander. Am
                Ende erhältst du deinen Wochenplan, der dich in den nächsten 7
                Tagen dabei unterstützen wird, dein Ziel anzusteuern.
                <br />
                <br />
                Damit du dein Ziel sicher erreichst, ist es wichtig, Woche für
                Woche zu prüfen, wie gut du mit deinem Plan zurechtkommst.
                Merkst du eventuell, dass die Umsetzung nicht so klappt, wie du
                es dir vorgenommen hast? Dann kannst du gerne noch Anpassungen
                vornehmen und frisch in eine neue Woche starten. Läuft alles
                super, dann nur weiter so!
            </>
        ),
        balance: (
            <>
                Um deine Vision zu erreichen, suchst du dir in den folgenden
                drei Wochen jeweils eine Gewohnheit (Habit) aus, die dich deiner
                Vision ein Stückchen näherbringen. Anschließend legst du fest,
                wie oft du deinen Habit umsetzen möchtest. Am Anfang jeder Woche
                setzt du dich intensiv mit deinem Wochenziel auseinander. Am
                Ende erhältst du deinen Wochenplan, der dich in den nächsten 7
                Tagen dabei unterstützen wird, dein Ziel anzusteuern.
                <br />
                <br />
                Damit du dein Ziel sicher erreichst, ist es wichtig, Woche für
                Woche zu prüfen, wie gut du mit deinem Plan zurechtkommst.
                Merkst du eventuell, dass die Umsetzung nicht so klappt, wie du
                es dir vorgenommen hast? Dann kannst du gerne noch Anpassungen
                vornehmen und frisch in eine neue Woche starten. Läuft alles
                super, dann nur weiter so!
            </>
        ),
    },
    infoBoxText: {
        fitness:
            'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        nutrition:
            'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        relax: 'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        energy: 'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        sleep: 'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
        balance:
            'Think Positive! Achte darauf, deine Vision und Ziele positiv zu formulieren. Ist deine Vision oder dein Ziel negativ formuliert, wird der negative Zustand fokussiert.',
    },
    cardImage: {
        fitness: FitnessOnboardingImage,
        nutrition: FitnessOnboardingImage,
        relax: FitnessOnboardingImage,
        energy: FitnessOnboardingImage,
        sleep: FitnessOnboardingImage,
        balance: FitnessOnboardingImage,
    },
};
