import styled from 'styled-components';
import {Column} from 'shared-ui/components/Layout/Grid';
import Flex from 'shared-ui/components/Layout/Flex';

export const StyledColumn = styled(Column)`
    padding: 0;
`;

export const StyledFlex = styled(Flex)`
    width: 100%;
`;
