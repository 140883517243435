import PropTypes from 'prop-types';
import React from 'react';

import header from 'mindset-mentor/src/assets/images/header/img-header-dann-planung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import {color, queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {navigate} from 'shared-utils';

import {pageRoute} from 'routes/pages';

import {plan} from 'lang/global.lang';

const GeneratePlan = ({handleNextStep}) => {
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    return (
        <PageLayout
            pageName="Generierung Plan"
            pageImage={header}
            pageIcon="book"
            subLine="Dein Wochenplan"
        >
            <Flex
                flexDirection="column"
                alignItems={textAlign}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {plan.mainTitle}
                </Headline>
                <Paragraph margin="16px 0" textAlign={textAlign}>
                    {plan.mainParagraph}
                </Paragraph>

                <Flex
                    flexDirection="column"
                    alignItems="center"
                    marginTop="16px"
                    width="100%"
                >
                    <Icon color={color.red} name="export" size="48px" />
                    <Headline type="h4" marginTop="8px">
                        {plan.generatePlan}
                    </Headline>
                </Flex>
                <Flex
                    justifyContent="space-between"
                    marginTop="16px"
                    width="100%"
                >
                    {plan.exportOptions.map(({label, icon, onClick}) => {
                        return (
                            <Flex
                                flexDirection="column"
                                alignItems="center"
                                key={label}
                                width="30%"
                            >
                                <Button
                                    theme="secondary"
                                    onClick={onClick}
                                    icon={icon}
                                    iconColor={color.red}
                                    iconSize="24px"
                                    size="64px"
                                />
                                <Headline type="h4" marginTop="8px">
                                    {label}
                                </Headline>
                            </Flex>
                        );
                    })}
                </Flex>
                <Flex
                    flexDirection={isMd ? 'row' : 'column'}
                    marginTop={isMd ? '64px' : ''}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <Button
                        label={plan.weeklyPreparation}
                        maxWidth="352px"
                        margin="8px 16px"
                        onClick={handleNextStep}
                    />
                    <Button
                        theme="outline"
                        label={plan.digitalPlan}
                        maxWidth="352px"
                        margin="8px 16px"
                        onClick={() => {}}
                    />
                </Flex>
            </Flex>
        </PageLayout>
    );
};

GeneratePlan.propTypes = {
    handleNextStep: PropTypes.func.isRequired,
};

export const ConnectedGeneratePlan = () => {
    const handleNextStep = () => navigate(pageRoute.overviewWeekStart);

    return <GeneratePlan handleNextStep={handleNextStep} />;
};

export default GeneratePlan;
