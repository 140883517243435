import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from 'shared-utils';
import OnboardingPage from 'pages/Onboarding';
import { page, pageRoute } from 'routes/pages';
import { useOnboarding } from 'context/OnboardingContext';
import { useProtectedRoutes } from 'hooks/useProtectedRoutes';
import { useProtectedSteps } from 'hooks/useProtectedSteps';

const AppLayout = ({ children }) => {
    const { isDisableOnboarding } = useOnboarding();
    const { isProtectedStep } = useProtectedSteps();
    const { isNavigateToStart } = useProtectedRoutes();

    if (!isDisableOnboarding) {
        return <OnboardingPage />;
    }
    if (isNavigateToStart) {
        navigate(`/${page.mainPath}`);
    }
    if (isProtectedStep) {
        navigate(pageRoute.bulletBoard);
    }
    return children;
};
AppLayout.propTypes = {
    children: PropTypes.any.isRequired,
};

export default AppLayout;