import {useMemo} from 'react';
import {useLocation} from 'shared-utils';

import {steps} from 'data/courses/course-week.data';

import {useGetCompletedSteps} from 'services/data-hooks/course/useGetCompletedSteps';

const extraFields = [
    {value: 'habit-check-1', prevStep: 'habit'},
    {value: 'habit-check-2', prevStep: 'habit'},
];

export const useProtectedSteps = () => {
    const {data} = useGetCompletedSteps();
    const location = useLocation();

    const isProtectedStep = useMemo(() => {
        if (data) {
            const field = location.pathname.split('/').pop();
            const findExtraField = extraFields.find(
                (step) => step.value === field
            );
            if (findExtraField) {
                const findPrevStep = data.some(
                    (step) => step === findExtraField.prevStep
                );
                return !findPrevStep;
            }

            const valuesSteps = Object.values(steps);
            const mappedSteps = valuesSteps.map((step) => step.field);
            const itemIndex = mappedSteps.findIndex((step) => step === field);
            const prevItem = mappedSteps[itemIndex - 1] || false;
            if (!prevItem) {
                return false;
            }
            const findPrevStep = data.some((step) => step === prevItem);
            return !findPrevStep;
        }
    }, [data, location]);

    return {isProtectedStep: isProtectedStep || false};
};
