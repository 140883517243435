import { page, pageFields } from 'routes/pages';


export const steps = {
    [page.visualization]: {
        id: 1,
        iconName: 'book',
        name: 'Visualisierung',
        slug: page.visualization,
        field: pageFields.chapter1.visualization,
    },
    [page.vision]: {
        id: 2,
        iconName: 'pen',
        name: 'Individuelle Vision',
        slug: page.vision,
        field: pageFields.chapter1.vision,
    },
    [page.lessonTarget]: {
        id: 3,
        iconName: 'book',
        name: 'Lernpart Zielsetzung',
        slug: page.lessonTarget,
        field: pageFields.chapter1.lessonTarget,
    },
    [page.individualAction]: {
        id: 4,
        iconName: 'book',
        name: 'Individuelle Handlung',
        slug: page.individualAction,
        field: pageFields.chapter2.individualAction,
    },
    [page.timePlan]: {
        id: 5,
        iconName: 'book',
        name: 'Zielgeschwindigkeit',
        slug: page.timePlan,
        field: pageFields.chapter2.timePlan,
    },
    [page.smartTarget]: {
        id: 6,
        iconName: 'book',
        name: 'SMART-Ziel',
        slug: page.smartTarget,
        field: pageFields.chapter2.smartTarget,
    },
    [page.rubicon]: {
        id: 7,
        iconName: 'book',
        name: 'Rubikon',
        slug: page.rubicon,
        field: pageFields.chapter2.rubicon,
    },
    [page.goalPlanning]: {
        id: 8,
        iconName: 'book',
        name: 'Lernpart Zielplanung',
        slug: page.goalPlanning,
        field: pageFields.chapter3.goalPlanning,
    },
    [page.ifPlanning]: {
        id: 9,
        iconName: 'pen',
        name: 'Wenn Planung',
        slug: page.ifPlanning,
        field: pageFields.chapter3.ifPlanning,
    },
    [page.thenPlanning]: {
        id: 10,
        iconName: 'pen',
        name: 'Dann Planung',
        slug: page.thenPlanning,
        field: pageFields.chapter3.thenPlanning,
    },
    [page.firstSteps]: {
        id: 11,
        iconName: 'pen',
        name: 'First Steps',
        slug: page.firstSteps,
        field: pageFields.chapter3.firstSteps,
    },
    [page.startDate]: {
        id: 12,
        iconName: 'pen',
        name: 'Startdatum',
        slug: page.startDate,
        field: pageFields.chapter3.startDate,
    },
    [page.overviewWeekStart]: {
        id: 13,
        iconName: 'book',
        name: 'Wochenvorbereitung',
        slug: page.overviewWeekStart,
        field: pageFields.chapter3.overviewWeekStart,
    },
};

export const courseWeekData = {
    chapter1: {
        title: 'Kapitel 1',
        name: 'Einführung',
        items: [
            steps[page.visualization],
            steps[page.vision],
            steps[page.lessonTarget],
        ],
    },
    chapter2: {
        title: 'Kapitel 2',
        name: 'Zielsetzung',
        items: [
            steps[page.individualAction],
            steps[page.timePlan],
            steps[page.smartTarget],
            steps[page.rubicon],
        ],
    },
    chapter3: {
        title: 'Kapitel 3',
        name: 'Planung',
        items: [
            steps[page.goalPlanning],
            steps[page.ifPlanning],
            steps[page.thenPlanning],
            steps[page.firstSteps],
            steps[page.startDate],
            steps[page.overviewWeekStart],
        ],
    },
};