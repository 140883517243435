import HeaderImage from 'mindset-mentor/src/assets/images/header/img-header-kurs-woche-1.jpg';


export const lang = {
    welcome: 'Willkommen zum MINDSETmentor',
    selectCourse: 'Suche dir jetzt einen passenden Kurs aus!',
    addCourse:
        'Beginne mit einem Kurs für die nächsten 3 Wochen. Anschließend kannst du eine weiteren Kurs hinzufügen.',
    seeProgress: 'Verfolge deine Fortschritte',
    toHabitTracker: 'Zum Habit Tracker',
    withCourse: {
        headerImage: HeaderImage,
        title: 'Willkommen im Kurs,',
        paragraph:
            'wenn du eine Erklärung der Kursoberfläche benötigst klick hier. Ansonsten fange jetzt mit dem ersten Schritt an.',
    },
};