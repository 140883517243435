import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'shared-utils';



import header from 'mindset-mentor/src/assets/images/header/img_header_reminder.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';



import Button from 'shared-ui/components/Elements/Button';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import { queries } from 'shared-ui/styles/variables';



import Calendar from 'components/Elements/Calendar';

import {pageRoute} from 'routes/pages';

import {lang} from 'lang/global.lang';

import {usePatchCurrentWeek} from 'services/data-hooks/week/usePatchCurrentWeek';

import {useCalendarDate} from 'hooks/useCalendarDate';

import {localizeDate} from 'helpers/localization-date';

const CourseStartDate = ({handleCurrentWeekMutate}) => {
    const {tomorrow, nextDays, calendarDate, handleOnChange} =
        useCalendarDate(15);

    const handleOnClickCalendar = () => {
        const data = {
            start_date: localizeDate(calendarDate),
        };
        handleCurrentWeekMutate(data);
    };

    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    return (
        <PageLayout
            pageName="Startdatum"
            pageImage={header}
            pageIcon="calendar2"
            subLine="Lorem ipsum dolor sit amet"
        >
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Paragraph textAlign={textAlign}>
                    Lorem Ipsum dolor sit amet, set diam nonumy et magna dolore
                    aliquayam erat. Lorem ipsum dolor sit amet, consetetur
                    sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua.
                </Paragraph>
                <Paragraph bold margin="16px 0 32px 0" textAlign={textAlign}>
                    Wann soll deine Motivation starten?
                </Paragraph>
            </Flex>
            <Flex flexDirection="column" alignItems="center" width="100%">
                <Calendar
                    minDate={tomorrow}
                    maxDate={nextDays}
                    handleOnChange={handleOnChange}
                    calendarDate={calendarDate}
                />
                <Button
                    label={lang.saveAndGo()}
                    maxWidth="320px"
                    margin="32px auto"
                    onClick={handleOnClickCalendar}
                />
            </Flex>
        </PageLayout>
    );
};

CourseStartDate.propTypes = {
    handleCurrentWeekMutate: PropTypes.func.isRequired,
};

export const ConnectedCourseStartDate = () => {
    const {mutate: currentWeekMutate} = usePatchCurrentWeek();

    const handleCurrentWeekMutate = (data) => {
        currentWeekMutate({
            data,
            step: 'startDate',
            route: pageRoute.overviewWeekStart,
        });
    };

    return (
        <CourseStartDate handleCurrentWeekMutate={handleCurrentWeekMutate} />
    );
};

export default CourseStartDate;