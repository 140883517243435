import PropTypes from 'prop-types';
import React from 'react';

import SelectCard from 'shared-ui/components/Elements/SelectCard';

import {navigate} from 'shared-utils';

import {pageRoute, protectedRoutes} from 'routes/pages';

const ChapterItem = ({item, completedSteps}) => {
    const {steps} = protectedRoutes;
    if (!item) return null;

    const isSuccessful = completedSteps.some((step) => step === item.field);
    const itemIndex = steps.findIndex((x) => x === item.field);
    const prevItem = steps[itemIndex - 1];
    const isActive =
        itemIndex === 0
            ? true
            : completedSteps.some((step) => step === prevItem);

    return (
        <SelectCard
            title={item.name}
            isSuccessful={isSuccessful}
            isDisabled={!isActive}
            iconName={item.iconName}
            handleOnClick={() => {
                navigate(pageRoute.courseWeekItem(item.slug));
            }}
        />
    );
};

ChapterItem.propTypes = {
    item: PropTypes.object.isRequired,
    completedSteps: PropTypes.array.isRequired,
};

export default ChapterItem;
