import styled from 'styled-components';

import {cardRadius, color, font, shadow} from 'shared-ui/styles/variables';

export const StyledInput = styled.input`
    box-shadow: ${(props) =>
        props.theme === 'primary'
            ? 'inset 0 0px 15px 0 rgba(0, 0, 0, 0.3)'
            : shadow};
    border: none;
    border-radius: ${(props) =>
        props.theme === 'primary' ? '100px' : cardRadius};
    padding: 18px;
    padding-left: 24px;
    outline-color: ${color.orange};
    color: ${color.grey};
    font-size: ${font.size.xsmall};
    font-family: ${font.type.mulishRegular};
    background: ${color.white};
    width: ${(props) => props.width};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : '')};
`;
