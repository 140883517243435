import styled, {css} from 'styled-components';

import desktopBack from 'mindset-mentor/src/assets/images/img-bg-kurs-desktop.jpg';

import Card from 'shared-ui/components/Elements/Card';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {cardRadius, color, queries, shadow} from 'shared-ui/styles/variables';

import Flag from 'components/Elements/Flag';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    margin: 0 auto;
    margin-bottom: 64px;
    align-items: center;
    max-width: 1300px;

    &::before {
        content: '';
        width: 100%;
        border-radius: ${cardRadius};
        position: absolute;

        @media ${queries.md} {
            height: 100%;
            background: url(${desktopBack});
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
`;

export const StyledGrid = styled.div`
    width: 100%;
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 10px;
    row-gap: 15px;
    @media ${queries.md} {
        grid-template-columns: 1fr 1fr;
        margin-top: 48px;
        width: 75%;
    }
    @media ${queries.xl} {
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 48px;
        width: 75%;
    }
`;

export const FlagContainer = styled(Flex)`
    position: absolute;
    height: 90%;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
`;

export const StyledFlag = styled(Flag)`
    width: 16px;
`;

export const StyledTipsGrid = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 40px;
    row-gap: 15px;
    @media ${queries.lg} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    color: ${color.darkBlue};

    @media ${queries.md} {
        color: ${color.white};
    }
`;

export const StyledColumn = styled.div`
    padding: 0;
    filter: drop-shadow(0 0px 10px rgba(0, 0, 0, 0.15));
    display: flex;
`;

export const StyledCard = styled(Card)`
    margin: 8px;
    position: relative;
    box-shadow: none;
    width: 100%;

    ${({isActive}) =>
        isActive &&
        css`
            clip-path: polygon(
                -56px -56px,
                0 calc(100% + 56px),
                calc(100% + 56px) calc(100% + 56px),
                100% 56px,
                calc(100% - 56px) 0
            );
            cursor: auto;

            &::after {
                content: '';
                width: 56px;
                height: 56px;
                position: absolute;
                top: 0;
                right: 0;
                background: ${color.red};
                border-bottom-left-radius: ${cardRadius};
                box-shadow: ${shadow};
            }
        `};
`;

export const Layout = styled(Div)`
    background: ${color.white};
    margin: 0 auto;
    padding: 32px 0;
`;
