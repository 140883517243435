import axios from 'axios';

import {baseUrl} from './variables';

export async function makeRequest(url, method, data = {}) {
    const cachedToken = localStorage.getItem('userToken');
    const parsedToken = JSON.parse(cachedToken);
    const config = {
        method,
        url: `${baseUrl}/api/account/v1/${url}`,
        headers: {
            Authorization: `Bearer ${parsedToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        data,
    };

    try {
        const res = await axios(config);
        if (res.status === 200) {
            return res.data;
        }
        if (res.status === 204) {
            return true;
        }
    } catch (error) {
        return false;
    }
}

export {axios};
