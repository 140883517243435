import styled from 'styled-components';

import Div from 'shared-ui/components/Layout/Div';
import {color, queries, shadow} from 'shared-ui/styles/variables';

import ChapterCard from '../ChapterCard';

export const Wrapper = styled(Div)`
    padding-left: 40px;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 0;
        width: 12px;
        height: 32px;
        background: ${(props) =>
            props.showTopLine ? color.red : color.bgGrey};
        z-index: -1;
    }

    &::after {
        content: '';
        position: absolute;
        left: 16px;
        top: 32px;
        width: 12px;
        height: 100%;
        background: ${(props) =>
            props.showBottomLine ? color.red : color.bgGrey};
        z-index: -1;
    }

    &:last-child::after {
        height: 40px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
    }
    &:first-child::before {
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
    }
`;

export const StyledChapterCard = styled(ChapterCard)`
    padding-left: 16px;
    padding-right: 64px;
    margin-right: 0;
    border-color: ${({isOpen, isDone}) =>
        isOpen ? color.orange : isDone ? color.red : ''};

    @media ${queries.md} {
        padding-left: 32px;
    }
`;

export const SubCard = styled(ChapterCard)`
    padding-left: 16px;
    padding-right: 64px;
    margin: 16px;
    margin-left: 64px;
    margin-right: 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media ${queries.md} {
        padding-left: 32px;
    }
`;

export const Indicator = styled.div`
    position: absolute;
    left: 10px;
    top: 24px;
    width: 24px;
    height: 24px;
    background: ${(props) => (props.active ? color.red : color.bgGrey)};
    box-shadow: ${shadow};
    border: 4px solid ${color.white};
    border-radius: 100px;
`;
