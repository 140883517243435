import { notify } from 'react-notify-toast';
import { useMutation } from 'react-query';
import {navigate} from 'shared-utils';

import {pageFields} from 'routes/pages';

import {currentWeek} from 'services/config/endpoints';
import {makeRequest} from 'services/config/mindset-mentor-api';
import {COMPLETED_STEPS, CURRENT_WEEK} from 'services/config/query-keys';
import {normalizeCurrentWeek} from 'services/normalize/current-week';

import {useSetQueryData} from 'hooks/useSetQueryData';

import {setErrorMessage} from 'helpers/error-message';

const updatedFields = {
    '1_2': ['vision'],
    '2_1': ['habit'],
    '2_3': [
        'minimum_amount',
        'expected_amount',
        'maximum_amount',
        'has_increasing_amounts',
    ],
    '3_2': ['obstacles'],
    '3_3': ['solutions'],
    '3_4': ['todos'],
    goal: ['goal'],
    startDate: ['start_date'],
};
const cacheFields = {
    '1_2': [pageFields.chapter1.vision],
    '2_1': [pageFields.chapter2.individualAction],
    '2_3': [pageFields.chapter2.smartTarget],
    goal: [pageFields.chapter2.goal],
    '3_2': [pageFields.chapter3.ifPlanning],
    '3_3': [pageFields.chapter3.thenPlanning],
    '3_4': [pageFields.chapter3.firstSteps],
    startDate: [pageFields.chapter3.startDate],
};

const patchCurrentCourse = async (data, step, isChapterDisabled = false) => {
    const formData = {
        ...data,
        updated_fields: updatedFields[step],
    };
    if (!isChapterDisabled) {
        await makeRequest(currentWeek, 'PATCH', formData);
    }
    /* TODO:if data has phone number: make request account api */
    return {data};
};

export function usePatchCurrentWeek() {
    const {setData, getData} = useSetQueryData();
    const mutate = useMutation(
        ({data, step, isChapterDisabled}) =>
            patchCurrentCourse(data, step, isChapterDisabled),
        {
            onSuccess: (data, variables) => {
                if (variables.route) {
                    navigate(variables.route);
                }
            },
            onError: (error, variables, context) => {
                const {previousCurrentWeekValue, previousCompletedSteps} =
                    context;
                setData([CURRENT_WEEK], previousCurrentWeekValue);
                setData([COMPLETED_STEPS], previousCompletedSteps);
                const errorMessage = setErrorMessage(error);
                notify.show(errorMessage, 'error');
            },
            onMutate: async ({
                data,
                route,
                isChapterDisabled = false,
                step,
            }) => {
                if (isChapterDisabled) {
                    navigate(route);
                } else {
                    /* start current week cache */
                    const previousCurrentWeekValue = await getData(
                        CURRENT_WEEK
                    );

                    const normalized = await normalizeCurrentWeek({data});
                    const mergedCurrentWeekData = {
                        ...previousCurrentWeekValue,
                        ...normalized,
                    };
                    setData([CURRENT_WEEK], mergedCurrentWeekData);
                    /* end current week cache */

                    /* start completed steps cache */
                    const previousCompletedStepsValue = await getData(
                        COMPLETED_STEPS
                    );
                    const updateFields = cacheFields[step];
                    if (!previousCompletedStepsValue) {
                        const mergedCompletedStepsData = [...updateFields];
                        setData([COMPLETED_STEPS], mergedCompletedStepsData);
                    } else {
                        const mergedCompletedStepsData = [
                            ...new Set([
                                ...previousCompletedStepsValue,
                                ...updateFields,
                            ]),
                        ];
                        setData([COMPLETED_STEPS], mergedCompletedStepsData);
                    }
                    /* end completed steps cache */
                    return {
                        previousCurrentWeekValue,
                        previousCompletedStepsValue,
                    };
                }
            },
        }
    );
    return mutate;
}