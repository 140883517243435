import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {color} from 'shared-ui/styles/variables';

import {coursesIcons} from 'data/courses-list.data';

import {FlagContainer, StyledCard, StyledColumn, StyledFlag} from './styles';

const ListItem = ({
    identifier,
    handleCourseSelect,
    userParticipationsCount,
    name,
    badges,
}) => {
    const isActive = userParticipationsCount === 1;

    return (
        <StyledColumn>
            <StyledCard
                onClick={() =>
                    isActive ? null : handleCourseSelect(identifier)
                }
                alignItems="center"
                isActive={isActive}
            >
                <Icon
                    name={coursesIcons[identifier]}
                    color={color.red}
                    size="48px"
                />
                <Paragraph bold theme="darkBlue" textAlign="center">
                    {name}
                </Paragraph>

                {badges && (
                    <FlagContainer
                        flexDirection="column"
                        justifyContent="space-around"
                    >
                        {Object.keys(badges).map((key) => {
                            return (
                                <StyledFlag
                                    key={identifier + key}
                                    name={badges[key]}
                                />
                            );
                        })}
                    </FlagContainer>
                )}
            </StyledCard>
        </StyledColumn>
    );
};

ListItem.propTypes = {
    identifier: PropTypes.string.isRequired,
    handleCourseSelect: PropTypes.func.isRequired,
    userParticipationsCount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    badges: PropTypes.array.isRequired,
};

export default ListItem;
