import styled from 'styled-components';

import 'react-calendar/dist/Calendar.css';
import {
    cardRadius,
    color,
    font,
    orangeShadow,
    shadow,
} from 'shared-ui/styles/variables';

export const CalendarStyled = styled.div`
    max-width: 100%;

    button,
    div,
    abbr {
        font-family: ${font.type.mulishRegular};
        font-weight: ${font.weight.regular};
        text-decoration: none;
        color: ${font.color.darkBlue};
        font-size: 16px;
    }

    .react-calendar__month-view__weekdays__weekday abbr {
        text-decoration: none;
        color: ${color.red};
    }

    .react-calendar {
        border: none;
        border-radius: ${cardRadius};
        box-shadow: ${shadow};
        padding: 16px;
    }

    .react-calendar__tile {
        border-radius: 100px;
    }
    .react-calendar__tile:disabled {
        opacity: 0.3;
    }

    .react-calendar__tile--now {
        background: white;
    }

    .react-calendar__tile--active {
        background: ${color.red} !important;
        box-shadow: ${orangeShadow};

        abbr {
            color: white !important;
        }
    }

    .react-calendar__navigation {
        align-items: center;
    }

    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
        border-radius: 100px;
        background: ${color.lightGrey} !important;
        font-size: 32px;
        min-width: unset;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 6px;
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
        display: none;
    }

    .react-calendar__navigation__label {
        font-weight: ${font.weight.bold};
        font-size: 19.2px;
        pointer-events: none;
    }
`;
