import styled from 'styled-components';
import Input from 'components/Elements/Input';
import Icon from 'shared-ui/components/Elements/Icon';

export const StyledInput = styled(Input)`
    display: inline-block;
    margin: 8px;
`;

export const StyledIcon = styled(Icon)`
    margin: 24px auto 16px auto;
`;
