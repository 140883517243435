import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import Flex from 'shared-ui/components/Layout/Flex';
import SelectionBox from '../SelectionBox';

const SelectionBoxGroup = forwardRef((props, ref) => {
    const {items, onChange, theme, className, selectedValues} = props;

    return (
        <Flex justifyContent="center" className={className}>
            {items.map(({value, label}, index) => {
                return (
                    <Flex
                        key={index}
                        alignItems="center"
                        flexDirection="column"
                    >
                        <SelectionBox
                            theme={theme}
                            id={`${className}-${value}`}
                            ref={ref}
                            label={label}
                            checked={selectedValues?.indexOf(value) > -1}
                            value={value}
                            onChange={() => {
                                onChange(value);
                            }}
                        />
                    </Flex>
                );
            })}
        </Flex>
    );
});

SelectionBoxGroup.propTypes = {
    theme: PropTypes.oneOf(['primary', 'secondary']),
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    selectedValues: PropTypes.array.isRequired,
};

SelectionBoxGroup.defaultProps = {
    theme: 'primary',
    onChange: () => {},
    className: 'ap-selectionboxgroup',
};

export default React.memo(SelectionBoxGroup);
