import React from 'react';

import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';
import header from 'mindset-mentor/src/assets/images/header/img_header_reminder.jpg';
import ChapterCard from 'components/Layout/ChapterCard';

import {settings} from 'lang/settings.lang';

import {StyledColumn, StyledFlex} from './styles';
import {navigate} from 'shared-utils';

const SettingsTemplate = () => {
    return (
        <PageLayout pageName="Einstellungen" pageImage={header}>
            <StyledFlex flexWrap="wrap">
                {settings.cards.map(({label, iconName, link}) => {
                    return (
                        <StyledColumn key={label} cols={{sm: 6, xl: 4}}>
                            <ChapterCard
                                label={label}
                                iconName={iconName}
                                onClick={() => navigate(link)}
                            />
                        </StyledColumn>
                    );
                })}
            </StyledFlex>
        </PageLayout>
    );
};

export default SettingsTemplate;
