import {useMutation} from 'react-query';
import {navigate, notify} from 'shared-utils';

import {pageRoute} from 'routes/pages';

import {currentCourse} from 'services/config/endpoints';
import {makeRequest} from 'services/config/mindset-mentor-api';

import {useSetQueryData} from 'hooks/useSetQueryData';

import {setErrorMessage} from 'helpers/error-message';

const deleteCurrentCourse = async () => {
    const response = await makeRequest(currentCourse, 'DELETE');
    return response;
};

export function useDeleteCurrentCourse() {
    const {queryClient} = useSetQueryData();

    const mutate = useMutation(deleteCurrentCourse, {
        onSuccess: () => {
            navigate(`${pageRoute.mainPath}`);
            queryClient.clear();
        },
        onError: (error) => {
            const errorMessage = setErrorMessage(error);
            notify.show(errorMessage, 'error');
        },
    });
    return mutate;
}
