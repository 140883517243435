import PropTypes from 'prop-types';
import React from 'react';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import { color } from 'shared-ui/styles/variables';
import { StyledList, StyledListElement } from './styles';

const List = ({
    items,
    numeric,
    markerColor,
    textColor,
    bold,
    italic,
    showBottomLine,
    margin,
}) => {
    return (
        <StyledList>
            {items.map((item, index) => {
                return (
                    <StyledListElement
                        key={item + index}
                        markerColor={markerColor}
                        showBottomLine={showBottomLine}
                        numeric={numeric}
                    >
                        <Paragraph
                            bold={bold}
                            italic={italic}
                            theme={textColor}
                            margin={margin}
                        >
                            {item}
                        </Paragraph>
                    </StyledListElement>
                );
            })}
        </StyledList>
    );
};

List.propTypes = {
    markerColor: PropTypes.string,
    textColor: PropTypes.string,
    showBottomLine: PropTypes.bool,
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    items: PropTypes.array.isRequired,
    numeric: PropTypes.bool,
    margin: PropTypes.string,
};

List.defaultProps = {
    markerColor: `${color.red}`,
    textColor: 'darkGrey',
    showBottomLine: false,
    bold: false,
    italic: false,
    numeric: false,
    margin: '0',
};

export default List;