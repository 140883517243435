import {useCallback, useEffect, useState} from 'react';

const useCreatableInput = (options, selectLimit = 1, initialSelectedValues) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [initialOptions, setInitialOptions] = useState(options);
    const [isDisabled, setIsDisabled] = useState(false);

    const createOption = (label) => ({
        label: label,
        value: label,
    });

    const handleOnChange = (changeValue) => {
        if (changeValue.length > 0) {
            const findActiveOptions = options.filter(
                (ar) => !changeValue.find((rm) => rm.value === ar.value)
            );
            setInitialOptions(findActiveOptions);
        } else {
            setInitialOptions(options);
        }
        setSelectedValues(changeValue);
    };

    const handleOnInputChange = (inputChangeValue) => {
        setInputValue(inputChangeValue);
    };

    const handleSetSelectedValues = (value, index) => {
        if (!isDisabled) {
            setSelectedValues((prev) => [...prev, createOption(value)]);
            setInputValue('');
            if (index) {
                setInitialOptions((prev) => prev.filter((x) => x.id !== index));
            }
        }
    };

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        if (event.key === 'Tab' || event.key === 'Enter') {
            handleSetSelectedValues(inputValue);
            event.preventDefault();
        }
    };

    const onDragEnd = useCallback((result) => {
        const {source, destination} = result;
        if (!destination || destination?.droppableId === 'StartTarget') {
            return null;
        }
        const findOption = options.find((x) => x.id === source.index);
        handleSetSelectedValues(findOption.value, source.index);
    }, []);

    useEffect(() => {
        setIsDisabled(selectedValues.length >= selectLimit && !isDisabled);
    }, [selectedValues]);

    useEffect(() => {
        if (initialSelectedValues?.length > 0) {
            const convertToArray =
                typeof initialSelectedValues === 'string'
                    ? [initialSelectedValues]
                    : [...initialSelectedValues];
            const mapped = convertToArray.map((item) => createOption(item));

            const cleanInitialOptions = initialOptions.filter(
                (ar) => !mapped.find((rm) => rm.value === ar.value)
            );

            setSelectedValues(mapped);
            setInitialOptions(cleanInitialOptions);
        }
    }, []);

    return {
        handleOnChange,
        selectedValues,
        initialOptions,
        handleOnInputChange,
        handleKeyDown,
        onDragEnd,
        inputValue,
        handleSetSelectedValues,
        isDisabled,
    };
};

export default useCreatableInput;
