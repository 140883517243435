import PropTypes from 'prop-types';
import React from 'react';
import CreatableSelect from 'react-select/creatable';

import {customStyles} from './styles';

const components = {
    DropdownIndicator: null,
};

const CreatableInput = ({
    inputValue,
    value,
    placeholder,
    handleOnChange,
    handleOnInputChange,
    handleKeyDown,
    menuIsOpen,
    isDisabled,
    inputRef,
}) => {
    return (
        <CreatableSelect
            ref={inputRef}
            styles={customStyles}
            classNamePrefix={'CreatableSelect'}
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={menuIsOpen}
            onChange={handleOnChange}
            onInputChange={handleOnInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || ''}
            value={value}
            isSearchable={isDisabled}
        />
    );
};

CreatableInput.propTypes = {
    inputValue: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    handleOnChange: PropTypes.func.isRequired,
    handleOnInputChange: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    menuIsOpen: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    inputRef: PropTypes.any,
};

CreatableInput.defaultProps = {
    placeholder: '',
    inputRef: null,
};

export default CreatableInput;
