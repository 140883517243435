import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { navigate } from 'shared-utils';



import header from 'mindset-mentor/src/assets/images/header/img-header-zielgeschwindigkeit.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';



import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import { queries } from 'shared-ui/styles/variables';



import { useCheckBoxValues } from 'shared-utils/utils/hooks/useCheckBoxValues';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';



import SelectionBoxGroup from 'components/Elements/SelectionBoxGroup';
import AlertBox from 'components/Layout/AlertBox';



import { page, pageRoute, protectedRoutes } from 'routes/pages';



import { lang } from 'lang/global.lang';
import { weekDays } from 'lang/week-days';



import { coursesSteps } from 'data/courses-list.data';



import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';
import {usePatchCurrentWeek} from 'services/data-hooks/week/usePatchCurrentWeek';

import {useActiveChapter} from 'hooks/useActiveChapter';
import {useCurrentIdentifier} from 'hooks/useCurrentIdentifier';

import {StyledBadge} from './styles';

const TargetTemplate = ({
    identifier,
    step,
    currentWeekData,
    handleCurrentWeekMutate,
    isChapterDisabled,
}) => {
    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    const {
        selectedValues: optimalValues,
        handleOnChange: optimalValuesOnChange,
    } = useCheckBoxValues(currentWeekData.expectedAmount);
    const {selectedValues: minValues, handleOnChange: minValuesOnChange} =
        useCheckBoxValues(currentWeekData.minimumAmount);
    const {selectedValues: maxValues, handleOnChange: maxValuesOnChange} =
        useCheckBoxValues(currentWeekData.maximumAmount);

    const currentCourse = coursesSteps[step];
    if (!currentCourse) return null;

    const handleClickGoToRubikon = () => {
        if (
            optimalValues.length > 0 &&
            minValues.length > 0 &&
            maxValues.length > 0
        ) {
            const data = {
                minimum_amount: minValues,
                expected_amount: optimalValues,
                maximum_amount: maxValues,
                has_increasing_amounts: true,
            };
            handleCurrentWeekMutate(data);
        } else {
            setIsOpenLightbox(true);
        }
    };

    return (
        <PageLayout
            pageName="SMART-Ziel"
            pageIcon="book"
            subLine="Dein Wochenziel im Überblick"
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {currentCourse.mainTitle[identifier]}
                </Headline>
                <Flex
                    flexDirection="column"
                    alignItems={isMd ? 'center' : 'flex-start'}
                    marginTop="30px"
                    marginBottom="30px"
                >
                    <Paragraph margin="0 0 16px 0" textAlign={textAlign}>
                        Du hast dir folgenden Habit ausgesucht:
                    </Paragraph>
                    <StyledBadge text={currentWeekData.habit} />
                </Flex>
                <Flex
                    flexDirection="column"
                    alignItems={isMd ? 'center' : 'flex-start'}
                    marginBottom="30px"
                >
                    <Paragraph margin="0 0 16px 0">
                        Davon erhoffst du dir:
                    </Paragraph>
                    <StyledBadge text={currentWeekData.goal} />
                </Flex>
                {isChapterDisabled ? (
                    <Div marginBottom="20px">
                        <Paragraph bold theme="red">
                            {lang.completedChapter}
                        </Paragraph>
                    </Div>
                ) : null}
                <Flex marginBottom="20px">
                    <Div>
                        <Paragraph bold>{lang.optimalTarget}:</Paragraph>
                        <SelectionBoxGroup
                            items={weekDays}
                            className="ap-selectionboxgroup-optimal"
                            selectedValues={optimalValues}
                            onChange={
                                isChapterDisabled ? null : optimalValuesOnChange
                            }
                        />
                        <Paragraph>
                            {optimalValues.length} {lang.daysOfWeek}
                        </Paragraph>
                    </Div>
                </Flex>

                <Flex marginBottom="20px">
                    <Div>
                        <Paragraph bold>{lang.minTarget}:</Paragraph>
                        <SelectionBoxGroup
                            items={weekDays}
                            className="ap-selectionboxgroup-min"
                            selectedValues={minValues}
                            onChange={
                                isChapterDisabled ? null : minValuesOnChange
                            }
                        />
                        <Paragraph>
                            {minValues.length} {lang.daysOfWeek}
                        </Paragraph>
                    </Div>
                </Flex>
                <Flex marginBottom="20px">
                    <Div>
                        <Paragraph bold>{lang.maxTarget}:</Paragraph>
                        <SelectionBoxGroup
                            items={weekDays}
                            className="ap-selectionboxgroup-max"
                            selectedValues={maxValues}
                            onChange={
                                isChapterDisabled ? null : maxValuesOnChange
                            }
                        />
                        <Paragraph>
                            {maxValues.length} {lang.daysOfWeek}
                        </Paragraph>
                    </Div>
                </Flex>
                <Flex
                    alignItems={isMd ? 'center' : 'flex-start'}
                    marginBottom="20px"
                    flexDirection="column"
                    width="100%"
                >
                    <Div marginTop="15px" marginBottom="15px">
                        <Paragraph bold textAlign={textAlign}>
                            {lang.informationSatisfied}
                        </Paragraph>
                    </Div>

                    <Flex
                        flexDirection={isMd ? 'row-reverse' : 'column'}
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <Button
                            label="Ja, bin ich"
                            maxWidth="352px"
                            margin="8px 16px"
                            onClick={handleClickGoToRubikon}
                        />
                        <Button
                            theme="outline"
                            label={lang.noAdjustGoal}
                            maxWidth="352px"
                            margin="8px 16px"
                            onClick={() => {
                                navigate(
                                    pageRoute.courseWeekItem(page.habitCheck2)
                                );
                            }}
                        />
                    </Flex>
                </Flex>
            </Flex>
            <AlertBox
                setIsOpenLightbox={setIsOpenLightbox}
                isOpenLightbox={isOpenLightbox}
                text="Bitte wähle zuerst deine Ziele aus!"
                buttonHandler={() => setIsOpenLightbox(false)}
            />
        </PageLayout>
    );
};

TargetTemplate.propTypes = {
    identifier: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    currentWeekData: PropTypes.object.isRequired,
    handleCurrentWeekMutate: PropTypes.func.isRequired,
    isChapterDisabled: PropTypes.bool.isRequired,
};

export const ConnectedTargetTemplate = ({step}) => {
    const {mutate: currentWeekMutate} = usePatchCurrentWeek();
    const {data: currentWeekData} = useGetCurrentWeek();
    const {identifier} = useCurrentIdentifier();
    const {isChapterDisabled} = useActiveChapter(protectedRoutes.part2Final);

    if (!currentWeekData || !identifier) {
        return null;
    }

    const handleCurrentWeekMutate = (data) => {
        currentWeekMutate({
            data,
            step,
            route: pageRoute.courseWeekItem(page.rubicon),
            isChapterDisabled,
        });
    };

    return (
        <TargetTemplate
            identifier={identifier}
            step={step}
            handleCurrentWeekMutate={handleCurrentWeekMutate}
            currentWeekData={currentWeekData}
            isChapterDisabled={isChapterDisabled}
        />
    );
};

ConnectedTargetTemplate.propTypes = {
    step: PropTypes.string.isRequired,
};

export default TargetTemplate;