import React from 'react';

const Silver = () => {
    return (
        <svg id="s-a" viewBox="0 0 40 57.73">
            <defs>
                <linearGradient
                    id="s-d"
                    x1="3.82"
                    y1="178.57"
                    x2="7.67"
                    y2="178.57"
                    gradientTransform="translate(0 208.54) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#eb6742" stopOpacity=".2" />
                    <stop offset=".1" stopColor="#eb653f" stopOpacity=".24" />
                    <stop offset=".28" stopColor="#ec6136" stopOpacity=".34" />
                    <stop offset=".49" stopColor="#ed5929" stopOpacity=".51" />
                    <stop offset=".75" stopColor="#ee4e16" stopOpacity=".74" />
                    <stop offset="1" stopColor="#ef4200" />
                </linearGradient>
                <linearGradient
                    id="s-f"
                    x1="100.63"
                    y1="176.41"
                    x2="62.41"
                    y2="176.41"
                    gradientTransform="translate(-61.42 198.86) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#b9b9b9" />
                    <stop offset=".1" stopColor="#cacaca" />
                    <stop offset=".28" stopColor="#f7f7f7" />
                    <stop offset=".32" stopColor="#fff" />
                    <stop offset=".38" stopColor="#eee" />
                    <stop offset=".51" stopColor="#c1c1c1" />
                    <stop offset=".52" stopColor="#bfbfbf" />
                    <stop offset=".83" stopColor="#747474" />
                    <stop offset=".87" stopColor="#858585" />
                    <stop offset=".94" stopColor="#b2b2b2" />
                    <stop offset="1" stopColor="#e0e0e0" />
                </linearGradient>
            </defs>
            <g id="s-b">
                <g id="s-c">
                    <rect
                        x="4.62"
                        y="2.25"
                        width="3.85"
                        height="55.44"
                        fill="#fff"
                    />
                    <rect
                        x="3.82"
                        y="2.25"
                        width="3.85"
                        height="55.44"
                        fill="#eb6742"
                    />
                    <rect
                        x="3.82"
                        y="2.25"
                        width="3.85"
                        height="55.44"
                        fill="url(#s-d)"
                    />
                    <rect
                        x="3.83"
                        y="35.55"
                        width="3.84"
                        height=".78"
                        fill="#ce4c2e"
                    />
                    <circle cx="5.75" cy="3.97" r="3.42" fill="#ce4c2e" />
                    <circle cx="5.75" cy="3.55" r="3.42" fill="#eb6742" />
                </g>
                <g id="s-e">
                    <path
                        d="M2.27,35c-.68,.02-1.25-.51-1.28-1.19V11.05c.03-.68,.6-1.21,1.28-1.19H37.71c.53-.02,1.01,.3,1.21,.79,.17,.44,.04,.94-.32,1.25l-10.43,9.35s-.05,.07-.06,.11c0,.04,.02,.07,.05,.1l10.74,11.62c.31,.32,.4,.81,.22,1.22-.21,.46-.68,.75-1.19,.74l-35.66-.04Z"
                        fill="url(#s-f)"
                    />
                    <path
                        d="M37.7,10.36c.37-.05,.72,.21,.77,.58,.03,.22-.05,.44-.21,.59l-10.43,9.35c-.27,.22-.3,.62-.08,.89,0,.01,.02,.02,.03,.03l10.75,11.62c.24,.3,.19,.73-.1,.97-.14,.12-.33,.17-.51,.15H2.27c-.41,.02-.75-.29-.78-.69V11.05c.03-.4,.37-.71,.78-.69H37.71m0-1H2.27c-.96-.02-1.75,.73-1.78,1.69v22.8c.03,.96,.82,1.71,1.78,1.69H37.93c.7,.01,1.34-.38,1.65-1,.26-.6,.14-1.29-.31-1.76l-10.5-11.35,10.17-9.11c.51-.45,.69-1.17,.45-1.81-.28-.68-.94-1.12-1.68-1.11v-.04Z"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Silver;
