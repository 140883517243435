import React from 'react';

import FitnessOnboardingImage from 'mindset-mentor/src/assets/images/img-fitness-start-page.png';

export const step1_1 = {
    mainTitle: {
        fitness:
            'Was würde es für dich bedeuten, deinen Alltag fitter zu meistern?',
        nutrition:
            'Was würde es für dich bedeuten, deinen Alltag fitter zu meistern?',
        relax: 'Was würde es für dich bedeuten, deinen Alltag fitter zu meistern?',

        energy: 'Was würde es für dich bedeuten, deinen Alltag fitter zu meistern?',

        sleep: 'Was würde es für dich bedeuten, deinen Alltag fitter zu meistern?',
        balance:
            'Was würde es für dich bedeuten, deinen Alltag fitter zu meistern?',
    },
    mainParagraph: {
        fitness: (
            <>
                Mit der richtigen Motivation und den passenden Techniken ist die
                Umsetzung von mehr Bewegung und Sport im Alltag einfacher, als
                du zurzeit denken magst. <strong>Du kannst es schaffen!</strong>
            </>
        ),
        nutrition: (
            <>
                Mit der richtigen Motivation und den passenden Techniken ist die
                Umsetzung von mehr Bewegung und Sport im Alltag einfacher, als
                du zurzeit denken magst. <strong>Du kannst es schaffen!</strong>
            </>
        ),
        relax: (
            <>
                Mit der richtigen Motivation und den passenden Techniken ist die
                Umsetzung von mehr Bewegung und Sport im Alltag einfacher, als
                du zurzeit denken magst. <strong>Du kannst es schaffen!</strong>
            </>
        ),
        energy: (
            <>
                Mit der richtigen Motivation und den passenden Techniken ist die
                Umsetzung von mehr Bewegung und Sport im Alltag einfacher, als
                du zurzeit denken magst. <strong>Du kannst es schaffen!</strong>
            </>
        ),
        sleep: (
            <>
                Mit der richtigen Motivation und den passenden Techniken ist die
                Umsetzung von mehr Bewegung und Sport im Alltag einfacher, als
                du zurzeit denken magst. <strong>Du kannst es schaffen!</strong>
            </>
        ),
        balance: (
            <>
                Mit der richtigen Motivation und den passenden Techniken ist die
                Umsetzung von mehr Bewegung und Sport im Alltag einfacher, als
                du zurzeit denken magst. <strong>Du kannst es schaffen!</strong>
            </>
        ),
    },
    mainDescription: {
        fitness: 'Bewegung hat für MS-Betroffene zahlreiche Vorteile.',
        nutrition: 'Bewegung hat für MS-Betroffene zahlreiche Vorteile.',
        relax: 'Bewegung hat für MS-Betroffene zahlreiche Vorteile.',
        energy: 'Bewegung hat für MS-Betroffene zahlreiche Vorteile.',
        sleep: 'Bewegung hat für MS-Betroffene zahlreiche Vorteile.',
        balance: 'Bewegung hat für MS-Betroffene zahlreiche Vorteile.',
    },
    subDescription: {
        fitness: (
            <>
                Durch Sport wird die Muskelkraft und die Leistungsfähigkeit
                gefördert. Außerdem steigert Bewegung allgemein das körperliche
                und seelische Wohlbefinden. Somit hat ein aktiveres Leben eine
                vorbeugende und therapeutische Wirkung auf die MS.<sup>1</sup>{' '}
                Dabei gibt es je nach Geschmack, Interesse und Möglichkeiten die
                unterschiedlichsten Sportarten.
            </>
        ),
        nutrition: (
            <>
                Durch Sport wird die Muskelkraft und die Leistungsfähigkeit
                gefördert. Außerdem steigert Bewegung allgemein das körperliche
                und seelische Wohlbefinden. Somit hat ein aktiveres Leben eine
                vorbeugende und therapeutische Wirkung auf die MS.<sup>1</sup>{' '}
                Dabei gibt es je nach Geschmack, Interesse und Möglichkeiten die
                unterschiedlichsten Sportarten.
            </>
        ),
        relax: (
            <>
                Durch Sport wird die Muskelkraft und die Leistungsfähigkeit
                gefördert. Außerdem steigert Bewegung allgemein das körperliche
                und seelische Wohlbefinden. Somit hat ein aktiveres Leben eine
                vorbeugende und therapeutische Wirkung auf die MS.<sup>1</sup>{' '}
                Dabei gibt es je nach Geschmack, Interesse und Möglichkeiten die
                unterschiedlichsten Sportarten.
            </>
        ),
        energy: (
            <>
                Durch Sport wird die Muskelkraft und die Leistungsfähigkeit
                gefördert. Außerdem steigert Bewegung allgemein das körperliche
                und seelische Wohlbefinden. Somit hat ein aktiveres Leben eine
                vorbeugende und therapeutische Wirkung auf die MS.<sup>1</sup>{' '}
                Dabei gibt es je nach Geschmack, Interesse und Möglichkeiten die
                unterschiedlichsten Sportarten.
            </>
        ),
        balance: (
            <>
                Durch Sport wird die Muskelkraft und die Leistungsfähigkeit
                gefördert. Außerdem steigert Bewegung allgemein das körperliche
                und seelische Wohlbefinden. Somit hat ein aktiveres Leben eine
                vorbeugende und therapeutische Wirkung auf die MS.<sup>1</sup>{' '}
                Dabei gibt es je nach Geschmack, Interesse und Möglichkeiten die
                unterschiedlichsten Sportarten.
            </>
        ),
        sleep: (
            <>
                Durch Sport wird die Muskelkraft und die Leistungsfähigkeit
                gefördert. Außerdem steigert Bewegung allgemein das körperliche
                und seelische Wohlbefinden. Somit hat ein aktiveres Leben eine
                vorbeugende und therapeutische Wirkung auf die MS.<sup>1</sup>{' '}
                Dabei gibt es je nach Geschmack, Interesse und Möglichkeiten die
                unterschiedlichsten Sportarten.
            </>
        ),
    },
    imageTitle: {
        fitness: 'So erreichst du deine Ziele!',
        nutrition: 'So erreichst du deine Ziele!',
        relax: 'So erreichst du deine Ziele!',
        energy: 'So erreichst du deine Ziele!',
        sleep: 'So erreichst du deine Ziele!',
        balance: 'So erreichst du deine Ziele!',
    },
    image: {
        fitness: FitnessOnboardingImage,
        nutrition: FitnessOnboardingImage,
        relax: FitnessOnboardingImage,
        energy: FitnessOnboardingImage,
        sleep: FitnessOnboardingImage,
        balance: FitnessOnboardingImage,
    },
    imageDescription: {
        fitness: (
            <>
                Was dir dabei hilft, fitter zu werden und langfristig motiviert
                zu bleiben, ist eine Vision.{' '}
                <strong>
                    Die Vision ist dein langfristiges Ziel, dein Wunsch für dich
                    selbst.
                </strong>{' '}
                Wenn du deine Vision einmal festgelegt hast, kannst du sie dir
                immer wieder vor die Augen führen, wenn du merkst, dass deine
                Motivation etwas schwindet.
            </>
        ),
        nutrition: (
            <>
                Was dir dabei hilft, fitter zu werden und langfristig motiviert
                zu bleiben, ist eine Vision.{' '}
                <strong>
                    Die Vision ist dein langfristiges Ziel, dein Wunsch für dich
                    selbst.
                </strong>{' '}
                Wenn du deine Vision einmal festgelegt hast, kannst du sie dir
                immer wieder vor die Augen führen, wenn du merkst, dass deine
                Motivation etwas schwindet.
            </>
        ),
        relax: (
            <>
                Was dir dabei hilft, fitter zu werden und langfristig motiviert
                zu bleiben, ist eine Vision.{' '}
                <strong>
                    Die Vision ist dein langfristiges Ziel, dein Wunsch für dich
                    selbst.
                </strong>{' '}
                Wenn du deine Vision einmal festgelegt hast, kannst du sie dir
                immer wieder vor die Augen führen, wenn du merkst, dass deine
                Motivation etwas schwindet.
            </>
        ),
        energy: (
            <>
                Was dir dabei hilft, fitter zu werden und langfristig motiviert
                zu bleiben, ist eine Vision.{' '}
                <strong>
                    Die Vision ist dein langfristiges Ziel, dein Wunsch für dich
                    selbst.
                </strong>{' '}
                Wenn du deine Vision einmal festgelegt hast, kannst du sie dir
                immer wieder vor die Augen führen, wenn du merkst, dass deine
                Motivation etwas schwindet.
            </>
        ),
        sleep: (
            <>
                Was dir dabei hilft, fitter zu werden und langfristig motiviert
                zu bleiben, ist eine Vision.{' '}
                <strong>
                    Die Vision ist dein langfristiges Ziel, dein Wunsch für dich
                    selbst.
                </strong>{' '}
                Wenn du deine Vision einmal festgelegt hast, kannst du sie dir
                immer wieder vor die Augen führen, wenn du merkst, dass deine
                Motivation etwas schwindet.
            </>
        ),
        balance: (
            <>
                Was dir dabei hilft, fitter zu werden und langfristig motiviert
                zu bleiben, ist eine Vision.{' '}
                <strong>
                    Die Vision ist dein langfristiges Ziel, dein Wunsch für dich
                    selbst.
                </strong>{' '}
                Wenn du deine Vision einmal festgelegt hast, kannst du sie dir
                immer wieder vor die Augen führen, wenn du merkst, dass deine
                Motivation etwas schwindet.
            </>
        ),
    },
    videoTitle: {
        fitness: 'Wie finde ich meine Vision?',
        nutrition: 'Wie finde ich meine Vision?',
        relax: 'Wie finde ich meine Vision?',
        energy: 'Wie finde ich meine Vision?',
        sleep: 'Wie finde ich meine Vision?',
        balance: 'Wie finde ich meine Vision?',
    },
    videoId: {
        fitness: '6281301959001',
        nutrition: '6281301959001',
        relax: '6281301959001',
        energy: '6281301959001',
        sleep: '6281301959001',
        balance: '6281301959001',
    },
    videoSubTitle: {
        fitness: 'Titel…. - Kurs 1',
        nutrition: 'Titel…. - Kurs 1',
        relax: 'Titel…. - Kurs 1',
        energy: 'Titel…. - Kurs 1',
        sleep: 'Titel…. - Kurs 1',
        balance: 'Titel…. - Kurs 1',
    },
    videoSubParagraph: {
        fitness: 'Im Video kannst du noch eine Portion Motivation sammeln.',
        nutrition: 'Im Video kannst du noch eine Portion Motivation sammeln.',
        relax: 'Im Video kannst du noch eine Portion Motivation sammeln.',
        energy: 'Im Video kannst du noch eine Portion Motivation sammeln.',
        sleep: 'Im Video kannst du noch eine Portion Motivation sammeln.',
        balance: 'Im Video kannst du noch eine Portion Motivation sammeln.',
    },
    bottomTitle: {
        fitness:
            'Klicke jetzt hier, um deine eigene Vision für mehr Fitness und Bewegung im Alltag zu erstellen:',
        nutrition:
            'Klicke jetzt hier, um deine eigene Vision für mehr Fitness und Bewegung im Alltag zu erstellen:',
        relax: 'Klicke jetzt hier, um deine eigene Vision für mehr Fitness und Bewegung im Alltag zu erstellen:',
        energy: 'Klicke jetzt hier, um deine eigene Vision für mehr Fitness und Bewegung im Alltag zu erstellen:',
        sleep: 'Klicke jetzt hier, um deine eigene Vision für mehr Fitness und Bewegung im Alltag zu erstellen:',
        balance:
            'Klicke jetzt hier, um deine eigene Vision für mehr Fitness und Bewegung im Alltag zu erstellen:',
    },
    references: {
        fitness: [
            {
                prefix: '*',
                ref: 'https://www.akademie.de/de/wissen/unternehmensvision-entwickeln/was-ist-eine-vision-0',
            },
            {
                prefix: '1',
                ref: 'https://www.dmsg.de/multiple-sklerose-infos/ms-und-sport/einfuehrung/was-sie-ueber-sport-und-koerperliche-aktivitaet-wissen-sollten/',
            },
        ],
        nutrition: [
            {
                prefix: '*',
                ref: 'https://www.akademie.de/de/wissen/unternehmensvision-entwickeln/was-ist-eine-vision-0',
            },
            {
                prefix: '1',
                ref: 'https://www.dmsg.de/multiple-sklerose-infos/ms-und-sport/einfuehrung/was-sie-ueber-sport-und-koerperliche-aktivitaet-wissen-sollten/',
            },
        ],
        relax: [
            {
                prefix: '*',
                ref: 'https://www.akademie.de/de/wissen/unternehmensvision-entwickeln/was-ist-eine-vision-0',
            },
            {
                prefix: '1',
                ref: 'https://www.dmsg.de/multiple-sklerose-infos/ms-und-sport/einfuehrung/was-sie-ueber-sport-und-koerperliche-aktivitaet-wissen-sollten/',
            },
        ],
        energy: [
            {
                prefix: '*',
                ref: 'https://www.akademie.de/de/wissen/unternehmensvision-entwickeln/was-ist-eine-vision-0',
            },
            {
                prefix: '1',
                ref: 'https://www.dmsg.de/multiple-sklerose-infos/ms-und-sport/einfuehrung/was-sie-ueber-sport-und-koerperliche-aktivitaet-wissen-sollten/',
            },
        ],
        sleep: [
            {
                prefix: '*',
                ref: 'https://www.akademie.de/de/wissen/unternehmensvision-entwickeln/was-ist-eine-vision-0',
            },
            {
                prefix: '1',
                ref: 'https://www.dmsg.de/multiple-sklerose-infos/ms-und-sport/einfuehrung/was-sie-ueber-sport-und-koerperliche-aktivitaet-wissen-sollten/',
            },
        ],
        balance: [
            {
                prefix: '*',
                ref: 'https://www.akademie.de/de/wissen/unternehmensvision-entwickeln/was-ist-eine-vision-0',
            },
            {
                prefix: '1',
                ref: 'https://www.dmsg.de/multiple-sklerose-infos/ms-und-sport/einfuehrung/was-sie-ueber-sport-und-koerperliche-aktivitaet-wissen-sollten/',
            },
        ],
    },
};
