export const inputData = (currentWeekData) => {
    const data = {
        vision: {
            label: 'Deine Vision:',
            value: currentWeekData.vision,
        },
        habit: {
            label: 'Dein Habit:',
            value: currentWeekData.habit,
        },
        effect: {
            label: 'Davon erhoffst du dir:',
            value: currentWeekData.goal,
        },
        optimum: {
            label: 'Optimalziel:',
            value: `${currentWeekData.expectedAmount.length} Tage`,
        },
        minimum: {
            label: 'Minimalziel:',
            value: `${currentWeekData.minimumAmount.length} Tage`,
        },
        maximum: {
            label: 'Maximalziel:',
            value: `${currentWeekData.maximumAmount.length} Tage`,
        },
        obstacles: {
            label: 'Hürden:',
            value: currentWeekData.obstacles,
        },
        solutions: {
            label: 'Lösungen:',
            value: currentWeekData.solutions,
        },
        toDos: {label: 'ToDos:', value: currentWeekData.todos},
    };
    return data;
};
