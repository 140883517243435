export const normalizeCoursesSuggestions = async ({data}) => {
    if (!data) return null;
    const normalizedMap = {
        vision: data.vision.map((tip, index) => ({
            id: ++index,
            value: tip,
        })),
        goal: data.goal.map((tip, index) => ({
            id: ++index,
            value: tip,
        })),
        habit: data.habit.map((tip, index) => ({
            id: ++index,
            value: tip,
        })),
        problem: data.problem.map((tip, index) => ({
            id: ++index,
            value: tip,
        })),
        solution: data.solution.map((tip, index) => ({
            id: ++index,
            value: tip,
        })),
        todo: data.todo.map((tip, index) => ({
            id: ++index,
            value: tip,
        })),
    };
    return normalizedMap;
};
