import styled from 'styled-components';

import {Column} from 'shared-ui/components/Layout/Grid';
import {color, font, lightShadow} from 'shared-ui/styles/variables';

import Card from 'shared-ui/components/Elements/Card';
import Badge from 'shared-ui/components/Elements/Badge';

export const StyledColumn = styled(Column)`
    margin-bottom: 32px;
`;

export const StyledCard = styled(Card)`
    padding: 8px;
`;

export const StyledContainer = styled.div`
    padding: ${({padding}) => padding};
    cursor: not-allowed;
    width: 100%;
    box-shadow: inset 0 0px 15px 0 rgba(0, 0, 0, 0.3);
    border-radius: 40px;
    margin: 0 0 8px 0;
    background-color: white;
`;

export const StyledBadge = styled(Badge)`
    font-size: 16px;
    display: inline-block;
    border-radius: 100px;
    margin: 5px;
    border: 1px solid ${color.orange};
    box-shadow: ${lightShadow};
    padding: 10px 20px;
    font-family: ${font.type.mulishRegular};
    white-space: normal;
    color: ${color.grey};
`;
