import React from 'react';
import PropTypes from 'prop-types';

import {StyledIndicators, Indicator} from './styles';

const HorizontalIndicators = ({steps, activeStep, color}) => {
    return (
        <StyledIndicators visible={activeStep !== 0}>
            {Array.from({length: steps}).map((item, index) => (
                <Indicator
                    key={'indicator' + index}
                    isActive={index + 1 === activeStep}
                    color={color}
                />
            ))}
        </StyledIndicators>
    );
};

HorizontalIndicators.propTypes = {
    steps: PropTypes.number,
    activeStep: PropTypes.number,
    color: PropTypes.string,
};

HorizontalIndicators.defaultProps = {
    steps: 3,
    activeStep: 1,
    color: 'white',
};

export default HorizontalIndicators;
