import styled from 'styled-components';

import {
    shadow,
    color,
    orangeShadow,
    fastTransition,
} from 'shared-ui/styles/variables';

export const StyledRadioButton = styled.label`
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 24px;
`;

export const Input = styled.input`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;

    &:checked ~ span::after {
        transform: translate(-50%, -50%) scale(0.625);
    }
`;

export const StyledRadio = styled.span`
    position: relative;
    height: 32px;
    width: 32px;
    border-radius: 12px;
    transition: ${fastTransition};
    box-shadow: ${shadow};

    &:hover {
        box-shadow: ${orangeShadow};
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: ${color.button.primary};
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: ${fastTransition};
    }
`;
