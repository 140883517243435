import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {navigate} from 'shared-utils';

import header from 'mindset-mentor/src/assets/images/header/img-header-wochenuebersicht.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';

import Input from 'components/Elements/Input';

import {pageFields, pageRoute} from 'routes/pages';

import {lang} from 'lang/global.lang';
import {weekDaysFull} from 'lang/week-days';

import {usePostConfirmWeekPlan} from 'services/data-hooks/course/usePostConfirmWeekPlan';
import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';
import {usePatchCurrentWeek} from 'services/data-hooks/week/usePatchCurrentWeek';

import {useCalendarDate} from 'hooks/useCalendarDate';

import {localizeDate} from 'helpers/localization-date';

import {inputData} from './input-data';
import {StyledBadge, StyledCard, StyledColumn, StyledContainer} from './styles';

const OverviewWeekStart = ({inputData, startDate, handleClickStartWeek}) => {
    const {tomorrow, nextDays} = useCalendarDate(15);
    const [selectedDate, setSelectedDate] = useState(
        localizeDate(startDate ? new Date(startDate) : new Date())
    );

    return (
        <PageLayout pageName="Übersicht" pageIcon="pen" pageImage={header}>
            <Flex justifyContent="center" width="100%">
                <Headline type="h4" textAlign="center" marginBottom="40px">
                    {lang.overviewWeek}
                </Headline>
            </Flex>

            <StyledCard theme="secondary" flexDirection="column">
                <Flex flexWrap="wrap" alignItems="stretch" marginTop="32px">
                    {Object.keys(inputData).map((key) => {
                        const inputValue = inputData[key].value;

                        return (
                            <StyledColumn key={key} cols={{sm: 6, xl: 4}}>
                                <Paragraph bold theme="red" margin="0 0 16px 0">
                                    {inputData[key].label}
                                </Paragraph>

                                {Array.isArray(inputValue) ? (
                                    <StyledContainer padding="16px">
                                        {inputValue.map((val, index) => {
                                            return (
                                                <StyledBadge
                                                    key={key + index}
                                                    text={val}
                                                />
                                            );
                                        })}
                                    </StyledContainer>
                                ) : (
                                    <StyledContainer padding="10px 24px">
                                        <Paragraph size="xsmall" theme="grey">
                                            {weekDaysFull[inputValue] ??
                                                inputValue}
                                        </Paragraph>
                                    </StyledContainer>
                                )}
                            </StyledColumn>
                        );
                    })}
                </Flex>

                <Flex
                    flexWrap="wrap"
                    alignItems="flex-end"
                    justifyContent="center"
                >
                    <StyledColumn cols={{sm: 6, xl: 4}}>
                        <Paragraph bold theme="red" margin="0 0 16px 0">
                            {lang.startDate}:
                        </Paragraph>
                        <Input
                            name="startDate"
                            type="date"
                            value={selectedDate}
                            onChange={setSelectedDate}
                            min={localizeDate(new Date(tomorrow))}
                            max={localizeDate(new Date(nextDays))}
                        />
                    </StyledColumn>
                </Flex>
            </StyledCard>

            <Flex justifyContent="center" width="100%">
                <Button
                    label="Jetzt meine Woche starten!"
                    maxWidth="320px"
                    margin="32px auto"
                    onClick={() => handleClickStartWeek(selectedDate)}
                />
            </Flex>
        </PageLayout>
    );
};

OverviewWeekStart.propTypes = {
    inputData: PropTypes.objectOf(
        PropTypes.shape(PropTypes.string, PropTypes.array)
    ).isRequired,
    startDate: PropTypes.string.isRequired,
    handleClickStartWeek: PropTypes.func.isRequired,
};

export const ConnectedOverviewWeekStart = () => {
    const {mutateAsync: currentWeekMutate} = usePatchCurrentWeek();
    const {mutate: confirmWeekMutate} = usePostConfirmWeekPlan();
    const {data: currentWeekData} = useGetCurrentWeek();

    const updateFields = [pageFields.chapter3.overviewWeekStart];

    if (!currentWeekData) {
        return null;
    }

    const handleClickStartWeek = async (selectedDate) => {
        const data = {
            start_date: selectedDate,
        };
        const updateCurrentWeek = await currentWeekMutate({
            data,
            step: 'startDate',
            route: null,
        });
        if (updateCurrentWeek) {
            confirmWeekMutate(
                {data, updateFields},
                {
                    onSuccess: () => {
                        navigate(pageRoute.motivation);
                    },
                }
            );
        }
    };

    const data = inputData(currentWeekData);

    return (
        <OverviewWeekStart
            inputData={data}
            startDate={currentWeekData.startDate}
            handleClickStartWeek={handleClickStartWeek}
        />
    );
};

export default OverviewWeekStart;