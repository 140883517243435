import PropTypes from 'prop-types';
import React from 'react';

import {lang} from 'mindset-mentor/src/lang/global.lang';

import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import {queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import RadioButtonGroup from 'components/Elements/RadioButtonGroup';

import {usePatchReminderSettings} from 'services/data-hooks/settings/usePatchReminderSettings';
import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';

import {useNotificationsReducer} from 'hooks/useNotificationsReducer';

const NotificationSettings = ({currentWeekData, mutateReminderSettings}) => {
    const {emailNotification, emailTime, smsNotification, smsTime} =
        currentWeekData;

    const {notifications, setNotifications} = useNotificationsReducer({
        emailNotification,
        emailTime,
        smsNotification,
        smsTime,
        isPrivacyPolicy: true, //TODO: this variable must come from api
    });

    const handleReminderSettings = () => {
        mutateReminderSettings(notifications);
    };

    const isLg = useMediaQuery(queries.lg);

    return (
        <>
            <Headline textAlign={isLg ? 'center' : 'left'} marginBottom="16px">
                {lang.notificationSettings}
            </Headline>
            <Paragraph textAlign={isLg ? 'center' : 'left'}>
                {lang.notificationOptIn}
            </Paragraph>

            <Flex
                flexDirection={isLg ? 'row' : 'column'}
                justifyContent="space-around"
            >
                <RadioButtonGroup
                    label="per E-Mail"
                    iconName="envelope"
                    selectedValue={notifications.emailNotification}
                    setSelectedValue={(value) => {
                        setNotifications({emailNotification: value});
                    }}
                    name="email"
                />
                <RadioButtonGroup
                    label="per SMS"
                    iconName="mobilePhone"
                    selectedValue={notifications.smsNotification}
                    setSelectedValue={(value) => {
                        setNotifications({smsNotification: value});
                    }}
                    name="sms"
                />
            </Flex>

            <Flex justifyContent="center">
                <Button
                    label="Bestätigen"
                    onClick={handleReminderSettings}
                    maxWidth="320px"
                    margin="0 auto"
                />
            </Flex>
        </>
    );
};

NotificationSettings.propTypes = {
    currentWeekData: PropTypes.object.isRequired,
    mutateReminderSettings: PropTypes.func.isRequired,
};

export const ConnectedNotificationSettings = () => {
    const {mutate: mutateReminderSettings} = usePatchReminderSettings();
    const {data: currentWeekData} = useGetCurrentWeek();

    if (!currentWeekData) {
        return null;
    }

    return (
        <NotificationSettings
            mutateReminderSettings={mutateReminderSettings}
            currentWeekData={currentWeekData}
        />
    );
};

export default NotificationSettings;
