import React from 'react';

export const step2_4 = {
    mainTitle: {
        fitness:
            'Die Segel sind gesetzt – du bist einem gesünderen Lifestyle nähergekommen!',
        nutrition:
            'Die Segel sind gesetzt – du bist einem gesünderen Lifestyle nähergekommen!',

        relax: 'Die Segel sind gesetzt – du bist einem gesünderen Lifestyle nähergekommen!',

        energy: 'Die Segel sind gesetzt – du bist einem gesünderen Lifestyle nähergekommen!',
        sleep: 'Die Segel sind gesetzt – du bist einem gesünderen Lifestyle nähergekommen!',
        balance:
            'Die Segel sind gesetzt – du bist einem gesünderen Lifestyle nähergekommen!',
    },
    mainParagraph: {
        fitness: (
            <>
                Glückwunsch, du hast dein Wochenziel festgelegt und eine weitere
                Stufe für einen Alltag mit mehr Bewegung gemeistert!
                <br />
                <br />
                Den ersten Vorbereitungsschritt hast du hiermit erfolgreich
                abgeschlossen. Im nächsten Schritt wollen wir uns mit deinem
                Ziel näher auseinandersetzen und deine Motivationswoche
                vorbereiten. Und denke dran: Auch, wenn du dein Wochenziel mal
                nicht erreichst, ist das kein Weltuntergang. Sei nicht zu streng
                mit dir, aber behalte dein Ziel immer vor Augen! Ab jetzt gilt
                es nur noch am Ball zu bleiben. Bist du bereit?
            </>
        ),
        nutrition: (
            <>
                Glückwunsch, du hast dein Wochenziel festgelegt und eine weitere
                Stufe für einen Alltag mit mehr Bewegung gemeistert!
                <br />
                <br />
                Den ersten Vorbereitungsschritt hast du hiermit erfolgreich
                abgeschlossen. Im nächsten Schritt wollen wir uns mit deinem
                Ziel näher auseinandersetzen und deine Motivationswoche
                vorbereiten. Und denke dran: Auch, wenn du dein Wochenziel mal
                nicht erreichst, ist das kein Weltuntergang. Sei nicht zu streng
                mit dir, aber behalte dein Ziel immer vor Augen! Ab jetzt gilt
                es nur noch am Ball zu bleiben. Bist du bereit?
            </>
        ),
        relax: (
            <>
                Glückwunsch, du hast dein Wochenziel festgelegt und eine weitere
                Stufe für einen Alltag mit mehr Bewegung gemeistert!
                <br />
                <br />
                Den ersten Vorbereitungsschritt hast du hiermit erfolgreich
                abgeschlossen. Im nächsten Schritt wollen wir uns mit deinem
                Ziel näher auseinandersetzen und deine Motivationswoche
                vorbereiten. Und denke dran: Auch, wenn du dein Wochenziel mal
                nicht erreichst, ist das kein Weltuntergang. Sei nicht zu streng
                mit dir, aber behalte dein Ziel immer vor Augen! Ab jetzt gilt
                es nur noch am Ball zu bleiben. Bist du bereit?
            </>
        ),
        energy: (
            <>
                Glückwunsch, du hast dein Wochenziel festgelegt und eine weitere
                Stufe für einen Alltag mit mehr Bewegung gemeistert!
                <br />
                <br />
                Den ersten Vorbereitungsschritt hast du hiermit erfolgreich
                abgeschlossen. Im nächsten Schritt wollen wir uns mit deinem
                Ziel näher auseinandersetzen und deine Motivationswoche
                vorbereiten. Und denke dran: Auch, wenn du dein Wochenziel mal
                nicht erreichst, ist das kein Weltuntergang. Sei nicht zu streng
                mit dir, aber behalte dein Ziel immer vor Augen! Ab jetzt gilt
                es nur noch am Ball zu bleiben. Bist du bereit?
            </>
        ),
        sleep: (
            <>
                Glückwunsch, du hast dein Wochenziel festgelegt und eine weitere
                Stufe für einen Alltag mit mehr Bewegung gemeistert!
                <br />
                <br />
                Den ersten Vorbereitungsschritt hast du hiermit erfolgreich
                abgeschlossen. Im nächsten Schritt wollen wir uns mit deinem
                Ziel näher auseinandersetzen und deine Motivationswoche
                vorbereiten. Und denke dran: Auch, wenn du dein Wochenziel mal
                nicht erreichst, ist das kein Weltuntergang. Sei nicht zu streng
                mit dir, aber behalte dein Ziel immer vor Augen! Ab jetzt gilt
                es nur noch am Ball zu bleiben. Bist du bereit?
            </>
        ),
        balance: (
            <>
                Glückwunsch, du hast dein Wochenziel festgelegt und eine weitere
                Stufe für einen Alltag mit mehr Bewegung gemeistert!
                <br />
                <br />
                Den ersten Vorbereitungsschritt hast du hiermit erfolgreich
                abgeschlossen. Im nächsten Schritt wollen wir uns mit deinem
                Ziel näher auseinandersetzen und deine Motivationswoche
                vorbereiten. Und denke dran: Auch, wenn du dein Wochenziel mal
                nicht erreichst, ist das kein Weltuntergang. Sei nicht zu streng
                mit dir, aber behalte dein Ziel immer vor Augen! Ab jetzt gilt
                es nur noch am Ball zu bleiben. Bist du bereit?
            </>
        ),
    },
    tipsText: {
        fitness: (
            <>
                Wenn du Unterstützung brauchst, melde dich gerne im
                <b>
                    <i> MS Service-Center</i>
                </b>
                . Immer
                <b>
                    <i> montags bis freitags </i>
                </b>
                von
                <b>
                    <i> 8 bis 20 Uhr </i>
                </b>
                erreichst du die MS Coaches unter der kostenlosen
                <b>
                    <i> Rufnummer 0800 030 77 30</i>
                </b>
            </>
        ),
        nutrition: (
            <>
                Wenn du Unterstützung brauchst, melde dich gerne im
                <b>
                    <i> MS Service-Center</i>
                </b>
                . Immer
                <b>
                    <i> montags bis freitags </i>
                </b>
                von
                <b>
                    <i> 8 bis 20 Uhr </i>
                </b>
                erreichst du die MS Coaches unter der kostenlosen
                <b>
                    <i> Rufnummer 0800 030 77 30</i>
                </b>
            </>
        ),
        relax: (
            <>
                Wenn du Unterstützung brauchst, melde dich gerne im
                <b>
                    <i> MS Service-Center</i>
                </b>
                . Immer
                <b>
                    <i> montags bis freitags </i>
                </b>
                von
                <b>
                    <i> 8 bis 20 Uhr </i>
                </b>
                erreichst du die MS Coaches unter der kostenlosen
                <b>
                    <i> Rufnummer 0800 030 77 30</i>
                </b>
            </>
        ),
        energy: (
            <>
                Wenn du Unterstützung brauchst, melde dich gerne im
                <b>
                    <i> MS Service-Center</i>
                </b>
                . Immer
                <b>
                    <i> montags bis freitags </i>
                </b>
                von
                <b>
                    <i> 8 bis 20 Uhr </i>
                </b>
                erreichst du die MS Coaches unter der kostenlosen
                <b>
                    <i> Rufnummer 0800 030 77 30</i>
                </b>
            </>
        ),
        sleep: (
            <>
                Wenn du Unterstützung brauchst, melde dich gerne im
                <b>
                    <i> MS Service-Center</i>
                </b>
                . Immer
                <b>
                    <i> montags bis freitags </i>
                </b>
                von
                <b>
                    <i> 8 bis 20 Uhr </i>
                </b>
                erreichst du die MS Coaches unter der kostenlosen
                <b>
                    <i> Rufnummer 0800 030 77 30</i>
                </b>
            </>
        ),
        balance: (
            <>
                Wenn du Unterstützung brauchst, melde dich gerne im
                <b>
                    <i> MS Service-Center</i>
                </b>
                . Immer
                <b>
                    <i> montags bis freitags </i>
                </b>
                von
                <b>
                    <i> 8 bis 20 Uhr </i>
                </b>
                erreichst du die MS Coaches unter der kostenlosen
                <b>
                    <i> Rufnummer 0800 030 77 30</i>
                </b>
            </>
        ),
    },
};
