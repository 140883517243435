import PropTypes from 'prop-types';
import React, {forwardRef} from 'react';

import Paragraph from 'shared-ui/components/Elements/Paragraph';

import {
    Box,
    CheckMark,
    Input,
    Label,
    LabelBox,
    StyledSelectionBox,
} from './styles';

const SelectionBox = forwardRef((props, ref) => {
    const {label, id, onChange, checked, value, theme} = props;
    const secondary = theme === 'secondary';

    return (
        <>
            {secondary && (
                <LabelBox theme="secondary">
                    <Paragraph bold textAlign="center" theme="darkBlue">
                        {label}
                    </Paragraph>
                </LabelBox>
            )}
            <StyledSelectionBox htmlFor={id}>
                <Input
                    type="checkbox"
                    ref={ref}
                    id={id}
                    onChange={() => onChange(value)}
                    checked={checked}
                    value={value}
                />
                <Box>
                    {secondary ? <CheckMark /> : <Label bold>{label}</Label>}
                </Box>
            </StyledSelectionBox>
        </>
    );
});

SelectionBox.propTypes = {
    theme: PropTypes.oneOf(['primary', 'secondary']),
    label: PropTypes.any,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

SelectionBox.defaultProps = {
    theme: 'primary',
    name: '',
    label: '',
    onChange: () => {},
};

export default SelectionBox;
