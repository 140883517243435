import HeaderImage from 'mindset-mentor/src/assets/images/header/img-header-kurs-woche-1.jpg';

export const lang = {
    solutionstoPotentialHurdles: 'Lösungen für mögliche Hürden',
    TodosForPreparation: 'To-Dos zur Vorbereitung',
    title: 'Willkommen im Kurs,',
    withCourse: {
        headerImage: HeaderImage,
        title: 'Willkommen zurück,',
        paragraph: 'Weiter geht’s mit der Vorbereitung für Woche',
    },
    vision: 'Vision: ',
    welcomeText:
        'wenn du eine Erklärung der Kursoberfläche benötigst klick hier. Ansonsten fange jetzt mit dem ersten Schritt an.',
};
