import {useEffect, useState} from 'react';

import {localizeDate} from 'helpers/localization-date';

const weekDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

const setDay = (today, index) => {
    const date = new Date();
    date.setDate(today.getDate() + index);
    return date.getDate();
};

export const useTrackerValues = ({startDate}) => {
    const [initialWeekDays, setInitialWeekDays] = useState(null);
    const [today, setToday] = useState(null);
    const [weekEndDay, setWeekEndDay] = useState(null);

    const handleDate = () => {
        const today = new Date(startDate);

        const mappedWeekDays = weekDays.map((day, index) => ({
            label: setDay(today, index),
            value: day,
        }));
        setInitialWeekDays(mappedWeekDays);
        setToday(localizeDate(today, 'en-GB'));
        const date = new Date();
        date.setDate(today.getDate() + 7);
        setWeekEndDay(localizeDate(date, 'en-GB'));
    };

    useEffect(() => {
        handleDate();
    }, []);

    return {initialWeekDays, today, weekEndDay};
};
