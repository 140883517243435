import {useQuery} from 'react-query';

import {coursesSuggestions} from 'services/config/endpoints';
import {makeRequest} from 'services/config/mindset-mentor-api';
import {COURSES_SUGGESTIONS} from 'services/config/query-keys';
import {normalizeCoursesSuggestions} from 'services/normalize/courses-suggestions';

const getCoursesSuggestions = async () => {
    const response = await makeRequest(coursesSuggestions('fitness'), 'GET');
    const normalized = await normalizeCoursesSuggestions(response);
    return normalized;
};

export function useGetCoursesSuggestions() {
    return useQuery([COURSES_SUGGESTIONS], getCoursesSuggestions, {
        staleTime: Infinity,
    });
}
