import React from 'react';
import PropTypes from 'prop-types';

import {StyledCard} from './styles';
import Lightbox from 'shared-ui/components/Elements/Lightbox';
import Flex from 'shared-ui/components/Layout/Flex';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Button from 'shared-ui/components/Elements/Button';

const AlertBox = ({
    text,
    buttonText,
    buttonHandler,
    isOpenLightbox,
    setIsOpenLightbox,
}) => {
    return (
        <Lightbox
            isOrangeBg
            isOpen={isOpenLightbox}
            onClose={() => setIsOpenLightbox(false)}
        >
            <StyledCard>
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    width="90%"
                    marginLeft="auto"
                    marginRight="auto"
                    marginTop="32px"
                    marginBottom="16px"
                >
                    <Icon name="exclamationMark" color="orange" size="64px" />
                    <Paragraph margin="16px 0 32px 0" textAlign="center">
                        {text}
                    </Paragraph>
                    <Button
                        margin="0 auto"
                        label={buttonText}
                        onClick={buttonHandler}
                    />
                </Flex>
            </StyledCard>
        </Lightbox>
    );
};

AlertBox.propTypes = {
    text: PropTypes.any,
    buttonText: PropTypes.string,
    buttonHandler: PropTypes.func.isRequired,
    isOpenLightbox: PropTypes.bool.isRequired,
    setIsOpenLightbox: PropTypes.func.isRequired,
};

AlertBox.defaultProps = {
    text: 'Bitte wähle zuerst eine Zielgeschwindigkeit aus.',
    buttonText: "Ich probier\n's nochmal",
};

export default AlertBox;
