import styled, {css} from 'styled-components';

import Icon from 'shared-ui/components/Elements/Icon';
import Flex from 'shared-ui/components/Layout/Flex';
import {cardRadius, shadow} from 'shared-ui/styles/variables';

export const StyledFlex = styled(Flex)`
    flex-wrap: wrap;
    margin: 16px 0;
    width: 100%;
`;

export const Container = styled(Flex)`
    max-width: 240px;
    margin: 16px;
`;

export const StyledIcon = styled(Icon)`
    padding: 16px;

    ${({iconShadow}) =>
        iconShadow &&
        css`
            border-radius: ${cardRadius};
            box-shadow: ${shadow};
        `};
`;
