import React from 'react';

export const step3_4 = {
    mainTitle: {
        fitness:
            'Was muss ich vorher noch machen, damit ich voller Energie in meine Motivationswoche starten kann?',
        nutrition:
            'Was muss ich vorher noch machen, damit ich voller Energie in meine Motivationswoche starten kann?',
        relax: 'Was muss ich vorher noch machen, damit ich voller Energie in meine Motivationswoche starten kann?',
        energy: 'Was muss ich vorher noch machen, damit ich voller Energie in meine Motivationswoche starten kann?',
        sleep: 'Was muss ich vorher noch machen, damit ich voller Energie in meine Motivationswoche starten kann?',
        balance:
            'Was muss ich vorher noch machen, damit ich voller Energie in meine Motivationswoche starten kann?',
    },
    mainParagraph: {
        fitness: (
            <>
                Du hast jetzt einen großartigen Plan aufgestellt und kannst
                stolz auf dich sein! Ein gut durchdachter Plan ist bereits die
                halbe Miete.
                <br />
                <br />
                Bevor du loslegst und in deine erste Woche startest, geh mental
                einmal alle Schritte durch, bis du deine Handlung durchführen
                kannst. Fehlt dir noch etwas, um anfangen zu können? Benötigst
                du eventuell einen ruhigen Platz für die Umsetzung?
                <br />
                <br />
                Trage in das Textfeld ein, was du noch gebrauchen oder schon
                erledigen kannst. Auch an dieser Stelle erhältst du Vorschläge,
                die dir zur Orientierung dienen.
            </>
        ),
        nutrition: (
            <>
                Du hast jetzt einen großartigen Plan aufgestellt und kannst
                stolz auf dich sein! Ein gut durchdachter Plan ist bereits die
                halbe Miete.
                <br />
                <br />
                Bevor du loslegst und in deine erste Woche startest, geh mental
                einmal alle Schritte durch, bis du deine Handlung durchführen
                kannst. Fehlt dir noch etwas, um anfangen zu können? Benötigst
                du eventuell einen ruhigen Platz für die Umsetzung?
                <br />
                <br />
                Trage in das Textfeld ein, was du noch gebrauchen oder schon
                erledigen kannst. Auch an dieser Stelle erhältst du Vorschläge,
                die dir zur Orientierung dienen.
            </>
        ),
        relax: (
            <>
                Du hast jetzt einen großartigen Plan aufgestellt und kannst
                stolz auf dich sein! Ein gut durchdachter Plan ist bereits die
                halbe Miete.
                <br />
                <br />
                Bevor du loslegst und in deine erste Woche startest, geh mental
                einmal alle Schritte durch, bis du deine Handlung durchführen
                kannst. Fehlt dir noch etwas, um anfangen zu können? Benötigst
                du eventuell einen ruhigen Platz für die Umsetzung?
                <br />
                <br />
                Trage in das Textfeld ein, was du noch gebrauchen oder schon
                erledigen kannst. Auch an dieser Stelle erhältst du Vorschläge,
                die dir zur Orientierung dienen.
            </>
        ),
        energy: (
            <>
                Du hast jetzt einen großartigen Plan aufgestellt und kannst
                stolz auf dich sein! Ein gut durchdachter Plan ist bereits die
                halbe Miete.
                <br />
                <br />
                Bevor du loslegst und in deine erste Woche startest, geh mental
                einmal alle Schritte durch, bis du deine Handlung durchführen
                kannst. Fehlt dir noch etwas, um anfangen zu können? Benötigst
                du eventuell einen ruhigen Platz für die Umsetzung?
                <br />
                <br />
                Trage in das Textfeld ein, was du noch gebrauchen oder schon
                erledigen kannst. Auch an dieser Stelle erhältst du Vorschläge,
                die dir zur Orientierung dienen.
            </>
        ),
        sleep: (
            <>
                Du hast jetzt einen großartigen Plan aufgestellt und kannst
                stolz auf dich sein! Ein gut durchdachter Plan ist bereits die
                halbe Miete.
                <br />
                <br />
                Bevor du loslegst und in deine erste Woche startest, geh mental
                einmal alle Schritte durch, bis du deine Handlung durchführen
                kannst. Fehlt dir noch etwas, um anfangen zu können? Benötigst
                du eventuell einen ruhigen Platz für die Umsetzung?
                <br />
                <br />
                Trage in das Textfeld ein, was du noch gebrauchen oder schon
                erledigen kannst. Auch an dieser Stelle erhältst du Vorschläge,
                die dir zur Orientierung dienen.
            </>
        ),
        balance: (
            <>
                Du hast jetzt einen großartigen Plan aufgestellt und kannst
                stolz auf dich sein! Ein gut durchdachter Plan ist bereits die
                halbe Miete.
                <br />
                <br />
                Bevor du loslegst und in deine erste Woche startest, geh mental
                einmal alle Schritte durch, bis du deine Handlung durchführen
                kannst. Fehlt dir noch etwas, um anfangen zu können? Benötigst
                du eventuell einen ruhigen Platz für die Umsetzung?
                <br />
                <br />
                Trage in das Textfeld ein, was du noch gebrauchen oder schon
                erledigen kannst. Auch an dieser Stelle erhältst du Vorschläge,
                die dir zur Orientierung dienen.
            </>
        ),
    },
    actionCardHeadline: {
        fitness:
            'Klicken Sie auf den Stift, um Ihre individuellen Vorbereitungsschritte einzutragen oder wählen Sie diese aus.',
        nutrition:
            'Klicken Sie auf den Stift, um Ihre individuellen Vorbereitungsschritte einzutragen oder wählen Sie diese aus.',
        relax: 'Klicken Sie auf den Stift, um Ihre individuellen Vorbereitungsschritte einzutragen oder wählen Sie diese aus.',
        energy: 'Klicken Sie auf den Stift, um Ihre individuellen Vorbereitungsschritte einzutragen oder wählen Sie diese aus.',
        sleep: 'Klicken Sie auf den Stift, um Ihre individuellen Vorbereitungsschritte einzutragen oder wählen Sie diese aus.',
        balance:
            'Klicken Sie auf den Stift, um Ihre individuellen Vorbereitungsschritte einzutragen oder wählen Sie diese aus.',
    },
    actionCardOptions: {
        fitness: [
            {
                id: 1,
                value: 'Vorschlag 1',
            },
            {
                id: 2,
                value: 'Vorschlag 2',
            },
        ],
        nutrition: [
            {
                id: 1,
                value: 'Vorschlag 1',
            },
            {
                id: 2,
                value: 'Vorschlag 2',
            },
        ],
        relax: [
            {
                id: 1,
                value: 'Vorschlag 1',
            },
            {
                id: 2,
                value: 'Vorschlag 2',
            },
        ],
        energy: [
            {
                id: 1,
                value: 'Vorschlag 1',
            },
            {
                id: 2,
                value: 'Vorschlag 2',
            },
        ],
        sleep: [
            {
                id: 1,
                value: 'Vorschlag 1',
            },
            {
                id: 2,
                value: 'Vorschlag 2',
            },
        ],
        balance: [
            {
                id: 1,
                value: 'Vorschlag 1',
            },
            {
                id: 2,
                value: 'Vorschlag 2',
            },
        ],
    },
};
