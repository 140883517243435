import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from 'shared-utils';
import header from 'mindset-mentor/src/assets/images/header/img-header-visualisierung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';
import Button from 'shared-ui/components/Elements/Button';
import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import { queries } from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import { pageRoute } from 'routes/pages';
import { preparationEnd } from 'lang/global.lang';
import { useGetCurrentUser } from 'services/data-hooks/user/useGetCurrentUser';
import Accordion from '../../Elements/Accordion';
import HabitTracker from '../../Layout/HabitTracker';

const PreparationEnd = ({ userName, date, handleNextStep }) => {
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';
    const alignItems = isMd ? 'center' : 'flex-start';

    return (
        <PageLayout
            pageName="Abschluss der Vorbereitung"
            pageImage={header}
            pageIcon="hello"
            subLine="Es Kann losgehen!"
            noIconMobile="true"
        >
            <Flex
                flexDirection="column"
                alignItems="center"
                width="100%"
                marginLeft="auto"
                marginRight="auto"
            >
                <Flex
                    flexDirection="column"
                    alignItems={alignItems}
                    width="100%"
                >
                    <Headline marginTop="0" textAlign={textAlign}>
                        {preparationEnd.mainTitle(userName)}
                    </Headline>
                </Flex>
                <Paragraph textAlign={textAlign}>
                    {preparationEnd.introParagraph(date)}
                </Paragraph>
                <Flex
                    flexDirection="column"
                    alignItems={alignItems}
                    width="100%"
                >
                    <Headline marginTop="3" textAlign={textAlign}>
                        {preparationEnd.subTitle}
                    </Headline>
                </Flex>
                <Card theme="primary" width="100%">
                    <Card theme="secondary">
                        <HabitTracker />
                    </Card>
                    <Headline marginBottom={0}>
                        Vorschau Pinnwand Habit Tracker
                    </Headline>
                </Card>
                <Paragraph margin="32px 0" textAlign={textAlign}>
                    {preparationEnd.mainParagraph}
                </Paragraph>
                <Card width="100%" theme="primary">
                    <Card theme="secondary" title="Wochenplan">
                        <Paragraph margin="0 0 20px 0">
                            Lösungen für mögliche Hürden
                        </Paragraph>

                        <Accordion items={preparationEnd.accordionItems} />
                    </Card>
                    <Headline marginBottom={0}>
                        Vorschau Pinnwand Wochenplan
                    </Headline>
                </Card>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Paragraph margin="32px 0" textAlign={textAlign}>
                        {preparationEnd.subParagraph2(date)}
                    </Paragraph>
                    <Paragraph margin="0 0 32px 0" textAlign={textAlign}>
                        {preparationEnd.subParagraph3}
                    </Paragraph>
                    <Button
                        label="Zur Pinnwand!"
                        maxWidth="320px"
                        margin="0 0 16px"
                        onClick={handleNextStep}
                    />
                </Flex>
            </Flex>
        </PageLayout>
    );
};

PreparationEnd.propTypes = {
    userName: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    handleNextStep: PropTypes.func.isRequired,
};

export const ConnectedPreparationEnd = () => {
    const { data: currentUserData } = useGetCurrentUser();
    const handleNextStep = () => {
        navigate(pageRoute.bulletBoard);
    };

    if (!currentUserData) {
        return null;
    }

    return (
        <PreparationEnd
            userName={currentUserData.userName}
            handleNextStep={handleNextStep}
        />
    );
};

export default PreparationEnd;
