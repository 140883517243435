import PropTypes from 'prop-types';
import React from 'react';
import {navigate} from 'shared-utils';

import {pageRoute} from 'routes/pages';

import {SubCard} from './styles';

const ContentItem = ({label, isDone, isActive}) => {
    const handleOnClick = () => {
        if (!isActive) {
            return null;
        }
        if (!isDone) {
            navigate(pageRoute.courseWeek);
        }
    };

    return (
        <SubCard
            isDone={isDone}
            isHoverBg
            label={label}
            onClick={handleOnClick}
        />
    );
};

ContentItem.propTypes = {
    label: PropTypes.string.isRequired,
    isDone: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
};

export default ContentItem;
