import styled from 'styled-components';

import {font, queries} from 'shared-ui/styles/variables';

export const StyledList = styled.ul`
    counter-reset: listCounter;
    padding: 0;
`;

export const StyledListElement = styled.li`
    position: relative;
    padding: ${(props) => (props.showBottomLine ? '8px' : '0')};
    padding-left: 48px;
    list-style-type: none;

    &::before {
        content: ${(props) =>
            props.numeric ? "counter(listCounter)'.'" : "'•'"};
        counter-increment: listCounter;
        position: absolute;
        left: 24px;

        color: ${(props) => props.markerColor};
        font-family: ${font.type.mulishBold};
        font-weight: ${font.weight.bold};

        top: ${(props) => (!props.numeric ? '0px' : '6px')};
        font-size: ${(props) => (props.numeric ? '16px' : '24px')};

        @media ${queries.lg} {
            top: ${(props) => (!props.numeric ? '3px' : '10px')};
            font-size: ${(props) => (props.numeric ? '16px' : '24px')};
        }
    }

    &::after {
        content: '';
        display: ${(props) => (props.showBottomLine ? 'block' : 'none')};
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: white;
    }
`;
