export const unNormalizeReminderSettings = async (data) => {
    if (!data) return null;
    const normalizedMap = {
        email_notification: data.emailNotification,
        email_time: data.emailTime,
        sms_notification: data.smsNotification,
        sms_time: data.smsTime,
        privacy_policy: data.isPrivacyPolicy,
    };
    return normalizedMap;
};
