import PropTypes from 'prop-types';
import React from 'react';
import {navigate, useParams} from 'shared-utils';

import header from 'mindset-mentor/src/assets/images/header/img-header-kurs-woche-1.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Image from 'shared-ui/components/Elements/Image';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {cardRadius, queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import InfoListing from 'components/Layout/InfoListing';
import References from 'components/Layout/References';

import {pageRoute} from 'routes/pages';

import {lang} from 'lang/global.lang';

import {courses} from 'data/courses-list.data';

const CourseStartPageTemplate = ({
    identifier,
    handleNextPage,
    handleBackButton,
}) => {
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    const selectedCourse = courses.find((c) => c.identifier === identifier);
    if (!selectedCourse) return null;
    const {name, startPageData} = selectedCourse;

    return (
        <PageLayout
            pageName={name}
            pageIcon="fitness"
            pageImage={header}
            subLine={isMd ? 'Willkommen im Kurs' : null}
        >
            <Flex
                flexDirection="column"
                alignItems="center"
                width={isMd ? '80%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {startPageData.mainTitle}
                </Headline>
                <Paragraph textAlign={textAlign}>
                    {startPageData.mainParagraph}
                </Paragraph>
                <InfoListing
                    iconShadow={false}
                    items={startPageData.infoListItems}
                />
                <Div
                    width={isMd ? '70%' : ''}
                    marginLeft="auto"
                    marginRight="auto"
                >
                    <Flex
                        alignItems="center"
                        marginTop="30px"
                        marginBottom="30px"
                        flexDirection="column"
                    >
                        <Flex justifyContent="center" width="100%">
                            <Card>
                                <Image
                                    src={startPageData.startPageImage}
                                    alt={startPageData.startPageImageAlt}
                                    radius={cardRadius}
                                />
                            </Card>
                        </Flex>

                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            flexDirection={isMd ? 'row' : 'column'}
                            width="100%"
                            marginTop="64px"
                        >
                            <Button
                                label={lang.courseSelect}
                                onClick={handleNextPage}
                                margin="8px"
                            />
                            <Button
                                theme="outline"
                                onClick={handleBackButton}
                                label={lang.backToCourseSelection}
                                margin="8px"
                            />
                        </Flex>
                    </Flex>
                    <References items={startPageData.references} />
                </Div>
            </Flex>
        </PageLayout>
    );
};

CourseStartPageTemplate.propTypes = {
    identifier: PropTypes.string.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    handleBackButton: PropTypes.func.isRequired,
};

export const ConnectedCourseStartPageTemplate = () => {
    const {identifier} = useParams();

    const handleNextPage = () => {
        navigate(`${pageRoute.courseDashboard}/${identifier}`);
    };

    const handleBackButton = () => {
        navigate(pageRoute.mainPath);
    };

    return (
        <CourseStartPageTemplate
            handleNextPage={handleNextPage}
            identifier={identifier}
            handleBackButton={handleBackButton}
        />
    );
};

export default CourseStartPageTemplate;
