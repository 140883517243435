import axios from 'axios';

import {baseUrl} from 'shared-utils/utils/api/variables';

const makeRequest = async (url, method, data = {}) => {
    const cachedToken = localStorage.getItem('userToken');
    const parsedToken = JSON.parse(cachedToken);
    const config = {
        method,
        url: `${baseUrl}/api/mindset-mentor/v1${url}`,
        headers: {
            Authorization: `Bearer ${parsedToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        data,
    };

    try {
        const response = await axios(config);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        throw error.response.data;
    }
};

export {axios, makeRequest};
