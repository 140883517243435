import PropTypes from 'prop-types';
import React from 'react';
import { navigate, useMatch } from 'shared-utils';
import Headline from 'shared-ui/components/Elements/Headline';
import Icon, { icons } from 'shared-ui/components/Elements/Icon';
import Div from 'shared-ui/components/Layout/Div';
import ServiceCenter from 'shared-ui/components/Layout/ServiceCenter';
import { color, queries } from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import { pageRoute } from 'routes/pages';
import { BackgroundImage, ButtonContainer, Header, IntroIcon, IntroImage, PageWrapper, StyledBackButton, StyledButton, StyledFlex } from './styles';


const PageLayout = ({
    pageName,
    pageIcon,
    pageImage,
    subLine,
    children,
    noHeader,
    noIconMobile,
}) => {
    const isMd = useMediaQuery(queries.md);
    const isSettingPage = useMatch('/mindset-mentor/einstellungen');

    return (
        <Div>
            {isMd && (
                <Headline
                    marginTop="80px"
                    marginBottom="80px"
                    textAlign="center"
                    type="h2"
                >
                    {pageName}
                </Headline>
            )}

            <Div paddingBottom={isMd ? '96px' : '0'}>
                {!noHeader ? (
                    <>
                        <BackgroundImage />
                        <Header
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {!isMd && pageIcon && !noIconMobile && (
                                <Icon
                                    color="white"
                                    name={pageIcon}
                                    size="64px"
                                />
                            )}

                            {isMd ? (
                                <Headline theme="white" textAlign="center">
                                    {subLine}
                                </Headline>
                            ) : (
                                <Headline
                                    marginTop="0"
                                    marginBottom="0"
                                    theme="white"
                                    textAlign="center"
                                    type="h2"
                                >
                                    {pageName}
                                </Headline>
                            )}

                            <ButtonContainer justifyContent="flex-end">
                                {window.history.state ? (
                                    <StyledBackButton />
                                ) : null}

                                <StyledButton
                                    bg
                                    color={color.orange}
                                    name="settings"
                                    size="64px"
                                    onClick={() =>
                                        isSettingPage
                                            ? null
                                            : navigate(pageRoute.settings)
                                    }
                                />
                                <StyledButton
                                    bg
                                    color={color.orange}
                                    name="help"
                                    size="64px"
                                    onClick={() =>
                                        navigate(
                                            'https://mein.ms-life.de/service/service-team'
                                        )
                                    }
                                />
                            </ButtonContainer>
                        </Header>
                    </>
                ) : null}

                <PageWrapper>
                    {isMd && pageImage && (
                        <IntroImage
                            src={pageImage}
                            marginBottom={pageIcon ? '80px' : '16px'}
                        >
                            {pageIcon && (
                                <IntroIcon
                                    bg
                                    color="red"
                                    name={pageIcon}
                                    size="80px"
                                />
                            )}
                        </IntroImage>
                    )}
                    <StyledFlex marginBottom="48px" flexDirection="column">
                        {!isMd && subLine && (
                            <Headline theme="red" textAlign="center">
                                {subLine}
                            </Headline>
                        )}

                        {children}
                    </StyledFlex>

                    <ServiceCenter />
                </PageWrapper>
            </Div>
        </Div>
    );
};

PageLayout.propTypes = {
    children: PropTypes.any.isRequired,
    pageName: PropTypes.string.isRequired,
    pageIcon: PropTypes.oneOf(Object.keys(icons)),
    pageImage: PropTypes.string,
    subLine: PropTypes.string,
    noHeader: PropTypes.bool,
    noIconMobile: PropTypes.bool,
};

PageLayout.defaultProps = {
    pageName: 'Template',
    pageIcon: null,
    pageImage: null,
    subLine: '',
    noHeader: false,
    noIconMobile: false,
};

export default PageLayout;