import React from 'react';

import FitnessStartPageImage from 'mindset-mentor/src/assets/images/img-fitness-start-page.png';

export {FitnessStartPageImage};

export const startPageData = {
    startPageImage: FitnessStartPageImage,
    startPageImageAlt: 'img-fitness-start-page',
    references: [
        {
            prefix: '1',
            ref: 'https://www.dmsg.de/multiple-sklerose-infos/ms-und-sport/einfuehrung/der-zusammenhang-zwischen-ms-und-sport/',
        },
    ],
    mainTitle: <>Der Kurs für alle, die sich mehr Bewegung im Leben wünschen</>,
    mainParagraph: (
        <>
            Hast du Lust, dich mal wieder so richtig fit zu fühlen? Dann ist der
            Kurs für mehr Fitness und Bewegung genau das Richtige für dich.
        </>
    ),
    infoListItems: [
        {
            icon: 'sitemap',
            text: (
                <>
                    Dein <strong>persönlicher Motivationsplan</strong> für ein
                    vitaleres Leben.
                </>
            ),
        },
        {
            icon: 'hourglass',
            text: (
                <>
                    <strong>Wenig Zeit? Kein Problem!</strong> <br /> Die
                    Bewegungs- und Fitnesseinheiten werden gemeinsam mit dir
                    optimal an deinen Alltag angepasst.
                </>
            ),
        },
        {
            icon: 'fitness',
            text: (
                <>
                    Ob daheim oder im <strong>Freien</strong>, du entscheidest,
                    wo und was du für Übungen ausführen möchtest,{' '}
                    <strong>
                        um mehr Bewegung in deinen Tagesablauf zu integrieren.
                    </strong>
                </>
            ),
        },
    ],
};
