export const normalizeCourses = async ({data}) => {
    if (!data) return null;
    const normalizedMap = data.map((item) => ({
        identifier: item.identifier,
        name: item.name,
        participationsCount: item.participations_count,
        userParticipationsCount: item.user_participations_count,
    }));
    return normalizedMap;
};
