import React from 'react';
import PropTypes from 'prop-types';

import Headline from 'shared-ui/components/Elements/Headline';

import {StyledRadioButton, StyledRadio, Input} from './styles';

const RadioButton = ({name, value, label, id, onChange, checked}) => {
    return (
        <StyledRadioButton htmlFor={id}>
            <Input
                type="radio"
                name={name}
                value={value}
                id={id}
                checked={checked}
                onChange={(e) => onChange(e)}
            />
            <StyledRadio />

            <Headline marginTop="16px">{label}</Headline>
        </StyledRadioButton>
    );
};

RadioButton.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    name: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

RadioButton.defaultProps = {
    id: 'radio1',
    name: 'radio',
    checked: false,
    onChange: () => {},
};

export default RadioButton;
