import styled from 'styled-components';

import Badge from 'shared-ui/components/Elements/Badge';
import {font} from 'shared-ui/styles/variables';

export const StyledBadge = styled(Badge)`
    margin: 8px;
    color: ${font.color.grey};
    padding: 8px 16px;
    font-size: ${font.size.xsmall};
`;
