import React from 'react';

import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Error from 'assets/images/img-error.png';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import Button from 'shared-ui/components/Elements/Button';

import {StyledImage} from './styles';
import {navigate} from 'shared-utils';

import {pageRoute} from 'routes/pages';
import {lang} from 'lang/global.lang';

const ErrorPageTemplate = () => {
    return (
        <PageLayout pageName="404">
            <Flex
                flexDirection="column"
                alignItems="center"
                marginLeft="auto"
                marginRight="auto"
            >
                <Paragraph
                    bold
                    theme="red"
                    textAlign="center"
                    size="xxlarge"
                    margin="32px 0"
                >
                    {lang.hoppla}!
                </Paragraph>
                <Paragraph
                    bold
                    theme="darkBlue"
                    margin="0 0 32px 0"
                    textAlign="center"
                >
                    {lang.pageNotFound}
                </Paragraph>
                <StyledImage src={Error} alt="Error 404" />
                <Paragraph margin="32px 0" textAlign="center">
                    Lorem Ipsum dolor sit amet.
                </Paragraph>

                <Button
                    label="Zurück zur Startseite"
                    maxWidth="320px"
                    margin="0 0 16px"
                    onClick={() => {
                        navigate(pageRoute.mainPath);
                    }}
                />
            </Flex>
        </PageLayout>
    );
};

export default ErrorPageTemplate;
