import {useEffect, useState} from 'react';

export const calculateServerDate = async () => {
    const {headers} = await fetch(window.location, {
        cache: `no-store`,
        method: `HEAD`,
    });

    const serverDate = headers.get(`Date`);
    return serverDate;
};
export const useCalculateServerDate = async () => {
    const [date, setDate] = useState();
    const {headers} = await fetch(window.location, {
        cache: `no-store`,
        method: `HEAD`,
    });

    const serverDate = headers.get(`Date`);

    useEffect(() => {
        setDate(serverDate);
    }, [serverDate]);

    return {date};
};
