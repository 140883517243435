import React from 'react';
import PropTypes from 'prop-types';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {icons} from 'shared-ui/components/Elements/Icon';

import {StyledCard, StyledIcon, CheckMark, CardContent} from './styles';
import Div from 'shared-ui/components/Layout/Div';

const ChapterCard = ({
    label,
    iconName,
    children,
    isOpen,
    onClick,
    isDone,
    className,
}) => {
    return (
        <Div>
            <StyledCard
                isHoverBg
                onClick={(e) => onClick(e)}
                className={className}
            >
                {iconName && <StyledIcon name={iconName} color="red" />}
                {isDone && <CheckMark />}

                <Paragraph>{label}</Paragraph>
            </StyledCard>

            <CardContent isOpen={isOpen}>{children}</CardContent>
        </Div>
    );
};

ChapterCard.propTypes = {
    label: PropTypes.string.isRequired,
    iconName: PropTypes.oneOf(Object.keys(icons)),
    isDone: PropTypes.bool,
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.any,
};

ChapterCard.defaultProps = {
    label: 'Label',
    isDone: false,
    iconName: null,
    isOpen: false,
    className: 'ap-chaptercard',
    onClick: () => {},
    children: null,
};

export default ChapterCard;
