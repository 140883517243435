import PropTypes from 'prop-types';
import React from 'react';
import {useMediaQuery} from 'shared-utils';

import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Headline from 'shared-ui/components/Elements/Headline';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import ProgressBar from 'shared-ui/components/Elements/ProgressBar';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {Column, Container, Row} from 'shared-ui/components/Layout/Grid';
import {queries} from 'shared-ui/styles/variables';

import ConnectedTips from 'components/Layout/Tips';

import {lang} from 'lang/global.lang';

import {courses} from 'data/courses-list.data';
import {courseWeekData} from 'data/courses/course-week.data';

import {useGetCompletedSteps} from 'services/data-hooks/course/useGetCompletedSteps';
import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';

import {useCurrentIdentifier} from 'hooks/useCurrentIdentifier';

import ChapterItem from './ChapterItem';

const CourseWeekTemplate = ({identifier, currentWeek, completedSteps}) => {
    const isMd = useMediaQuery(queries.md);

    const {chapter1, chapter2, chapter3} = courseWeekData;

    const course = courses.find((course) => course.identifier === identifier);

    return (
        <PageLayout
            pageName={course.name}
            pageImage={course.coverImage}
            pageIcon={isMd ? 'fitness' : null}
            subLine={isMd ? lang.startYourTasks : null}
        >
            <Container>
                <Flex alignItems="center" flexDirection="column">
                    {!isMd && <Icon color="red" name="fitness" size="64px" />}
                    {!isMd && (
                        <Headline bold type="h3">
                            {lang.startYourTasks}
                        </Headline>
                    )}
                </Flex>
                <Paragraph bold textAlign="center" theme="darkBlue">
                    Woche {currentWeek.weekNumber} von 3
                </Paragraph>
                <Div
                    marginBottom="20px"
                    marginTop="20px"
                    marginLeft="auto"
                    marginRight="auto"
                    width={isMd ? '500px' : '100%'}
                >
                    <ProgressBar
                        steps={3}
                        activeStep={currentWeek.weekNumber}
                    />
                </Div>
                <Div marginBottom="20px">
                    <Paragraph bold theme="red">
                        {chapter1.title}
                    </Paragraph>
                    <Paragraph bold>{chapter1.name}</Paragraph>
                </Div>
                <Row>
                    {chapter1.items.map((item) => (
                        <Column key={item.id} cols={{sm: 6, xl: 4}}>
                            <ChapterItem
                                item={item}
                                completedSteps={completedSteps}
                            />
                        </Column>
                    ))}
                </Row>
                <Div marginBottom="20px" marginTop={isMd ? '0' : '20px'}>
                    <Paragraph bold theme="red">
                        {chapter2.title}
                    </Paragraph>
                    <Paragraph bold>{chapter2.name}</Paragraph>
                </Div>
                <Row>
                    {chapter2.items.map((item) => (
                        <Column key={item.id} cols={{sm: 6, xl: 4}}>
                            <ChapterItem
                                item={item}
                                completedSteps={completedSteps}
                            />
                        </Column>
                    ))}
                </Row>
                <Div marginBottom="20px" marginTop={isMd ? '0' : '20px'}>
                    <Paragraph bold theme="red">
                        {chapter3.title}
                    </Paragraph>
                    <Paragraph bold>{chapter3.name}</Paragraph>
                </Div>
                <Row>
                    {chapter3.items.map((item) => (
                        <Column key={item.id} cols={{sm: 6, xl: 4}}>
                            <ChapterItem
                                item={item}
                                completedSteps={completedSteps}
                            />
                        </Column>
                    ))}
                </Row>

                <Flex justifyContent="center" marginTop="40px">
                    <Column cols={{lg: 6, sm: 12}}>
                        <ConnectedTips />
                    </Column>
                </Flex>
            </Container>
        </PageLayout>
    );
};

CourseWeekTemplate.propTypes = {
    currentWeek: PropTypes.object.isRequired,
    completedSteps: PropTypes.array.isRequired,
    identifier: PropTypes.string.isRequired,
};

export const ConnectedCourseWeekTemplate = () => {
    const {data: currentWeek} = useGetCurrentWeek();
    const {data: completedSteps} = useGetCompletedSteps();

    const {identifier} = useCurrentIdentifier();

    if (!currentWeek || !completedSteps || !identifier) {
        return null;
    }

    return (
        <CourseWeekTemplate
            identifier={identifier}
            currentWeek={currentWeek}
            completedSteps={completedSteps}
        />
    );
};

export default CourseWeekTemplate;
