// import React from 'react';

export const step2_3 = {
    mainTitle: {
        fitness: 'Das möchtest du diese Woche erreichen:',
        nutrition: 'Das möchtest du diese Woche erreichen:',
        relax: 'Das möchtest du diese Woche erreichen:',
        energy: 'Das möchtest du diese Woche erreichen:',
        sleep: 'Das möchtest du diese Woche erreichen:',
        balance: 'Das möchtest du diese Woche erreichen:',
    },
    mainParagraph: {
        fitness: 'Du hast dir folgenden Habit ausgesucht:',
        nutrition: 'Du hast dir folgenden Habit ausgesucht:',
        relax: 'Du hast dir folgenden Habit ausgesucht:',
        energy: 'Du hast dir folgenden Habit ausgesucht:',
        sleep: 'Du hast dir folgenden Habit ausgesucht:',
        balance: 'Du hast dir folgenden Habit ausgesucht:',
    },
};
