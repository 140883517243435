import React from 'react';

import List from 'components/Elements/List';
import Headline from 'shared-ui/components/Elements/Headline';
import Image from 'shared-ui/components/Elements/Image';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

import IntroImage from 'assets/images/onboarding-image.png';

const listItems = [
    'Das Tool für ein gesünderes Leben und mehr Wohlbefinden',
    'Individuelle Abstimmung auf deine persönlichen Ziele',
    'Digital und unkompliziert',
    'Durch kleine einzelne Schritte kommst du Stück für Stück deinem Ziel näher',
    'Du bestimmst die Richtung sowie das Tempo und entscheidest, was du als Nächstes erreichen möchtest',
    'Eine unterstützende und motivierende Umgebung für deinen Weg zum Ziel',
];

export const screens = [
    {
        headline: '',
        content: (
            <>
                <Image src={IntroImage} alt="MINDSETmentor" />
                <Headline type="h2" theme="white" textAlign="center">
                    MINDSETmentor
                </Headline>
            </>
        ),
        buttonLabel: "Los geht's!",
    },
    {
        headline: 'Schritt für Schritt zu mehr Wohlbefinden im Alltag',
        content: (
            <>
                <Paragraph theme="white" textAlign="center">
                    Kennst du das auch? Wir nehmen uns vor, uns gesünder zu
                    verhalten, zum Beispiel mit einer ausgewogeneren Ernährung
                    oder mehr Bewegung. Pläne werden geschmiedet, wir sind top
                    motiviert und - zack - zwei Tage später fällt es uns wieder
                    schwer, das Vorhaben durchzuziehen. Wir hängen unser Ziel
                    wieder an den Nagel.
                </Paragraph>
                <Paragraph theme="white" textAlign="center">
                    Wenn dir das bekannt vorkommt, solltest du den MINDSETmentor
                    ausprobieren. Er soll dir das nötige Werkzeug geben, an
                    deinen Zielen langfristig festzuhalten und gesunde Routinen
                    in den Alltag zu integrieren.
                </Paragraph>
            </>
        ),
        buttonLabel: 'Weiter',
    },
    {
        headline: 'Welche Vorteile bietet mir der MINDSETmentor?',
        content: (
            <>
                <List items={listItems} markerColor="white" textColor="white" />
            </>
        ),
        buttonLabel: 'Weiter',
    },
    {
        headline: 'Wie funktioniert der MINDSETmentor?',
        content: (
            <>
                <Paragraph theme="white" textAlign="center">
                    Als Erstes erarbeitest du dir mit dem MINDSETmentor, was
                    deine individuelle Wunschvorstellung für die Zukunft ist.
                    Möchtest du vielleicht richtig fit und beweglich sein?
                    Würdest du gerne regelmäßig frisch und gesund essen?
                    Wünschst du dir mehr Entspannung im alltäglichen Leben? Der
                    MINDSETmentor hilft dir, dein Ziel zu erreichen und
                    begleitet dich Schritt für Schritt auf dem Weg zu deinem
                    Langzeit-Ziel. Unterwegs erhältst du reichlich
                    Motivations-Tipps, damit du sicher ankommst und der Spaß
                    nicht auf der Strecke bleibt.
                </Paragraph>
            </>
        ),
        buttonLabel: 'Weiter',
    },
];
