import PropTypes from 'prop-types';
import React from 'react';

import Flex from '../../Layout/Flex';
import Paragraph from '../Paragraph';
import {CheckMark, StyledCard, StyledIcon} from './styles';

const SelectCard = ({
    isSuccessful,
    handleOnClick,
    title,
    iconName,
    isDisabled,
}) => {
    return (
        <StyledCard
            width="100%"
            isHoverBg
            onClick={!isDisabled ? handleOnClick : undefined}
            isDisabled={isDisabled}
        >
            <Flex flexDirection="row" alignItems="center">
                <StyledIcon name={iconName} size="35px" color="red" />
                <Paragraph theme="black">{title}</Paragraph>
                {isSuccessful && <CheckMark />}
            </Flex>
        </StyledCard>
    );
};
SelectCard.propTypes = {
    isSuccessful: PropTypes.bool,
    handleOnClick: PropTypes.func,
    iconName: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
};

SelectCard.defaultProps = {
    isSuccessful: false,
    handleOnClick: () => {},
};

export default SelectCard;
