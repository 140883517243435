import PropTypes from 'prop-types';
import React from 'react';



import Div from 'shared-ui/components/Layout/Div';

import ListItem from './ListItem';
import {useWeeklyOverviewData} from './useWeeklyOverviewData';

const WeeklyOverview = ({completedSteps, currentWeek}) => {
    const {normalizedItems} = useWeeklyOverviewData({
        completedSteps,
        weekNumber: currentWeek.weekNumber,
        startDate: currentWeek?.startDate,
        confirmedAt: currentWeek?.confirmedAt,
    });
    return (
        <Div width="100%">
            {normalizedItems.map(
                ({label, content, isDone}, index) => (
                    <ListItem
                        key={index}
                        label={label}
                        content={content}
                        items={normalizedItems}
                        isDone={isDone}
                        index={index}
                    />
                )
            )}
        </Div>
    );
};

WeeklyOverview.propTypes = {
    completedSteps: PropTypes.array.isRequired,
    currentWeek: PropTypes.object.isRequired,
};

export default WeeklyOverview;