import styled from 'styled-components';

import Image from 'shared-ui/components/Elements/Image';
import Div from 'shared-ui/components/Layout/Div';
import {queries} from 'shared-ui/styles/variables';

export const StyledImage = styled(Image)`
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 350px;

    @media ${queries.md} {
        max-height: 550px;
    }
`;

export const GifWrapper = styled(Div)`
    height: 300px;
    overflow: hidden;

    @media ${queries.md} {
        height: 400px;
    }
`;
