import {useMutation} from 'react-query';
import {notify} from 'shared-utils';

import {confirmWeekPlan} from 'services/config/endpoints';
import {makeRequest} from 'services/config/mindset-mentor-api';
import {CURRENT_WEEK} from 'services/config/query-keys';
import {normalizeCurrentWeek} from 'services/normalize/current-week';

import {useSetQueryData} from 'hooks/useSetQueryData';

import {setErrorMessage} from 'helpers/error-message';

const mutateConfirmWeekPlan = async () => {
    const response = await makeRequest(confirmWeekPlan, 'POST');
    return response;
};

export function usePostConfirmWeekPlan() {
    const {setData, getData} = useSetQueryData();

    const mutate = useMutation(mutateConfirmWeekPlan, {
        onError: (error, variables, previousValue) => {
            const errorMessage = setErrorMessage(error);
            notify.show(errorMessage, 'error');
            setData(CURRENT_WEEK, previousValue);
        },
        onMutate: async (data) => {
            const previousValue = await getData(CURRENT_WEEK);
            const normalized = await normalizeCurrentWeek({data});
            const mergedCurrentWeekData = {
                ...previousValue,
                ...normalized,
            };
            setData([CURRENT_WEEK], mergedCurrentWeekData);

            return previousValue;
        },
    });
    return mutate;
}
