import React from 'react';


import bronze from 'mindset-mentor/src/assets/images/img-medaille-bronze-2x.png';
import gold from 'mindset-mentor/src/assets/images/img-medaille-gold-2x.png';
import silber from 'mindset-mentor/src/assets/images/img-medaille-silber-2x.png';


export const lang = {
    notificationSettings: 'Einstellungen Benachrichtigungen',
    notificationOptIn: 'Möchtest du die Benachrichtigungen erhalten?',
    currentWeek: (week, maxWeeks) => `Woche ${week} von ${maxWeeks}`,
    daysTrained: (days) => `Du hast an ${days} Tagen deinen Habit umgesetzt!`,
    references: 'Quellen:',
    courseStart: 'Jetzt Kurs starten!',
    courseSelect: 'Kurs auswählen',
    backToCourseSelection: 'Zurück zur Kursauswahl',
    onToTheVision: 'Weiter zur Vision',
    advance: 'Weiter',
    courseTutorialTitle: (number, name) => (
        <>
            Tutorial {number} <br/> {name} Kurs
        </>
    ),
    optimalTarget: 'Optimalziel',
    minTarget: 'Minimalziel',
    maxTarget: 'Maximalziel',
    daysOfWeek: 'Tage die Woche',
    informationSatisfied: 'Bist du mit deinen Angaben zufrieden?',
    noAdjustGoal: 'Nein, Ziele anpassen',
    shareSuccess: 'Teile deine Erfolge:',
    startYourTasks: 'Starte deine Aufgaben',
    habitTracker: 'Habit-Tracker',
    enterMyActivities: 'Meine Aktivitäten eintragen',
    weekEnded: (weekNumber) => `Woche ${weekNumber} beenden`,
    overviewWeek: 'Deine Eingaben für die Woche im Überblick',
    cancelCourse:
        'Du möchtest wirklich den Kurs abbrechen? Bitte beachte, dass alle von dir eingetragenen Visionen, Habits, ToDos usw. gelöscht werden und du von vorne mit der Erstellung deines Wochenplans beginnen musst! Wenn du Hilfe benötigst kannst du dich jederzeit an dein mslife Service-Center wenden.',
    confirmCancel: 'Wirklich abbrechen?',
    restartCourse: 'Ja, ich möchte den Kurs abbrechen und von vorne beginnen',
    toHabitTracker: 'Zum Habit Tracker',
    saveAndGo: (to) => `Speichern und weiter ${to ?? ''}`,
    completedChapter:
        'Da Sie diesen Abschnitt abgeschlossen haben, können Sie keine Änderungen mehr vornehmen.',
    privacypolicySelectedError:
        'Die Datenschutzbestimmung muss ausgefüllt werden',
    startDate: 'Startdatum',
    weekPreparation: 'Wochen Vorbereitung',
    hoppla: 'Hoppla',
    pageNotFound: 'Seite nicht gefunden.',
    weekPlan: 'Wochenplan',
    confirmTrackedHabits: (
        <>
            ACHTUNG!
            <br/>
            Hast du wirklich alle Habits getrackt? Wenn ja, drücke auf
            Fortfahren.
        </>
    ),
};

export const prizes = {
    gold: {
        label: 'Gold',
        intro: (user) => `${user}, du hast dein Ziel erreicht!`,
        text: (
            <>
                Wow, du hast es geschafft! Du hast dein{' '}
                <strong>Maximalziel</strong> erreicht und kannst jetzt sehr
                stolz auf dich sein. Das war eine starke Leistung. Jetzt ist es
                wichtig, deine neuen Habits weiterhin zu pflegen.
                <br/>
                <br/>
                <strong>Mach weiter so!</strong>
            </>
        ),
        image: gold,
    },
    silver: {
        label: 'Silber',
        intro: (user) => `${user}, du hast dein Ziel erreicht!`,
        text: (
            <>
                Wow, du hast es geschafft! Du hast dein{' '}
                <strong>Optimalziel</strong> erreicht und kannst jetzt sehr
                stolz auf dich sein. Das war eine starke Leistung. Jetzt ist es
                wichtig, deine neuen Habits weiterhin zu pflegen.
                <br/>
                <br/>
                <strong>Mach weiter so!</strong>
            </>
        ),
        image: silber,
    },
    bronze: {
        label: 'Bronze',
        intro: (user) => `${user}, du hast dein Ziel erreicht!`,
        text: (
            <>
                Wow, du hast es geschafft! Du hast dein{' '}
                <strong>Minimalziel</strong> erreicht und kannst jetzt sehr
                stolz auf dich sein. Das war eine starke Leistung. Jetzt ist es
                wichtig, deine neuen Habits weiterhin zu pflegen.
                <br/>
                <br/>
                <strong>Mach weiter so!</strong>
            </>
        ),
        image: bronze,
    },
    consolation: {
        label: 'Trostpreis',
        intro: (user) => `${user}, du bist auf dem richtigen Weg.`,
        text: (
            <>
                Verfolge deine Ziele weiterhin!
                <br/>
                <br/>
                Dieses Mal hat es nicht wie geplant funktioniert. Auch solche
                Erfahrungen gehören langfristig zu dieser Reise dazu. Doch davon
                lässt du dich nicht aufhalten und bleibst am Ball! Denn der
                Schlüssel liegt darin, den Fokus nicht auf die Sachen zu legen,
                die nicht geklappt haben, sondern zu reflektieren: Was kann ich
                beim nächsten Mal anders machen? Welche Hilfe kann ich mir
                selbst geben, um meine Ziele zu erreichen?
                <br/>
                <br/>
                Das Entscheidende ist: Du hast angefangen an dir und einem
                gesunden Lebensstil zu arbeiten – mach damit weiter!
            </>
        ),
        image: bronze,
    },
    participationMedal: {
        label: 'Trostpreis',
        intro: (user) => `${user}, du bist auf dem richtigen Weg.`,
        text: (
            <>
                Verfolge deine Ziele weiterhin!
                <br/>
                <br/>
                Dieses Mal hat es nicht wie geplant funktioniert. Auch solche
                Erfahrungen gehören langfristig zu dieser Reise dazu. Doch davon
                lässt du dich nicht aufhalten und bleibst am Ball! Denn der
                Schlüssel liegt darin, den Fokus nicht auf die Sachen zu legen,
                die nicht geklappt haben, sondern zu reflektieren: Was kann ich
                beim nächsten Mal anders machen? Welche Hilfe kann ich mir
                selbst geben, um meine Ziele zu erreichen?
                <br/>
                <br/>
                Das Entscheidende ist: Du hast angefangen an dir und einem
                gesunden Lebensstil zu arbeiten – mach damit weiter!
            </>
        ),
        image: bronze,
    },
};

export const service = {
    headline: (
        <>
            Du hast Fragen?
            <br/>
            Melde dich bei uns
        </>
    ),
    telIntro: (
        <>
            Ruf uns an
            <br/>
            Wir sind für dich da
            <br/>
            tel:{' '}
        </>
    ),
    tel: '+49 800 034 345',
    mailIntro: 'E-Mail: ',
    mail: 'info@loremipsum.de',
};

export const weekPreparation = {
    mainTitle: 'Mit inspirierenden Nachrichten durch deine Motivationswoche',
    mainParagraph: (
        <>
            <strong>
                Super, die wichtigsten Schritte hast du jetzt gemacht!
            </strong>
            <br/>
            <br/>
            Bald startet deine Motivationswoche. Halte deine Erfolge gerne in
            der Pinnwand fest. Mit nur einem Klick hast du deine Erfolge immer
            im Blick und es erwartet dich dort eine kleine Überraschung.
            <br/>
            <br/>
            Damit du weiterhin am Ball bleibst, kommt dein täglicher
            Motivationskick direkt in dein Postfach, wenn du magst. In der
            E-Mail erwarten dich tolle Sportübungen und viele Anregungen, wie du
            mehr Bewegung mit Leichtigkeit in deinen Alltag integrieren kannst.
            Per SMS erhältst du knackig-kurze Impulse und positive Statements.
            Die erste Nachricht erhältst du am Starttag deiner Motivationswoche.
        </>
    ),
    startWeek: 'Starte jetzt in deine 1. Woche!',
};

export const habitcheck1 = {
    mainTitle: 'Das möchtest du diese Woche erreichen:',
    subTitle1: 'Du hast dir folgenden Habit ausgesucht:',
    mainParagraph: (
        <>
            Bevor du deine Planung fortführen kannst, vergewissere dich noch
            einmal, ob dein Habit positiv formuliert ist. Das wird für die
            nächsten Schritte wichtig sein.
            <br/>
            <br/>
            Achte auch darauf, dass es ein Habit ist, den du dir angewöhnen und
            nicht abgewöhnen möchtest, da der <b>MINDSETmentor</b> speziell dazu
            dient, sich Habits anzueignen.
        </>
    ),
    subTitle2: 'Bist du mit deinem Habit zufrieden?',
};

export const habitcheck2 = {
    mainTitle: 'Welchen positiven Effekt erhoffst du dir von deinem Habit?',
    mainParagraph: (
        <>
            Dein Habit steht fest. Jetzt solltest du dir überlegen, welchen
            positiven Effekt du dir wünschst. Was möchtest du erreichen? Wie
            bringt dich der Habit deiner Vision näher? Wenn du dir das immer
            wieder vor Augen führst, wirst du dein Ziel einfacher umsetzen
            können und motivierter an die Aufgabe gehen.
        </>
    ),
    actionCard: {
        headline:
            'Klicken Sie auf den Stift, um Ihren positiven Effekt einzutragen oder wählen Sie diesen aus.',
        options: [
            {
                id: 1,
                value: 'Mich im Alltag regelmäßig bewegen',
            },
            {
                id: 2,
                value: 'Ausdauer',
            },
        ],
    },
};

export const evaluation = {
    mainTitle: (user) =>
        `${user}, herzlichen Glückwunsch, du hast deine Motivationswoche abgeschlossen!`,
    mainParagraph: (
        <>
            <b>Jetzt ist es Zeit, deine Ergebnisse auszuwerten. </b>
            <br/>
            Hast du alle Erfolge eingetragen? Trage sonst jetzt deine Erfolge
            nach, falls du dein Habit-Tracer ausgedruckt hast:
        </>
    ),
    subParagraph: (
        <>
            Ob du deine Ziele diesmal erreicht hast oder nicht, spielt dabei
            keine Rolle – du kannst stolz auf dich sein. Schritt für Schritt
            geht es voran. Was zählt, ist dranzubleiben!
        </>
    ),
};

export const plan = {
    mainTitle: 'Alles Wichtige im Überblick',
    mainParagraph:
        "Dein persönlicher Wochenplan liegt bereit! Alle Schritte, die du bis jetzt geplant hast – mögliche Hindernisse, Lösungsvorschläge und alles, was du vorweg noch erledigen möchtest –, kannst du auf deinem Plan einsehen. So hast du immer deine Vision und Ziele vor Augen. Deinen Wochenplan kannst du dir entweder direkt über den Download-Button herunterladen oder jederzeit in deiner Pinnwand aufrufen. Du kannst ihn dir auch ausdrucken. Wenn du deine Ergebnisse in der Pinnwand einträgst, erwartet sich sogar eine kleine Überraschung. Probier's doch mal aus!",
    generatePlan: 'Generierung Plan',
    weeklyPreparation: 'Weiter zur Wochenvorbereitung',
    digitalPlan: 'Zum digitalen Plan',
    exportOptions: [
        {
            label: 'Download', icon: 'pdfDownload', onClick: () => {
            }
        },
        {
            label: 'Mail', icon: 'mail', onClick: () => {
            }
        },
        {
            label: 'Drucken', icon: 'print', onClick: () => {
            }
        },
    ],
};

const formatDate = (dateString, daysToAdd = 0) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

export const preparationEnd = {
    mainTitle: (user) => `Hallo ${user},`,
    subTitle: 'So geht’s weiter',
    introParagraph: (date) => (
        <> du hast deine Vorbereitungen für deine erste Woche im Kurs abgeschlossen. Ab dem\n <b>{formatDate(date)}</b> hast du 7 Tage Zeit für die Umsetzung.</>),
    mainParagraph: (
        <> Auf der <b>Pinnwand</b> kannst du während deiner Motivationswoche stets den Überblick behalten – über <b>deine
            Wochenziele, deine Erfolge</b> und wann es womit weitergeht. Wenn du willst, kannst du deine Erfolge im <b>Habit
            Tracker</b> festhalten. Markiere einfach mit einem Klick auf den entsprechenden Tag deiner Motivationswoche,
            sobald du deinen Habit umgesetzt hast.</>
    ),
    subParagraph: (
        <> Auf der <b>Pinnwand</b> kannst du während deiner Motivationswoche stets den Überblick behalten – über <b>deine
            Wochenziele, deine Erfolge</b> und wann es womit weitergeht. Wenn du willst, kannst du deine Erfolge im <b>Habit
            Tracker</b> festhalten. Markiere einfach mit einem Klick auf den entsprechenden Tag deiner Motivationswoche,
            sobald du deinen Habit umgesetzt hast.</>
    ),

    subParagraph2: (date) => (
        <> Um deine <b>Wochenziele</b> nicht aus dem Blick zu verlieren, kannst du auf der Pinnwand auch deinen Wochenplan
            einsehen. Nachdem du deine Motivationswoche abgeschlossen hast, also am <b>{formatDate(date, 7)}</b>, melde dich
            unbedingt wieder zur Auswertung im <b>MindsetMentor</b> an.</>
    ),

    subParagraph3: (
        <>Viel Freude bei deiner Motivationswoche! <b>Los geht’s!</b></>
    ),
    accordionItems: [
        {label: 'Lösung 1', value: 'jogging'},
        {label: 'Lösung 2', value: 'solution2'},
        {label: 'Lösung 3', value: 'solution3'},
    ],
};

export const reflection = {
    weekLabel : 'Stand: ',
    title: 'Reflektion',
    subHeadline: 'Ein Moment der Reflexion:',
};