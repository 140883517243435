export const normalizeHabit = (habits) => {
    const valuesMapped = Object.entries(habits).map((habit) => {
        if (habit[1]) {
            return habit[0];
        } else {
            return null;
        }
    });
    const normalize = valuesMapped.filter((x) => x !== null);
    return normalize;
};

export const unNormalizeHabit = (habits) => {
    const unNormalize = {
        monday: habits.includes('monday'),
        tuesday: habits.includes('tuesday'),
        wednesday: habits.includes('wednesday'),
        thursday: habits.includes('thursday'),
        friday: habits.includes('friday'),
        saturday: habits.includes('saturday'),
        sunday: habits.includes('sunday'),
    };
    return unNormalize;
};
