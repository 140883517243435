import React from 'react';

export const step3_3 = {
    mainTitle: {
        fitness: 'Plane eine mögliche Lösung für dein Hindernis',
        nutrition: 'Plane eine mögliche Lösung für dein Hindernis',
        relax: 'Plane eine mögliche Lösung für dein Hindernis',
        energy: 'Plane eine mögliche Lösung für dein Hindernis',
        sleep: 'Plane eine mögliche Lösung für dein Hindernis',
        balance: 'Plane eine mögliche Lösung für dein Hindernis',
    },
    mainParagraph: {
        fitness: (
            <>
                Nachdem du dir überlegt hast, was dich von deinem Ziel abhalten
                könnte, kannst du dir jetzt auch überlegen, wie du deinen
                Schweinehund aus dem Weg räumen kannst. Neben dem Ereignis,
                welches dich von deinem Ziel abhalten könnte, schreibst du nun,
                welche Lösung sich dafür eignen könnte. Beispiel: Falls du nach
                der Arbeit zu müde bist, um deinen Plan umzusetzen, dann plane
                deine Aktivität für den nächsten Morgen. So kannst du dir für
                den Abend auch eine Ruhepause gönnen, bevor du dein Ziel umsetzt
                und ausgeruht in dein Vorhaben starten!
            </>
        ),
        nutrition: (
            <>
                Nachdem du dir überlegt hast, was dich von deinem Ziel abhalten
                könnte, kannst du dir jetzt auch überlegen, wie du deinen
                Schweinehund aus dem Weg räumen kannst. Neben dem Ereignis,
                welches dich von deinem Ziel abhalten könnte, schreibst du nun,
                welche Lösung sich dafür eignen könnte. Beispiel: Falls du nach
                der Arbeit zu müde bist, um deinen Plan umzusetzen, dann plane
                deine Aktivität für den nächsten Morgen. So kannst du dir für
                den Abend auch eine Ruhepause gönnen, bevor du dein Ziel umsetzt
                und ausgeruht in dein Vorhaben starten!
            </>
        ),
        relax: (
            <>
                Nachdem du dir überlegt hast, was dich von deinem Ziel abhalten
                könnte, kannst du dir jetzt auch überlegen, wie du deinen
                Schweinehund aus dem Weg räumen kannst. Neben dem Ereignis,
                welches dich von deinem Ziel abhalten könnte, schreibst du nun,
                welche Lösung sich dafür eignen könnte. Beispiel: Falls du nach
                der Arbeit zu müde bist, um deinen Plan umzusetzen, dann plane
                deine Aktivität für den nächsten Morgen. So kannst du dir für
                den Abend auch eine Ruhepause gönnen, bevor du dein Ziel umsetzt
                und ausgeruht in dein Vorhaben starten!
            </>
        ),
        energy: (
            <>
                Nachdem du dir überlegt hast, was dich von deinem Ziel abhalten
                könnte, kannst du dir jetzt auch überlegen, wie du deinen
                Schweinehund aus dem Weg räumen kannst. Neben dem Ereignis,
                welches dich von deinem Ziel abhalten könnte, schreibst du nun,
                welche Lösung sich dafür eignen könnte. Beispiel: Falls du nach
                der Arbeit zu müde bist, um deinen Plan umzusetzen, dann plane
                deine Aktivität für den nächsten Morgen. So kannst du dir für
                den Abend auch eine Ruhepause gönnen, bevor du dein Ziel umsetzt
                und ausgeruht in dein Vorhaben starten!
            </>
        ),
        sleep: (
            <>
                Nachdem du dir überlegt hast, was dich von deinem Ziel abhalten
                könnte, kannst du dir jetzt auch überlegen, wie du deinen
                Schweinehund aus dem Weg räumen kannst. Neben dem Ereignis,
                welches dich von deinem Ziel abhalten könnte, schreibst du nun,
                welche Lösung sich dafür eignen könnte. Beispiel: Falls du nach
                der Arbeit zu müde bist, um deinen Plan umzusetzen, dann plane
                deine Aktivität für den nächsten Morgen. So kannst du dir für
                den Abend auch eine Ruhepause gönnen, bevor du dein Ziel umsetzt
                und ausgeruht in dein Vorhaben starten!
            </>
        ),
        balance: (
            <>
                Nachdem du dir überlegt hast, was dich von deinem Ziel abhalten
                könnte, kannst du dir jetzt auch überlegen, wie du deinen
                Schweinehund aus dem Weg räumen kannst. Neben dem Ereignis,
                welches dich von deinem Ziel abhalten könnte, schreibst du nun,
                welche Lösung sich dafür eignen könnte. Beispiel: Falls du nach
                der Arbeit zu müde bist, um deinen Plan umzusetzen, dann plane
                deine Aktivität für den nächsten Morgen. So kannst du dir für
                den Abend auch eine Ruhepause gönnen, bevor du dein Ziel umsetzt
                und ausgeruht in dein Vorhaben starten!
            </>
        ),
    },
    subTitle: {
        fitness: 'Deine individuellen Hürden',
        nutrition: 'Deine individuellen Hürden',
        relax: 'Deine individuellen Hürden',
        energy: 'Deine individuellen Hürden',
        sleep: 'Deine individuellen Hürden',
        balance: 'Deine individuellen Hürden',
    },
    actionCardHeadline: {
        fitness:
            'Klicken Sie auf den Stift, um Ihre individuellen Lösungen einzutragen oder wählen Sie diese aus.',
        nutrition:
            'Klicken Sie auf den Stift, um Ihre individuellen Lösungen einzutragen oder wählen Sie diese aus.',
        relax: 'Klicken Sie auf den Stift, um Ihre individuellen Lösungen einzutragen oder wählen Sie diese aus.',
        energy: 'Klicken Sie auf den Stift, um Ihre individuellen Lösungen einzutragen oder wählen Sie diese aus.',
        sleep: 'Klicken Sie auf den Stift, um Ihre individuellen Lösungen einzutragen oder wählen Sie diese aus.',
        balance:
            'Klicken Sie auf den Stift, um Ihre individuellen Lösungen einzutragen oder wählen Sie diese aus.',
    },
    actionCardOptions: {
        fitness: [
            {
                id: 1,
                value: 'mir für meinen Habit feste Termine ein',
            },
            {
                id: 2,
                value: 'mein Habit direkt vor der Arbeit/Ausbildung/Uni',
            },
        ],
        nutrition: [
            {
                id: 1,
                value: 'mir für meinen Habit feste Termine ein',
            },
            {
                id: 2,
                value: 'mein Habit direkt vor der Arbeit/Ausbildung/Uni',
            },
        ],
        relax: [
            {
                id: 1,
                value: 'mir für meinen Habit feste Termine ein',
            },
            {
                id: 2,
                value: 'mein Habit direkt vor der Arbeit/Ausbildung/Uni',
            },
        ],
        energy: [
            {
                id: 1,
                value: 'mir für meinen Habit feste Termine ein',
            },
            {
                id: 2,
                value: 'mein Habit direkt vor der Arbeit/Ausbildung/Uni',
            },
        ],
        sleep: [
            {
                id: 1,
                value: 'mir für meinen Habit feste Termine ein',
            },
            {
                id: 2,
                value: 'mein Habit direkt vor der Arbeit/Ausbildung/Uni',
            },
        ],
        balance: [
            {
                id: 1,
                value: 'mir für meinen Habit feste Termine ein',
            },
            {
                id: 2,
                value: 'mein Habit direkt vor der Arbeit/Ausbildung/Uni',
            },
        ],
    },
    infoboxText: {
        fitness:
            'Das MS Service-Center steht dir jederzeit bei und kann dich unterstützen, Lösungen zu finden.',
        nutrition:
            'Das MS Service-Center steht dir jederzeit bei und kann dich unterstützen, Lösungen zu finden.',
        relax: 'Das MS Service-Center steht dir jederzeit bei und kann dich unterstützen, Lösungen zu finden.',
        energy: 'Das MS Service-Center steht dir jederzeit bei und kann dich unterstützen, Lösungen zu finden.',
        sleep: 'Das MS Service-Center steht dir jederzeit bei und kann dich unterstützen, Lösungen zu finden.',
        balance:
            'Das MS Service-Center steht dir jederzeit bei und kann dich unterstützen, Lösungen zu finden.',
    },
};
