import { useMutation } from 'react-query';
import { notify } from 'shared-utils';



import { reminderSettings } from 'services/config/endpoints';
import { makeRequest } from 'services/config/mindset-mentor-api';
import { CURRENT_WEEK } from 'services/config/query-keys';
import { unNormalizeReminderSettings } from 'services/normalize/reminder-settings';



import { useSetQueryData } from 'hooks/useSetQueryData';



import { setErrorMessage } from 'helpers/error-message';


const patchReminderSettings = async (values) => {
    const unNormalized = await unNormalizeReminderSettings(values);
    const response = await makeRequest(reminderSettings, 'PATCH', unNormalized);
    return response;
};

export function usePatchReminderSettings() {
    const {setData, getData} = useSetQueryData();

    const mutate = useMutation((values) => patchReminderSettings(values), {
        onSuccess: () => {
            notify.show('Eingaben erfolgreich gespeichert.', 'success');
        },
        onError: (error, variables, previousValue) => {
            const errorMessage = setErrorMessage(error);
            notify.show(errorMessage, 'error');
            setData([CURRENT_WEEK], previousValue);
        },
        onMutate: async (notifications) => {
            const previousValue = await getData([CURRENT_WEEK]);
            const newCurrentWeekData = {
                ...previousValue,
                emailNotification: notifications.emailNotification,
                emailTime: notifications.emailTime,
                smsNotification: notifications.smsNotification,
                smsTime: notifications.smsTime,
            };
            setData([CURRENT_WEEK], newCurrentWeekData);

            return previousValue;
        },
    });
    return mutate;
}