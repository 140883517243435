import React from 'react';

import Tips from 'shared-ui/components/Elements/Tips';

import {useGetTips} from 'services/data-hooks/tips/useGetTips';

import {lang} from 'shared-ui/lang/global.lang';

const ConnectedTips = () => {
    const {data} = useGetTips();
    if (!data) return null;
    return (
        <Tips
            iconPosition="right"
            width="100%"
            headline={lang.tipsTricks}
            text={data.text}
        />
    );
};

export default ConnectedTips;
