import PropTypes from 'prop-types';
import React from 'react';

import Card from 'shared-ui/components/Elements/Card';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';

import {lang} from 'lang/global.lang';

import {StyledBadge} from './styles';

const CompletedChapterValues = ({selectedValues}) => {
    return (
        <Flex marginLeft="auto" marginRight="auto" marginBottom="20px">
            <Card theme="secondary" width="100%">
                <Paragraph theme="red">{lang.completedChapter}</Paragraph>
                <Flex>
                    {selectedValues.map((item, index) => (
                        <StyledBadge key={index} text={item.label} />
                    ))}
                </Flex>
            </Card>
        </Flex>
    );
};

CompletedChapterValues.propTypes = {
    selectedValues: PropTypes.array.isRequired,
};

export default CompletedChapterValues;
