import {useMemo} from 'react';

export const useAward = ({currentWeekData, habits}) => {
    const awardResult = useMemo(() => {
        const minimumAmountLength = currentWeekData.minimumAmount.length;
        const expectedAmountLength = currentWeekData.expectedAmount.length;
        const maximumAmountLength = currentWeekData.maximumAmount.length;
        const habitsLength = habits.length;

        if (habitsLength >= maximumAmountLength) {
            return 'gold';
        }
        if (habitsLength === expectedAmountLength) {
            return 'silber';
        }
        if (habitsLength <= minimumAmountLength) {
            return 'bronze';
        }
    }, []);

    return {awardResult};
};
