export const weekDays = [
    {label: 'M', value: 'monday'},
    {label: 'D', value: 'tuesday'},
    {label: 'M', value: 'wednesday'},
    {label: 'D', value: 'thursday'},
    {label: 'F', value: 'friday'},
    {label: 'S', value: 'saturday'},
    {label: 'S', value: 'sunday'},
];

export const weekDaysFull = {
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
};
