import React from 'react';

const White = () => {
    return (
        <svg id="w-a" viewBox="0 0 38 57.73">
            <defs>
                <linearGradient
                    id="w-d"
                    x1="2.79"
                    y1="177.36"
                    x2="6.64"
                    y2="177.36"
                    gradientTransform="translate(0 207.39) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#eb6742" stopOpacity=".2" />
                    <stop offset=".1" stopColor="#eb653f" stopOpacity=".24" />
                    <stop offset=".28" stopColor="#ec6136" stopOpacity=".34" />
                    <stop offset=".49" stopColor="#ed5929" stopOpacity=".51" />
                    <stop offset=".75" stopColor="#ee4e16" stopOpacity=".74" />
                    <stop offset="1" stopColor="#ef4200" />
                </linearGradient>
                <linearGradient
                    id="w-f"
                    x1="1.47"
                    y1="184.89"
                    x2="6.84"
                    y2="184.89"
                    gradientTransform="translate(0 207.39) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#eb6742" stopOpacity=".2" />
                    <stop offset=".07" stopColor="#ef8668" stopOpacity=".36" />
                    <stop offset=".16" stopColor="#f3a28b" stopOpacity=".51" />
                    <stop offset=".24" stopColor="#f6bbaa" stopOpacity=".64" />
                    <stop offset=".33" stopColor="#f9d0c5" stopOpacity=".75" />
                    <stop offset=".43" stopColor="#fbe1da" stopOpacity=".84" />
                    <stop offset=".53" stopColor="#fdeeea" stopOpacity=".91" />
                    <stop offset=".65" stopColor="#fef8f6" stopOpacity=".96" />
                    <stop offset=".79" stopColor="#fffdfd" stopOpacity=".99" />
                    <stop offset="1" stopColor="#fff" />
                </linearGradient>
            </defs>
            <g id="w-b">
                <g id="w-c">
                    <rect
                        x="3.58"
                        y="2.31"
                        width="3.85"
                        height="55.44"
                        fill="#fff"
                    />
                    <rect
                        x="2.79"
                        y="2.31"
                        width="3.85"
                        height="55.44"
                        fill="#eb6742"
                    />
                    <rect
                        x="2.79"
                        y="2.31"
                        width="3.85"
                        height="55.44"
                        fill="url(#w-d)"
                    />
                    <rect
                        x="2.79"
                        y="35.6"
                        width="3.84"
                        height=".78"
                        fill="#ce4c2e"
                    />
                    <circle cx="4.71" cy="4.02" r="3.42" fill="#ce4c2e" />
                    <circle cx="4.71" cy="3.6" r="3.42" fill="#eb6742" />
                </g>
                <g id="w-e">
                    <path
                        d="M36.63,34.44H1.47c-.4,.02-.74-.27-.77-.67V11.27c.03-.4,.37-.69,.77-.67H36.47c.69,0,1,.72,.55,1.15l-10.29,9.22c-.25,.24-.26,.64-.02,.89,0,0,.01,.01,.02,.02l10.5,11.49c.22,.3,.16,.72-.13,.94-.13,.1-.3,.15-.47,.13Z"
                        fill="#fff"
                        stroke="#eb6742"
                        stroke-miterlimit="10"
                        stroke-width="1.49"
                    />
                    <rect
                        x="1.47"
                        y="11.39"
                        width="5.38"
                        height="22.23"
                        fill="url(#w-f)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default White;
