import DesktopBackground from 'assets/images/img-bg-kurs-desktop.jpg';
import MobileBackground from 'assets/images/img-bg-kurs-mobile.jpg';
import styled from 'styled-components';

import Icon from 'shared-ui/components/Elements/Icon';
import BackButton from 'shared-ui/components/Layout/BackButton';
import Flex from 'shared-ui/components/Layout/Flex';
import {cardRadius, color, queries, shadow} from 'shared-ui/styles/variables';

export const BackgroundImage = styled.div`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 240px;
    background: url(${MobileBackground}) no-repeat;
    background-size: cover;
    background-position: center;

    @media ${queries.md} {
        background: url(${DesktopBackground}) no-repeat;
        background-size: cover;
        background-position: top;
        height: 100%;
        border-radius: ${cardRadius};
    }
`;

export const PageWrapper = styled.div`
    padding: 16px;
    max-width: 100%;
    background: ${color.white};
    border-radius: 40px;
    margin: 0 auto;
    overflow: hidden;

    @media ${queries.md} {
        max-width: 80%;
    }
`;

export const StyledFlex = styled(Flex)`
    padding: 0 16px;
    position: relative;
`;

export const Header = styled(Flex)`
    position: relative;
    width: 100%;
    height: 192px;
    padding: 32px;
`;

export const IntroImage = styled.div`
    position: relative;
    height: 240px;
    width: 100%;
    margin-bottom: ${({marginBottom}) => marginBottom};
    border-radius: 30px;
    background: ${(props) => `url(${props.src})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const IntroIcon = styled(Icon)`
    position: absolute;
    left: 50%;
    bottom: -40px;
    transform: translateX(-50%);
    box-shadow: ${shadow};
`;

export const ButtonContainer = styled(Flex)`
    position: absolute;
    width: 100%;
    top: 16px;
    padding: 0 16px;

    @media ${queries.md} {
        padding: 0 10%;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const StyledButton = styled(Icon)`
    cursor: pointer;
    margin-left: 16px;
    box-shadow: ${shadow};
    width: 48px;
    height: 48px;
    padding: 10px;

    @media ${queries.md} {
        width: 64px;
        height: 64px;
        padding: 18px;
    }
`;

export const StyledBackButton = styled(BackButton)`
    position: absolute;
    width: 48px;
    height: 48px;
    left: 16px;
    top: 0;

    @media ${queries.md} {
        width: 64px;
        height: 64px;
        padding: 16px;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
    }
`;
