import styled, {css} from 'styled-components';

import Flag from 'mindset-mentor/src/components/Elements/Flag';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import {color, queries, shadow} from 'shared-ui/styles/variables';

export const StyledDiv = styled(Div)`
    border-radius: 50px;
    border: 2px dashed ${color.grey};
    padding: 16px 32px 32px 32px;
    width: 100%;
    margin: 16px 0;
    max-width: 450px;

    @media ${queries.xs} {
        padding: 16px 80px 32px 80px;
    }

    @media ${queries.md} {
        box-shadow: ${shadow};
        border: none;
    }
`;

export const Container = styled(Div)`
    margin: 48px auto 32px auto;
    min-width: 200px;
`;

export const StyledParagraph = styled(Paragraph)`
    position: absolute;

    ${({top, right}) =>
        top &&
        right &&
        css`
            top: ${top};
            right: ${right};
        `};
`;

export const StyledFlag = styled(Flag)`
    position: absolute;
    width: 8%;
    top: 36%;
    left: 29%;

    ${({index}) =>
        index === 1 &&
        css`
            top: 12.5%;
            left: 70%;
        `};

    ${({index}) =>
        index === 2 &&
        css`
            top: -12%;
            left: 54%;
        `};
`;
