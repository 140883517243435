import {useQueryClient} from 'react-query';

export const useSetQueryData = () => {
    const queryClient = useQueryClient();

    const setData = async (key, values) => {
        await queryClient.cancelQueries(key);
        queryClient.setQueryData(key, () => values);
    };
    const getData = async (key) => {
        return queryClient.getQueryData(key);
    };

    return {queryClient, setData, getData};
};
