import {useState} from 'react';

export const setValues = (selectedValues, value) => {
    return selectedValues.indexOf(value) > -1
        ? selectedValues.filter((selectedValue) => selectedValue !== value)
        : selectedValues.concat(value);
};

export const useCheckBoxValues = (initialSelectedValues) => {
    const [selectedValues, setSelectedValues] = useState(
        initialSelectedValues || []
    );
    const handleOnChange = (value) => {
        const values = setValues(selectedValues, value);
        setSelectedValues(values);
    };

    return {selectedValues, handleOnChange, setSelectedValues};
};
