import DesktopBackground from 'assets/images/img-bg-onboarding-desktop.jpg';
import MobileBackground from 'assets/images/img-bg-onboarding-mobile.jpg';
import styled from 'styled-components';

import Button from 'shared-ui/components/Elements/Button';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {cardRadius, queries} from 'shared-ui/styles/variables';

export const StyledOnboarding = styled(Div)`
    position: relative;
    max-width: 1400px;
    height: 90vh;
    min-height: 800px;
    width: 100%;
    padding: 32px;

    @media ${queries.md} {
        height: 800px;
        border-radius: ${cardRadius};
        overflow: hidden;
    }
`;

export const BackgroundImage = styled.div`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url(${MobileBackground}) no-repeat;
    background-size: cover;
    background-position: center;

    @media ${queries.md} {
        background: url(${DesktopBackground}) no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
    }
`;

export const Content = styled(Flex)`
    margin: 0 auto;

    @media ${queries.md} {
        max-width: 75%;
    }
`;

export const StyledButton = styled(Button)`
    width: 240px;
    margin-top: 16px;
`;
