import React from 'react';
import PropTypes from 'prop-types';
import CalendarComponent from 'react-calendar';

import {CalendarStyled} from './styles';

const Calendar = ({minDate, maxDate, handleOnChange, calendarDate}) => {
    return (
        <CalendarStyled>
            <CalendarComponent
                maxDate={new Date(maxDate)}
                minDate={new Date(minDate)}
                onChange={(currentDate) => handleOnChange(currentDate)}
                value={calendarDate}
            />
        </CalendarStyled>
    );
};

Calendar.propTypes = {
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    handleOnChange: PropTypes.func,
    calendarDate: PropTypes.any,
};

Calendar.defaultProps = {
    minDate: null,
    maxDate: null,
    handleOnChange: () => {},
    calendarDate: null,
};

export default Calendar;
