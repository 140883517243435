import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayerLoader from '@brightcove/react-player-loader';

import {VideoStyled} from './styles';

const Video = ({videoId, className}) => {
    return (
        <VideoStyled className={className}>
            <ReactPlayerLoader
                accountId="5662516403001"
                videoId={videoId}
                playerId="0hVpNUYdY"
                embedId="default"
                className="vjs-16-9"
                embedOptions={{
                    responsive: true,
                }}
            />
        </VideoStyled>
    );
};

Video.propTypes = {
    className: PropTypes.string,
    videoId: PropTypes.string.isRequired,
};

Video.defaultProps = {
    className: 'ap-video',
};

/** @component */
export default Video;
