export const normalizeCurrentUser = async (data) => {
    if (!data) return null;
    const normalizedMap = {
        id: data.id,
        userName: data.username,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
    };
    return normalizedMap;
};
