import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Icon from 'shared-ui/components/Elements/Icon';

import {shadow, color, transition} from 'shared-ui/styles/variables';

export const StyledCard = styled(Card)`
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 16px;
`;

export const CheckMark = styled.div`
    border-radius: 10px;
    box-shadow: ${shadow};
    background-color: ${color.alertGreen};
    height: 40px;
    width: 40px;
    position: absolute;
    right: 16px;

    &::after {
        content: '';
        position: absolute;
        left: 16px;
        top: 9px;
        width: 8px;
        height: 16px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg) scale(1.5);
        -ms-transform: rotate(45deg) scale(1.5);
        transform: rotate(45deg) scale(1.5);
    }
`;

export const StyledIcon = styled(Icon)`
    margin-right: 16px;
`;

export const CardContent = styled.div`
    width: 100%;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    transition: ${transition};
`;
