import PropTypes from 'prop-types';
import React from 'react';
import {navigate} from 'shared-utils';

import header from 'mindset-mentor/src/assets/images/header/img-header-zielplanung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Image from 'shared-ui/components/Elements/Image';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import {cardRadius, queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {page, pageFields, pageRoute} from 'routes/pages';

import {coursesSteps} from 'data/courses-list.data';

import {usePostCompletedSteps} from 'services/data-hooks/course/usePostCompletedSteps';

import {useCurrentIdentifier} from 'hooks/useCurrentIdentifier';

const GoalPlanning = ({identifier, step, handleCompletedStepsMutate}) => {
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    const currentCourse = coursesSteps[step];
    if (!currentCourse) return null;

    return (
        <PageLayout
            pageName="Lernpart Zielplanung"
            pageIcon="book"
            subLine="Deine persönliche Zielplanung"
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {currentCourse.mainTitle[identifier]}
                </Headline>
                <Paragraph margin="0 0 48px 0" textAlign={textAlign}>
                    {currentCourse.mainParagraph[identifier]}
                </Paragraph>

                {!isMd && (
                    <Flex
                        justifyContent="center"
                        marginBottom="48px"
                        width="100%"
                    >
                        <Card width="100%">
                            <Image
                                src={currentCourse.cardImage[identifier]}
                                alt="Zielplanung"
                                radius={cardRadius}
                            />
                        </Card>
                    </Flex>
                )}

                <Flex justifyContent="center" width="100%">
                    <Button
                        label="Weiter zu den Hürden"
                        maxWidth="320px"
                        margin="0 auto 32px auto"
                        onClick={handleCompletedStepsMutate}
                    />
                </Flex>
            </Flex>
        </PageLayout>
    );
};

GoalPlanning.propTypes = {
    identifier: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    handleCompletedStepsMutate: PropTypes.func.isRequired,
};

export const ConnectedGoalPlanning = ({step}) => {
    const {mutate: completedStepsMutate} = usePostCompletedSteps();
    const {identifier} = useCurrentIdentifier();
    const completedStep = pageFields.chapter3.goalPlanning;

    if (!identifier) return null;

    const handleCompletedStepsMutate = () => {
        completedStepsMutate(completedStep, {
            onSuccess: () => {
                navigate(pageRoute.courseWeekItem(page.ifPlanning));
            },
        });
    };

    return (
        <GoalPlanning
            identifier={identifier}
            step={step}
            handleCompletedStepsMutate={handleCompletedStepsMutate}
        />
    );
};

ConnectedGoalPlanning.propTypes = {
    step: PropTypes.string.isRequired,
};

export default GoalPlanning;
