import React from 'react';

import FitnessGoalPlanningImage from 'mindset-mentor/src/assets/images/goalplanning-image.png';

export const step3_1 = {
    mainTitle: {
        fitness:
            'Wie planst du dein Ziel, um eine möglichst hohe Erfolgschance zu haben?',
        nutrition:
            'Wie planst du dein Ziel, um eine möglichst hohe Erfolgschance zu haben?',
        relax: 'Wie planst du dein Ziel, um eine möglichst hohe Erfolgschance zu haben?',
        energy: 'Wie planst du dein Ziel, um eine möglichst hohe Erfolgschance zu haben?',
        sleep: 'Wie planst du dein Ziel, um eine möglichst hohe Erfolgschance zu haben?',
        balance:
            'Wie planst du dein Ziel, um eine möglichst hohe Erfolgschance zu haben?',
    },
    mainParagraph: {
        fitness: (
            <>
                Wusstest du, dass deine Erfolgschancen ein bestimmtes Ziel zu
                erreichen steigen, wenn du dir im Vorfeld überlegst, was dich
                davon abhalten könnte? Das hört sich vermutlich erst einmal
                komisch an, ist aber sehr effektiv.
                <br />
                <b>
                    Wenn du bereits einschätzen kannst, welche Hindernisse auf
                    dich zukommen könnten, kannst du dir auch schon Lösungen
                    dafür zurechtlegen.
                </b>
                <br />
                <br />
                Genau das wirst du in den nächsten Schritten tun. Falls du dir
                noch nichts Konkretes darunter vorstellen kannst – keine Sorge.
                Du erhältst in jedem Schritt Unterstützung und passende Tipps.
                Also lass dich nicht aufhalten und erhöhe deine Erfolgschancen!
            </>
        ),
        nutrition: (
            <>
                Wusstest du, dass deine Erfolgschancen ein bestimmtes Ziel zu
                erreichen steigen, wenn du dir im Vorfeld überlegst, was dich
                davon abhalten könnte? Das hört sich vermutlich erst einmal
                komisch an, ist aber sehr effektiv.
                <br />
                <b>
                    Wenn du bereits einschätzen kannst, welche Hindernisse auf
                    dich zukommen könnten, kannst du dir auch schon Lösungen
                    dafür zurechtlegen.
                </b>
                <br />
                <br />
                Genau das wirst du in den nächsten Schritten tun. Falls du dir
                noch nichts Konkretes darunter vorstellen kannst – keine Sorge.
                Du erhältst in jedem Schritt Unterstützung und passende Tipps.
                Also lass dich nicht aufhalten und erhöhe deine Erfolgschancen!
            </>
        ),
        relax: (
            <>
                Wusstest du, dass deine Erfolgschancen ein bestimmtes Ziel zu
                erreichen steigen, wenn du dir im Vorfeld überlegst, was dich
                davon abhalten könnte? Das hört sich vermutlich erst einmal
                komisch an, ist aber sehr effektiv.
                <br />
                <b>
                    Wenn du bereits einschätzen kannst, welche Hindernisse auf
                    dich zukommen könnten, kannst du dir auch schon Lösungen
                    dafür zurechtlegen.
                </b>
                <br />
                <br />
                Genau das wirst du in den nächsten Schritten tun. Falls du dir
                noch nichts Konkretes darunter vorstellen kannst – keine Sorge.
                Du erhältst in jedem Schritt Unterstützung und passende Tipps.
                Also lass dich nicht aufhalten und erhöhe deine Erfolgschancen!
            </>
        ),
        energy: (
            <>
                Wusstest du, dass deine Erfolgschancen ein bestimmtes Ziel zu
                erreichen steigen, wenn du dir im Vorfeld überlegst, was dich
                davon abhalten könnte? Das hört sich vermutlich erst einmal
                komisch an, ist aber sehr effektiv.
                <br />
                <b>
                    Wenn du bereits einschätzen kannst, welche Hindernisse auf
                    dich zukommen könnten, kannst du dir auch schon Lösungen
                    dafür zurechtlegen.
                </b>
                <br />
                <br />
                Genau das wirst du in den nächsten Schritten tun. Falls du dir
                noch nichts Konkretes darunter vorstellen kannst – keine Sorge.
                Du erhältst in jedem Schritt Unterstützung und passende Tipps.
                Also lass dich nicht aufhalten und erhöhe deine Erfolgschancen!
            </>
        ),
        sleep: (
            <>
                Wusstest du, dass deine Erfolgschancen ein bestimmtes Ziel zu
                erreichen steigen, wenn du dir im Vorfeld überlegst, was dich
                davon abhalten könnte? Das hört sich vermutlich erst einmal
                komisch an, ist aber sehr effektiv.
                <br />
                <b>
                    Wenn du bereits einschätzen kannst, welche Hindernisse auf
                    dich zukommen könnten, kannst du dir auch schon Lösungen
                    dafür zurechtlegen.
                </b>
                <br />
                <br />
                Genau das wirst du in den nächsten Schritten tun. Falls du dir
                noch nichts Konkretes darunter vorstellen kannst – keine Sorge.
                Du erhältst in jedem Schritt Unterstützung und passende Tipps.
                Also lass dich nicht aufhalten und erhöhe deine Erfolgschancen!
            </>
        ),
        balance: (
            <>
                Wusstest du, dass deine Erfolgschancen ein bestimmtes Ziel zu
                erreichen steigen, wenn du dir im Vorfeld überlegst, was dich
                davon abhalten könnte? Das hört sich vermutlich erst einmal
                komisch an, ist aber sehr effektiv.
                <br />
                <b>
                    Wenn du bereits einschätzen kannst, welche Hindernisse auf
                    dich zukommen könnten, kannst du dir auch schon Lösungen
                    dafür zurechtlegen.
                </b>
                <br />
                <br />
                Genau das wirst du in den nächsten Schritten tun. Falls du dir
                noch nichts Konkretes darunter vorstellen kannst – keine Sorge.
                Du erhältst in jedem Schritt Unterstützung und passende Tipps.
                Also lass dich nicht aufhalten und erhöhe deine Erfolgschancen!
            </>
        ),
    },
    cardImage: {
        nutrition: FitnessGoalPlanningImage,
        relax: FitnessGoalPlanningImage,
        energy: FitnessGoalPlanningImage,
        sleep: FitnessGoalPlanningImage,
        balance: FitnessGoalPlanningImage,
        fitness: FitnessGoalPlanningImage,
    },
};
