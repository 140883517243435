import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {StyledCard, StyledDiv, Arrow} from './styles';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';

const Accordion = ({items, selectedValue, setSelectedValue}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <StyledCard
            isHoverBg
            onClick={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
        >
            <Paragraph>{items[selectedValue].label}</Paragraph>
            <Arrow isOpen={isOpen} />

            <StyledDiv isOpen={isOpen}>
                {items.map((item, index) => {
                    return (
                        index !== selectedValue && (
                            <Div
                                marginTop="8px"
                                key={item.label + index}
                                onClick={() => setSelectedValue(index)}
                            >
                                <Paragraph>{item.label}</Paragraph>
                            </Div>
                        )
                    );
                })}
            </StyledDiv>
        </StyledCard>
    );
};

Accordion.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape(PropTypes.string, PropTypes.string)
    ).isRequired,
    selectedValue: PropTypes.number,
    setSelectedValue: PropTypes.func,
};

Accordion.defaultProps = {
    selectedValue: 0,
    setSelectedValue: () => {},
};

export default Accordion;
