import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {navigate, useParams} from 'shared-utils';

import header from 'mindset-mentor/src/assets/images/header/img-header-kurs-woche-1.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Card from 'shared-ui/components/Elements/Card';
import CheckBox from 'shared-ui/components/Elements/CheckBox';
import Headline from 'shared-ui/components/Elements/Headline';
import Icon from 'shared-ui/components/Elements/Icon';
import Lightbox from 'shared-ui/components/Elements/Lightbox';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import List from 'components/Elements/List';
import Video from 'components/Elements/Video';
import {ConnectedNotificationSettings} from 'components/Layout/NotificationSettings';

import {pageRoute} from 'routes/pages';

import {lang} from 'lang/global.lang';

import {courses} from 'data/courses-list.data';

import {useUpdateCurrentCourse} from 'services/data-hooks/course/useUpdateCurrentCourse';
import {useGetCurrentUser} from 'services/data-hooks/user/useGetCurrentUser';

const CourseDashboardTemplate = ({
    identifier,
    userName,
    handleMutateCourse,
    handleBackButton,
}) => {
    const isMd = useMediaQuery(queries.md);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [isCheckbox, setIsCheckbox] = useState(false);
    const [isShowError, setIsShowError] = useState(false);
    const selectedCourse = courses.find((c) => c.identifier === identifier);
    if (!selectedCourse) return null;
    const {name, videoId, dashboardData} = selectedCourse;

    const handleCourseSelect = () =>
        isCheckbox ? handleMutateCourse(isCheckbox) : setIsShowError(true);

    const handleCheckboxOnClick = () => {
        setIsLightboxOpen((prev) => !prev);
    };

    return (
        <>
            <PageLayout
                pageName="Dashboard"
                pageImage={isMd ? header : null}
                pageIcon={isMd ? 'hello' : null}
            >
                <Div
                    width={isMd ? '70%' : ''}
                    marginLeft="auto"
                    marginRight="auto"
                >
                    <Flex alignItems="center" flexDirection="column">
                        {!isMd && <Icon color="red" name="hello" size="64px" />}
                        <Headline bold type="h3">
                            Hallo {userName}!
                        </Headline>
                    </Flex>

                    <Paragraph bold theme="red" margin="10px 0">
                        Kurs: {name}
                    </Paragraph>
                    {dashboardData.dashboardContent}

                    <Flex
                        alignItems="center"
                        marginTop="30px"
                        marginBottom="30px"
                        flexDirection="column"
                    >
                        <Div marginTop="15px">
                            <CheckBox
                                isLabelSuffix
                                theme="red"
                                label="Datenschutzbestimmung"
                                onClick={handleCheckboxOnClick}
                                checked={isCheckbox}
                                onChange={(e) =>
                                    setIsCheckbox(e.target.checked)
                                }
                            />
                        </Div>
                        <Div height="40px">
                            <Paragraph theme="red" textAlign="center">
                                {isShowError && lang.privacypolicySelectedError}
                            </Paragraph>
                        </Div>
                        <Button
                            label={lang.courseStart}
                            maxWidth="320px"
                            margin="0 auto"
                            onClick={handleCourseSelect}
                        />
                    </Flex>
                    <Flex
                        justifyContent="center"
                        marginTop="30px"
                        marginBottom="30px"
                    >
                        <Button
                            theme="outline"
                            onClick={handleBackButton}
                            label={lang.backToCourseSelection}
                            maxWidth="320px"
                            margin="0 auto"
                        />
                    </Flex>
                    <Card>
                        <Video videoId={videoId} />
                        <Headline type="h4">
                            {lang.courseTutorialTitle(1, name)}
                        </Headline>
                    </Card>
                    <Div marginTop="30px">
                        <Card
                            theme="secondary"
                            iconName="sitemap"
                            title="Was als nächstes zu tun ist:"
                        >
                            <List items={dashboardData.dashboardTodos} />
                        </Card>
                    </Div>
                    <ConnectedNotificationSettings />
                </Div>
            </PageLayout>
            <Lightbox
                isOpen={isLightboxOpen}
                onClose={handleCheckboxOnClick}
                isOrangeBg
            >
                <Paragraph theme="white">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industrys
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                </Paragraph>
            </Lightbox>
        </>
    );
};

CourseDashboardTemplate.propTypes = {
    identifier: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    handleMutateCourse: PropTypes.func.isRequired,
    handleBackButton: PropTypes.func.isRequired,
};

export const ConnectedCourseDashboardTemplate = () => {
    const {identifier} = useParams();
    const {data: currentUserData} = useGetCurrentUser();
    const {mutate} = useUpdateCurrentCourse();

    const handleBackButton = () => {
        navigate(pageRoute.mainPath);
    };

    const handleMutateCourse = (termOfUse) => {
        const values = {
            terms_of_use: termOfUse,
            course: identifier,
        };
        mutate(values, {
            onSuccess: () => {
                navigate(pageRoute.courseWeek);
            },
        });
    };
    if (!currentUserData || !identifier) {
        return null;
    }

    return (
        <CourseDashboardTemplate
            identifier={identifier}
            userName={currentUserData.userName}
            handleMutateCourse={handleMutateCourse}
            handleBackButton={handleBackButton}
        />
    );
};

export default CourseDashboardTemplate;
