import PropTypes from 'prop-types';
import React, { useState } from 'react';



import header from 'mindset-mentor/src/assets/images/header/img-header-visualisierung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';



import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Infobox from 'shared-ui/components/Elements/Infobox';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import { queries } from 'shared-ui/styles/variables';



import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';



import ActionCard from 'components/Layout/ActionCard';
import AlertBox from 'components/Layout/AlertBox';
import CompletedChapterValues from 'components/Layout/CompletedChapterValues';
import ConnectedTips from 'components/Layout/Tips';

import {page, pageRoute, protectedRoutes} from 'routes/pages';

import {lang} from 'lang/global.lang';

import {coursesSteps} from 'data/courses-list.data';

import {useGetCoursesSuggestions} from 'services/data-hooks/course/useGetCoursesSuggestions';
import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';
import {usePatchCurrentWeek} from 'services/data-hooks/week/usePatchCurrentWeek';

import {useActiveChapter} from 'hooks/useActiveChapter';
import useCreatableInput from 'hooks/useCreatableInput';
import {useCurrentIdentifier} from 'hooks/useCurrentIdentifier';

const IndividualActionTemplate = ({
    identifier,
    step,
    currentWeekData,
    coursesSuggestionsData,
    handleCurrentWeekMutate,
    isChapterDisabled,
}) => {
    const [isOpenLightbox, setIsOpenLightbox] = useState(false);

    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    const {habit: weekHabit} = currentWeekData;
    const {habit: suggestionsHabit} = coursesSuggestionsData;

    const {
        handleOnChange,
        onDragEnd,
        selectedValues,
        inputValue,
        handleOnInputChange,
        handleKeyDown,
        initialOptions,
        handleSetSelectedValues,
        isDisabled,
    } = useCreatableInput(suggestionsHabit, 1, weekHabit);

    const currentCourse = coursesSteps[step];
    if (!currentCourse) return null;

    const handleClickGoToHabitCheck = () => {
        if (selectedValues.length > 0) {
            const mapped = selectedValues.map((value) => value.label).shift();
            const data = {
                habit: mapped,
            };
            handleCurrentWeekMutate(data);
        } else {
            setIsOpenLightbox(true);
        }
    };

    return (
        <PageLayout
            pageName="Individuelle Handlung"
            pageIcon="pen"
            subLine="Dein persönlicher Habit"
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {currentCourse.mainTitle[identifier]}
                </Headline>
                <Paragraph textAlign={textAlign}>
                    {currentCourse.mainParagraph[identifier]}
                </Paragraph>
                <Div marginTop="48px" marginBottom="48px">
                    <ConnectedTips />
                </Div>

                <Paragraph margin="0 0 16px 0" textAlign={textAlign}>
                    {currentCourse.mainDescription[identifier]}
                </Paragraph>
                <Infobox text={currentCourse.infoBoxText[identifier]} />
                <Paragraph margin="0 0 32px 0" textAlign={textAlign}>
                    {currentCourse.habitText[identifier]}
                </Paragraph>
            </Flex>
            {isChapterDisabled ? (
                <CompletedChapterValues selectedValues={selectedValues} />
            ) : (
                <>
                    {coursesSuggestionsData ? (
                        <ActionCard
                            headline="Klicken Sie auf den Stift, um Ihren individuellen Habit aufzuschreiben oder wählen Sie diese aus."
                            handleOnChange={handleOnChange}
                            onDragEnd={onDragEnd}
                            selectedValues={selectedValues}
                            inputValue={inputValue}
                            handleOnInputChange={handleOnInputChange}
                            handleKeyDown={handleKeyDown}
                            initialOptions={initialOptions}
                            handleSetSelectedValues={handleSetSelectedValues}
                            isDisabled={isDisabled}
                        />
                    ) : null}
                </>
            )}

            <Flex
                flexDirection="column"
                alignItems="center"
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Button
                    label={lang.saveAndGo('zum Habit-Check')}
                    maxWidth="320px"
                    onClick={handleClickGoToHabitCheck}
                />
                <Div marginTop="48px" marginBottom="48px">
                    <ConnectedTips />
                </Div>
            </Flex>

            <AlertBox
                setIsOpenLightbox={setIsOpenLightbox}
                isOpenLightbox={isOpenLightbox}
                text="Bitte wähle zuerst deinen Habit aus!"
                buttonHandler={() => setIsOpenLightbox(false)}
            />
        </PageLayout>
    );
};

IndividualActionTemplate.propTypes = {
    identifier: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    currentWeekData: PropTypes.object.isRequired,
    coursesSuggestionsData: PropTypes.object.isRequired,
    handleCurrentWeekMutate: PropTypes.func.isRequired,
    isChapterDisabled: PropTypes.bool.isRequired,
};

export const ConnectedIndividualActionTemplate = ({step}) => {
    const {mutate: currentWeekMutate} = usePatchCurrentWeek();
    const {data: currentWeekData} = useGetCurrentWeek();
    const {identifier} = useCurrentIdentifier();
    const {data: coursesSuggestionsData} = useGetCoursesSuggestions();
    const {isChapterDisabled} = useActiveChapter(protectedRoutes.part2Final);

    if (!currentWeekData || !coursesSuggestionsData) {
        return null;
    }

    const handleCurrentWeekMutate = (data) => {
        currentWeekMutate({
            data,
            step,
            route: pageRoute.courseWeekItem(page.habitCheck1),
            isChapterDisabled,
        });
    };

    return (
        <IndividualActionTemplate
            identifier={identifier}
            step={step}
            handleCurrentWeekMutate={handleCurrentWeekMutate}
            currentWeekData={currentWeekData}
            coursesSuggestionsData={coursesSuggestionsData}
            isChapterDisabled={isChapterDisabled}
        />
    );
};

ConnectedIndividualActionTemplate.propTypes = {
    step: PropTypes.string.isRequired,
};

export default IndividualActionTemplate;