import React from 'react';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

export const dashboardData = {
    dashboardContent: (
        <>
            <Paragraph>
                Schön, dass du da bist und den ersten Schritt zu einem
                gesünderen Lebensstil mit mehr Bewegung machst! Du befindest
                dich gerade auf deiner persönlichen Pinnwand. Hier findest du
                verschiedene Optionen, die dein persönlicher MINDSETmentor für
                dich bereithält.
            </Paragraph>
            <Paragraph>
                Wenn du noch nicht genau weißt, wie du dich hier zurechtfinden
                sollst, schaue dir das kurze Tutorial zur Nutzung der Pinnwand
                an. Falls du direkt mit deinem Kurs starten möchtest, dann klick
                einfach auf den Button: „Jetzt Kurs starten!“
            </Paragraph>
        </>
    ),
    dashboardTodos: [
        'At vero eos et accusamus et iusto odio dignissimos ducimus qui',
        'Aet iusto odio dignissimos ducimus qui blanditiis ',
    ],
};
