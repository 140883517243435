import { useQuery } from 'react-query';



import { currentCourse } from 'services/config/endpoints';
import { makeRequest } from 'services/config/mindset-mentor-api';
import { CURRENT_COURSES } from 'services/config/query-keys';
import {normalizeCurrentCourse} from 'services/normalize/current-course';

export const getCurrentCourse = async () => {
    const response = await makeRequest(currentCourse, 'GET');
    const normalized = await normalizeCurrentCourse(response);
    return normalized;
};

export function useGetCurrentCourse() {
    return useQuery([CURRENT_COURSES], getCurrentCourse,{
        staleTime: Infinity,
    });
}