import PropTypes from 'prop-types';
import React, {useCallback} from 'react';

import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import {font} from 'shared-ui/styles/variables';

import {
    setValues,
    useCheckBoxValues,
} from 'shared-utils/utils/hooks/useCheckBoxValues';

import SelectionBoxGroup from 'components/Elements/SelectionBoxGroup';

import {useGetHabit} from 'services/data-hooks/habit/useGetHabit';
import {usePutHabit} from 'services/data-hooks/habit/usePutHabit';
import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';

import {useTrackerValues} from '../../../hooks/useTrackerValues';

const HabitTracker = ({startDate, initialValues, mutateHabit}) => {
    const {selectedValues, handleOnChange} = useCheckBoxValues(initialValues);
    const {initialWeekDays, today, weekEndDay} = useTrackerValues({
        startDate: startDate,
    });
    const handleMutate = useCallback(
        (value) => {
            const values = setValues(selectedValues, value);
            mutateHabit(values);
        },
        [selectedValues]
    );

    const onChange = (value) => {
        handleOnChange(value);
        handleMutate(value);
    };

    return (
        <Card>
            <Headline type="h4" marginTop="8px" marginBottom="8px">
                Habit Tracker
            </Headline>
            <Paragraph>
                <span style={{fontWeight: 'bold', color: font.color.darkBlue}}>
                    Woche:
                </span>{' '}
                {today} - {weekEndDay}
            </Paragraph>
            {initialWeekDays ? (
                <Div marginBottom="16px" marginTop="16px">
                    <SelectionBoxGroup
                        theme="secondary"
                        items={initialWeekDays}
                        selectedValues={selectedValues}
                        onChange={(value) => onChange(value)}
                    />
                </Div>
            ) : null}
        </Card>
    );
};

export default HabitTracker;

HabitTracker.propTypes = {
    initialValues: PropTypes.array,
    mutateHabit: PropTypes.func.isRequired,
    startDate: PropTypes.string.isRequired,
};

HabitTracker.defaultProps = {
    items: null,
    initialValues: null,
};

export const ConnectedHabitTracker = () => {
    const {data: habits} = useGetHabit();
    const {data: currentWeekData} = useGetCurrentWeek();
    const {mutate: mutateHabit} = usePutHabit();
    if (!habits || !currentWeekData) {
        return null;
    }

    return (
        <HabitTracker
            startDate={currentWeekData?.startDate}
            initialValues={habits}
            mutateHabit={mutateHabit}
        />
    );
};
