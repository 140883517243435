import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from 'shared-utils';



import header from 'mindset-mentor/src/assets/images/header/img-header-zielsetzung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';



import Button from 'shared-ui/components/Elements/Button';
import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Image from 'shared-ui/components/Elements/Image';
import Infobox from 'shared-ui/components/Elements/Infobox';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import { queries } from 'shared-ui/styles/variables';



import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';



import { page, pageFields, pageRoute } from 'routes/pages';



import { coursesSteps } from 'data/courses-list.data';



import { usePostCompletedSteps } from 'services/data-hooks/course/usePostCompletedSteps';



import { useCurrentIdentifier } from 'hooks/useCurrentIdentifier';


const SettingGoal = ({identifier, step, handleCompletedStepsMutate}) => {
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    const currentCourse = coursesSteps[step];
    if (!currentCourse) return null;

    return (
        <PageLayout
            pageName="Lernpart Zielsetzung"
            pageIcon="calendar"
            subLine="Es kann losgehen!"
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {currentCourse.mainTitle[identifier]}
                </Headline>
                <Paragraph textAlign={textAlign}>
                    {currentCourse.mainDescription[identifier]}
                </Paragraph>
                <Headline type="h4" marginBottom="0" textAlign={textAlign}>
                    {currentCourse.subTitle[identifier]}
                </Headline>
                <Paragraph margin="16px 0" textAlign={textAlign}>
                    {currentCourse.subDescription[identifier]}
                </Paragraph>
                <Infobox text={currentCourse.infoBoxText[identifier]} />
                <Flex justifyContent="center" width="100%">
                    <Button
                        label="Weiter zum Habit"
                        maxWidth="320px"
                        margin="16px auto 0 auto"
                        onClick={handleCompletedStepsMutate}
                    />
                </Flex>
                {!isMd && (
                    <Flex justifyContent="center" marginTop="48px" width="100%">
                        <Card width="100%">
                            <Image
                                src={currentCourse.cardImage[identifier]}
                                alt="Zielsetung"
                            />
                        </Card>
                    </Flex>
                )}
            </Flex>
        </PageLayout>
    );
};

SettingGoal.propTypes = {
    identifier: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    handleCompletedStepsMutate: PropTypes.func.isRequired,
};

export const ConnectedSettingGoal = ({step}) => {
    const {mutate: completedStepsMutate} = usePostCompletedSteps();
    const {identifier} = useCurrentIdentifier();

    const completedStep = pageFields.chapter1.lessonTarget;

    const handleCompletedStepsMutate = () => {
        completedStepsMutate(completedStep, {
            onSuccess: () => {
                navigate(pageRoute.courseWeekItem(page.individualAction));
            },
        });
    };

    if (!identifier) {
        return null;
    }

    return (
        <SettingGoal
            identifier={identifier}
            step={step}
            handleCompletedStepsMutate={handleCompletedStepsMutate}
        />
    );
};

ConnectedSettingGoal.propTypes = {
    step: PropTypes.string.isRequired,
};

export default SettingGoal;