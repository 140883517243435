import PropTypes from 'prop-types';
import React from 'react';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';

import {lang} from 'lang/global.lang';

import {StyledParagraph} from './styles';

const References = ({items}) => {
    return (
        <Div marginTop="32px">
            <Paragraph size="xsmall" bold>
                {lang.references}
            </Paragraph>
            {items.map(({prefix, ref}) => {
                return (
                    <StyledParagraph size="xsmall" key={prefix} prefix={prefix}>
                        {ref}
                    </StyledParagraph>
                );
            })}
        </Div>
    );
};

References.propTypes = {
    items: PropTypes.array,
};

References.defaultProps = {
    items: null,
};

export default References;
