import Paragraph from 'shared-ui/components/Elements/Paragraph';
import styled from 'styled-components';

export const StyledParagraph = styled(Paragraph)`
    position: relative;
    margin-left: 32px;

    &::before {
        content: '${(props) => props.prefix}';
        position: absolute;
        left: -24px;
        top: 4px;
    }
`;
