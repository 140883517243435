import styled, {css} from 'styled-components';

import {font} from 'shared-ui/styles/variables';

export const StyledLink = styled.a`
    color: ${({color}) => color};
    text-decoration: ${({textDecoration}) => textDecoration};

    ${({bold}) =>
        bold
            ? css`
                  font-weight: ${font.weight.bold};
                  font-family: ${font.type.mulishBold};
              `
            : css`
                  font-family: ${font.type.mulishRegular};
                  font-weight: ${font.weight.regular};
              `};
`;
