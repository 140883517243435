import React from 'react';

import Tips from 'shared-ui/components/Elements/Tips';

import {lang} from 'lang/start-page.lang';

const ConnectedTips = () => (
    <Tips
        iconPosition="right"
        width="100%"
        headline={lang.tipsTricks}
        text="Wenn du Unterstützung brauchst, melde dich gerne im MS Service-Center. Immer montags bis freitags von 8 bis 20 Uhr erreichst du die MS Coaches unter der kostenlosen Rufnummer 0800 030 77 30"
    />
);

export default ConnectedTips;
