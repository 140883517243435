import React from 'react';

import Link from 'components/Elements/Link';
import {page} from 'routes/pages';

export const settings = {
    cards: [
        {
            iconName: 'alarm',
            label: 'Reminder',
            link: page.reminderSettings,
        },
        {
            iconName: 'cancel',
            label: 'Kurs abbrechen',
            link: page.cancelCourse,
        },
        {
            iconName: 'serviceCenter',
            label: 'Service Center',
            link: page.serviceCenter,
        },
    ],
};

export const reminder = {
    mainTitle: '7 Nachrichten – als E-Mail & SMS – 1 x täglich',
    mainParagraph:
        'Extra-Motivation gefällig? Wähle eine oder beide Optionen aus:',
    reminderEmail: (
        <>
            <strong>Reminder E-Mail abbonieren</strong>
        </>
    ),
    reminderSms: (
        <>
            <strong>Reminder SMS abbonieren</strong>
        </>
    ),
    dataPrivacy: (
        <Link href="" textDecoration="underline" target="blank" bold>
            Datenschutzbestimmung
        </Link>
    ),
    emailText: 'Ja, ich möchte Motivationspost per E-Mail erhalten um',
    smsText: 'Ja, ich möchte Motivationspost per SMS erhalten an',
    at: 'um',
    clock: 'Uhr',
};
