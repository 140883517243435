import PropTypes from 'prop-types';
import React from 'react';
import header from 'mindset-mentor/src/assets/images/header/img-header-wenn-planung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';
import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import { queries } from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import { reflection } from 'lang/global.lang';
import { usePostCompleteWeekPlan } from 'services/data-hooks/course/usePostCompleteWeekPlan';
import { useGetCurrentWeek } from 'services/data-hooks/week/useGetCurrentWeek';
import List from '../../Elements/List';
import { StyledBadge } from '../CourseBulletBoard/styles';

const Reflection = ({
    icon,
    week,
    title,
    introText,
    mainText,
    listItems,
    outroText,
    handleNextStep,
}) => {
    const isMd = useMediaQuery(queries.md);

    return (
        <PageLayout
            pageName="Reflexion"
            pageIcon={icon}
            pageImage={header}
            noIconMobile="true"
        >
            <Flex
                flexDirection="column"
                width={isMd ? '80%' : '100%'}
                justifyContent="center"
                marginLeft="auto"
                marginRight="auto"
            >
                <Flex
                    flexDirection="row"
                    justifyContent={isMd ? 'center' : 'flex-start'}
                    alignItems="center"
                    width="100%"
                    marginBottom="32px"
                >
                    <Paragraph margin="0 24px 0 0" theme="red" bold>
                        {reflection.weekLabel}
                    </Paragraph>
                    <StyledBadge text={week} />
                </Flex>

                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                >
                    <Headline marginTop="0" textAlign="center">
                        {title}
                    </Headline>

                    {!isMd ? (
                        <>
                            <Icon name="target" size="105px" />
                            <Paragraph
                                margin="32px 0 48px 0"
                                textAlign="center"
                            >
                                {introText}
                            </Paragraph>
                        </>
                    ) : (
                        <Paragraph margin="0 0 48px 0" textAlign="center">
                            {introText}
                        </Paragraph>
                    )}
                </Flex>
                <Headline marginTop="0" textAlign="left">
                    {reflection.subHeadline}
                </Headline>
                <Paragraph textAlign="left" margin="0 0 32px 0">
                    {mainText}
                </Paragraph>
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    marginLeft="auto"
                    marginRight="auto"
                >
                    <List
                        items={listItems}
                        numeric="true"
                        bold="true"
                        margin="0 0 10px 0"
                    ></List>
                </Flex>
                <Paragraph textAlign="left" margin="32px 0 32px 0">
                    {outroText}
                </Paragraph>
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                >
                    <Button
                        label={`${week} abschließen!`}
                        maxWidth="320px"
                        onClick={handleNextStep}
                    />
                </Flex>
            </Flex>
        </PageLayout>
    );
};

Reflection.propTypes = {
    icon: PropTypes.string.isRequired,
    week: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    introText: PropTypes.string.isRequired,
    mainText: PropTypes.string.isRequired,
    outroText: PropTypes.string.isRequired,
    listItems: PropTypes.array.isRequired,
    handleNextStep: PropTypes.func.isRequired,
};

const lang = {
    icon: 'target',
    title: 'Ein Blick zurück für einen klareren Blick nach vorn',
    introText:
        'Du hast diese erste Woche nun erfolgreich hinter dir gelassen. Bevor es weiter geht, nimm dir noch einen Augenblick und gehe für einen Moment in dich.',
    outroText:
        'Deine Woche zu reflektieren, ist ein wichtiger Bestandteil bei deiner Reise. Danke dir selbst, dass du dir die Zeit dafür genommen hast.',
    mainText:
        'Denke daran, wie du die vergangene Woche erlebt hast, wie du dich jetzt fühlst und was du von der kommenden Woche und dir selbst erwartest. Mach es dir dabei bequem, zum Beispiel bei einer Tasse Tee. Nimm dir Zeit für dich.',
    listItems: [
        'Wie hast du die vergangene Woche erlebt?',
        'Was hast du für dich selbst erreicht?',
        'Warum warst du erfolgreich oder nicht erfolgreich?',
        'Wie fühlst du dich jetzt?',
        'Willst du deine Vision weiterverfolgen?',
        'Was möchtest du in der nächsten Woche vielleicht ändern?',
        'War dein Habit der richtige oder willst du ihn in der nächsten Woche nochmal anpassen?',
        'Was erwartest du von der nächsten Woche?',
    ],
};

export const ConnectedReflection = () => {
    const { data: currentWeek } = useGetCurrentWeek();
    const { mutate: mutateCompleteWeekPlan } = usePostCompleteWeekPlan();

    const handleNextStep = () => {
        mutateCompleteWeekPlan();
    };

    if (!currentWeek) {
        return null;
    }

    return (
        <Reflection
            icon={lang.icon}
            week={`Woche ${currentWeek.weekNumber}`}
            title={lang.title}
            introText={lang.introText}
            mainText={lang.mainText}
            listItems={lang.listItems}
            handleNextStep={handleNextStep}
        />
    );
};

export default Reflection;
