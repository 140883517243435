export const COURSES = 'COURSES';
export const COURSES_SUGGESTIONS = 'COURSES_SUGGESTIONS';
export const CURRENT_COURSES = 'CURRENT_COURSES';
export const CURRENT_WEEK = 'CURRENT_WEEK';
export const TRACKED_HABITS = 'TRACKED_HABITS';
export const USER = 'USER';
export const TIPS = 'TIPS';
export const MOTIVATION_TIPS = 'MOTIVATION_TIPS';
export const REMINDER_SETTINGS = 'REMINDER_SETTINGS';
export const COMPLETED_STEPS = 'COMPLETED_STEPS';
export const CURRENT_USER = 'CURRENT_USER';
