import React from 'react';
import PropTypes from 'prop-types';

const Mountains = ({week}) => {
    return (
        <svg id="a" viewBox="0 0 376.54 333">
            <defs>
                <linearGradient
                    id="c"
                    x1="171.07"
                    y1="109.48"
                    x2="418.4"
                    y2="109.48"
                    gradientTransform="translate(-61.42 251.02) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#e73f52" />
                    <stop offset="1" stopColor="#eb6742" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1="61.43"
                    y1="41.73"
                    x2="437.96"
                    y2="41.73"
                    gradientTransform="translate(-61.42 251.02) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#e73f52" />
                    <stop offset="1" stopColor="#eb6742" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1="88.88"
                    y1="-5.19"
                    x2="258.26"
                    y2="-5.19"
                    gradientTransform="translate(-61.42 251.02) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#e73f52" />
                    <stop offset="1" stopColor="#eb6742" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1="246.13"
                    y1="57.28"
                    x2="408.57"
                    y2="57.28"
                    gradientTransform="translate(-61.42 251.02) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#e73f52" />
                    <stop offset="1" stopColor="#eb6742" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1="61.43"
                    y1="43.81"
                    x2="437.96"
                    y2="43.81"
                    gradientTransform="translate(-61.42 253.01) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#e73f52" />
                    <stop offset="1" stopColor="#eb6742" />
                </linearGradient>
            </defs>
            <g id="b">
                <path
                    d="M112.15,171.01l19.18-34.76L202.17,7.89c1.92-3.72,6.49-5.19,10.21-3.27,1.44,.74,2.6,1.92,3.32,3.37l68.88,129.43,68.86,129.44c3,5.57-.85,12.49-6.87,12.44l-139.69-1.07"
                    fill="#fff"
                    stroke="url(#c)"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="5"
                />
                <path
                    d="M315.92,242.75c2.24,4.34-.64,9.72-5.17,9.69l-105.23-.81-105.24-.81c-4.53,0-7.32-5.46-5-9.76l53.39-100,53.38-99.95c1.34-2.81,4.7-4,7.51-2.66,1.19,.57,2.14,1.54,2.69,2.74l51.83,100.85,51.84,100.71Z"
                    fill={week >= 3 ? 'url(#g)' : '#ccc'}
                />
                <path
                    d="M374.04,331.23l-365.7-.8c-4.51,0-7.31-4.15-5-7.41l53-75.65,53-75.65c2.29-3.26,7.93-3.21,10.17,.08l51.87,76.45,2.15,3.18,37.12-67.27,51.77-93.81c1.4-2.72,4.74-3.79,7.46-2.39,1.05,.54,1.9,1.4,2.43,2.46l50.33,94.6,50.32,94.6c2.16,4.07-.62,9.12-5,9.09l-102.09-.78"
                    fill="#fff"
                    stroke="url(#d)"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="5"
                />
                <path
                    d="M346.58,264.99c1.64,3.17-.46,7.11-3.78,7.08l-76.9-.59-76.91-.59c-3.32,0-5.36-4-3.68-7.14l39-73,39-73c.97-2.05,3.42-2.92,5.47-1.95,.87,.41,1.57,1.12,1.98,2l37.89,73.64,37.93,73.55Z"
                    fill={week >= 2 ? 'url(#f)' : '#f2f2f2'}
                />
                <path
                    d="M196.25,311.62c1.71,2.46-.48,5.51-3.93,5.48l-80.2-.61-80.2-.62c-3.45,0-5.59-3.1-3.84-5.54l40.5-56.58,40.53-56.61c1.75-2.13,4.91-2.44,7.04-.68,.27,.22,.51,.47,.73,.74l39.7,57.19,39.67,57.23Z"
                    fill="url(#e)"
                />
            </g>

            {/* NUMBERS */}
            <g>
                <path
                    fill="white"
                    d="M104.9,286.4v-3.6h4.6v-18c-1.7,0.8-3.5,1.5-5.3,2.1v-4.2c1.8-0.6,3.7-1.4,5.8-2.6h4.1v22.7h4.7v3.6H104.9z"
                />
            </g>
            <g>
                <path
                    fill={week >= 2 ? 'white' : 'url(#f)'}
                    d="M266.7,196.8c2.4,0,4.4,0.7,5.8,2.2c1.5,1.5,2.2,3.6,2.2,6.3c0,0.6-0.1,1.1-0.2,1.7c-0.1,0.6-0.2,1-0.4,1.5
		c-0.1,0.4-0.3,0.9-0.6,1.4c-0.3,0.5-0.5,0.9-0.7,1.2s-0.5,0.7-0.9,1.2c-0.4,0.5-0.7,0.8-0.9,1c-0.2,0.2-0.5,0.6-1,1l-5.2,5.5h9.6
		v3.8h-15.2v-3.3l7.6-8.5c0.6-0.7,1-1.2,1.3-1.4c0.2-0.3,0.5-0.6,0.9-1.1c0.4-0.5,0.6-0.9,0.8-1.2s0.3-0.7,0.4-1.1
		c0.1-0.4,0.2-0.9,0.2-1.4c0-0.3,0-0.8-0.1-1.4c-0.1-0.7-0.3-1.2-0.5-1.7s-0.5-0.8-0.9-1.1c-0.4-0.2-0.7-0.4-1.1-0.5
		c-0.4-0.1-0.9-0.2-1.4-0.2c-0.3,0-0.7,0-1,0.1c-0.4,0.1-0.7,0.1-1,0.2c-0.3,0.1-0.7,0.2-1,0.3c-0.3,0.1-0.7,0.2-0.9,0.4
		c-0.3,0.1-0.6,0.3-0.8,0.4c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.4,0.2l-0.1,0.1l-1.5-3.6
		c0.1-0.1,0.2-0.2,0.4-0.3s0.6-0.3,1.1-0.6c0.6-0.3,1.2-0.6,1.8-0.8s1.4-0.4,2.4-0.6C264.8,196.9,265.7,196.8,266.7,196.8z"
                />
            </g>
            <g>
                <path
                    fill={week >= 3 ? 'white' : 'url(#g)'}
                    d="M207.1,132.2c-1,0-2-0.1-2.9-0.2c-0.9-0.2-1.7-0.3-2.3-0.6c-0.6-0.2-1.2-0.4-1.6-0.7c-0.5-0.2-0.8-0.4-1-0.6
		l-0.3-0.2l1.4-3.5c0.2,0.1,0.5,0.3,0.9,0.5c0.5,0.2,1.2,0.5,2.2,0.8c1,0.3,2,0.5,2.9,0.5c1.6,0,2.9-0.4,3.7-1.1
		c0.9-0.7,1.3-1.8,1.3-3c0-1.4-0.6-2.4-1.8-3.1c-1.2-0.7-2.8-1-4.9-1h-0.6l-1.2,0l0-3.9h1.3c2.3,0,4-0.3,4.9-0.9
		c1-0.6,1.4-1.7,1.4-3.3c0-1-0.5-1.7-1.4-2.2c-0.9-0.5-1.9-0.8-3.1-0.8c-0.6,0-1.1,0-1.6,0.1c-0.5,0.1-1,0.2-1.4,0.4
		c-0.4,0.2-0.8,0.3-1,0.5c-0.3,0.1-0.6,0.3-0.9,0.5c-0.3,0.2-0.5,0.3-0.6,0.4l-2-3.3c1.4-0.9,2.7-1.6,3.7-2c1.1-0.4,2.6-0.6,4.5-0.6
		c1.1,0,2.2,0.1,3.2,0.4c1,0.3,1.9,0.7,2.7,1.3c0.8,0.5,1.4,1.3,1.9,2.2c0.5,0.9,0.7,1.9,0.7,3c0,0.9-0.2,1.8-0.5,2.6
		s-0.9,1.6-1.6,2.2c-0.8,0.7-1.7,1.1-2.8,1.2c1.7,0.3,3.1,1.1,4.1,2.2c1,1.2,1.5,2.6,1.5,4.1c0,1.2-0.2,2.3-0.7,3.3
		c-0.5,1-1.1,1.8-1.9,2.5c-0.8,0.7-1.7,1.2-2.8,1.5S208.3,132.2,207.1,132.2z"
                />
            </g>
        </svg>
    );
};

Mountains.propTypes = {
    week: PropTypes.number.isRequired,
};

export default Mountains;
