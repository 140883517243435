import styled from 'styled-components';

import Badge from 'shared-ui/components/Elements/Badge';
import Div from 'shared-ui/components/Layout/Div';
import {color} from 'shared-ui/styles/variables';

export const StyledBadge = styled(Badge)`
    padding: 8px 16px;
    color: ${color.grey};
`;

export const HabitWrapper = styled(Div)`
    max-width: 100%;
`;
