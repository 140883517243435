export const normalizeAccount = async (response) => {
    if (!response) return null;

    const {data} = response;

    const normalizedMap = {
        ...(data.username && {
            userName: data.username,
        }),
        ...(data.email && {
            email: data.email,
        }),
        ...(data.first_name && {
            firstName: data.first_name,
        }),
        ...(data.last_name && {
            lastName: data.last_name,
        }),
        ...(data.phone_number && {
            phoneNumber: data.phone_number,
        }),
    };
    return normalizedMap;
};
