import { useMutation } from 'react-query';
import { navigate, notify } from 'shared-utils';
import { pageRoute } from 'routes/pages';
import { completeWeekPlan } from 'services/config/endpoints';
import { makeRequest } from 'services/config/mindset-mentor-api';
import { useSetQueryData } from 'hooks/useSetQueryData';
import { setErrorMessage } from 'helpers/error-message';

const mutateCompleteWeekPlan = async () => {
    const response = await makeRequest(completeWeekPlan, 'POST');
    return response;
};

export function usePostCompleteWeekPlan() {
    const { queryClient } = useSetQueryData();
    const mutate = useMutation(mutateCompleteWeekPlan, {
        onSuccess: async () => {
            queryClient.clear();
            await queryClient.refetchQueries();
            navigate(pageRoute.bulletBoard);
        },
        onError: (error) => {
            const errorMessage = setErrorMessage(error);
            notify.show(errorMessage, 'error');
        },
    });
    return mutate;
}
