import PropTypes from 'prop-types';
import React, {useState} from 'react';

import header from 'mindset-mentor/src/assets/images/header/img-header-visualisierung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import {queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import ActionCard from 'components/Layout/ActionCard';
import AlertBox from 'components/Layout/AlertBox';
import CompletedChapterValues from 'components/Layout/CompletedChapterValues';

import {page, pageRoute, protectedRoutes} from 'routes/pages';

import {habitcheck2} from 'lang/global.lang';

import {useGetCoursesSuggestions} from 'services/data-hooks/course/useGetCoursesSuggestions';
import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';
import {usePatchCurrentWeek} from 'services/data-hooks/week/usePatchCurrentWeek';

import {useActiveChapter} from 'hooks/useActiveChapter';
import useCreatableInput from 'hooks/useCreatableInput';

const HabitCheck2Template = ({
    currentWeekData,
    coursesSuggestionsData,
    handleCurrentWeekMutate,
    isChapterDisabled,
}) => {
    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    const {goal: weekGoal} = currentWeekData;
    const {goal: suggestionsGoal} = coursesSuggestionsData;

    const {
        handleOnChange,
        onDragEnd,
        selectedValues,
        inputValue,
        handleOnInputChange,
        handleKeyDown,
        initialOptions,
        handleSetSelectedValues,
        isDisabled,
    } = useCreatableInput(suggestionsGoal, 1, weekGoal);

    const handleClickGoToTimePlan = () => {
        if (selectedValues.length > 0) {
            const mapped = selectedValues.map((value) => value.label).shift();
            const data = {
                goal: mapped,
            };
            handleCurrentWeekMutate(data);
        } else {
            setIsOpenLightbox(true);
        }
    };

    return (
        <PageLayout
            pageName="Habit-Check 2/2"
            pageIcon="pen"
            subLine="Habit-Check"
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {habitcheck2.mainTitle}
                </Headline>
                <Paragraph margin="0 0 16px" textAlign={textAlign}>
                    {habitcheck2.mainParagraph}
                </Paragraph>
            </Flex>
            {isChapterDisabled ? (
                <CompletedChapterValues selectedValues={selectedValues} />
            ) : (
                <>
                    {coursesSuggestionsData ? (
                        <ActionCard
                            headline={habitcheck2.actionCard.headline}
                            handleOnChange={handleOnChange}
                            onDragEnd={onDragEnd}
                            selectedValues={selectedValues}
                            inputValue={inputValue}
                            handleOnInputChange={handleOnInputChange}
                            handleKeyDown={handleKeyDown}
                            initialOptions={initialOptions}
                            handleSetSelectedValues={handleSetSelectedValues}
                            isDisabled={isDisabled}
                        />
                    ) : null}
                </>
            )}

            <Flex justifyContent="center" width="100%">
                <Button
                    label="Weiter zur Zeitplanung"
                    maxWidth="320px"
                    onClick={handleClickGoToTimePlan}
                />
            </Flex>

            <AlertBox
                setIsOpenLightbox={setIsOpenLightbox}
                isOpenLightbox={isOpenLightbox}
                text="Bitte wähle zuerst dein Ziel aus!"
                buttonHandler={() => setIsOpenLightbox(false)}
            />
        </PageLayout>
    );
};

HabitCheck2Template.propTypes = {
    currentWeekData: PropTypes.object.isRequired,
    coursesSuggestionsData: PropTypes.object.isRequired,
    handleCurrentWeekMutate: PropTypes.func.isRequired,
    isChapterDisabled: PropTypes.bool.isRequired,
};

export const ConnectedHabitCheck2Template = () => {
    const {mutate: currentWeekMutate} = usePatchCurrentWeek();
    const {data: currentWeekData} = useGetCurrentWeek();
    const {data: coursesSuggestionsData} = useGetCoursesSuggestions();
    const {isChapterDisabled} = useActiveChapter(protectedRoutes.part2Final);

    if (!currentWeekData || !coursesSuggestionsData) {
        return null;
    }

    const handleCurrentWeekMutate = (data) => {
        currentWeekMutate({
            data,
            step: 'goal',
            route: pageRoute.courseWeekItem(page.timePlan),
            isChapterDisabled,
        });
    };

    return (
        <HabitCheck2Template
            currentWeekMutate={currentWeekMutate}
            handleCurrentWeekMutate={handleCurrentWeekMutate}
            currentWeekData={currentWeekData}
            coursesSuggestionsData={coursesSuggestionsData}
            isChapterDisabled={isChapterDisabled}
        />
    );
};

export default HabitCheck2Template;