import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {navigate} from 'shared-utils';

import {pageRoute} from 'routes/pages';

import ContentItem from './ContentItem';
import {Indicator, StyledChapterCard, Wrapper} from './styles';

const ListItem = ({label, isDone, content, index, items}) => {
    const [openChapter, setOpenChapter] = useState(-1);

    const isShowTopLine = index > 0 ? items[index - 1].isDone : true;

    const handleOnClick = () => {
        if (content) {
            setOpenChapter(index === openChapter ? -1 : index);
        } else {
            navigate(pageRoute.courseWeek);
        }
    };

    return (
        <Wrapper
            key={label + index}
            showTopLine={isShowTopLine}
            showBottomLine={isDone}
        >
            <Indicator active={isShowTopLine} />

            <StyledChapterCard
                className="ap-chaptercard"
                label={label}
                isDone={isDone}
                isOpen={index === openChapter}
                onClick={handleOnClick}
            >
                {content
                    ? content.map(({label, isDone, isActive}, index) => (
                          <ContentItem
                              key={index}
                              label={label}
                              isDone={isDone}
                              isActive={isActive}
                          />
                      ))
                    : null}
            </StyledChapterCard>
        </Wrapper>
    );
};

ListItem.propTypes = {
    items: PropTypes.array,
    content: PropTypes.array,
    label: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    isDone: PropTypes.bool.isRequired,
};
ListItem.defaultProps = {
    items: null,
    content: null,
};

export default ListItem;