import {dashboardData as fitnessDashboardData} from 'data/courses/fitness-data/dashboard-data';
import {startPageData as fitnessStartPageData} from 'data/courses/fitness-data/start-page-data';
import {step1_1} from 'data/courses/steps-data/step1_1';
import {step1_2} from 'data/courses/steps-data/step1_2';
import {step1_3} from 'data/courses/steps-data/step1_3';
import {step2_1} from 'data/courses/steps-data/step2_1';
import {step2_2} from 'data/courses/steps-data/step2_2';
import {step2_3} from 'data/courses/steps-data/step2_3';
import {step2_4} from 'data/courses/steps-data/step2_4';
import {step3_1} from 'data/courses/steps-data/step3_1';
import {step3_2} from 'data/courses/steps-data/step3_2';
import {step3_3} from 'data/courses/steps-data/step3_3';
import {step3_4} from 'data/courses/steps-data/step3_4';

import FitnessStartPageImage from 'mindset-mentor/src/assets/images/header/img-header-kurs-woche-1.jpg';

export const coursesSteps = {
    '1_1': step1_1,
    '1_2': step1_2,
    '1_3': step1_3,
    '2_1': step2_1,
    '2_2': step2_2,
    '2_3': step2_3,
    '2_4': step2_4,
    '3_1': step3_1,
    '3_2': step3_2,
    '3_3': step3_3,
    '3_4': step3_4,
};

export const coursesIcons = {
    fitness: 'fitness',
    nutrition: 'recipeSelection',
    relax: 'relax',
    energy: 'energy',
    sleep: 'sleep',
    balance: 'balance',
};

export const courses = [
    {
        id: 1,
        name: 'Fitness & Bewegung',
        coverImage: FitnessStartPageImage,
        identifier: 'fitness',
        icon: coursesIcons['fitness'],
        videoId: '6281301959001',
        dashboardData: fitnessDashboardData,
        startPageData: fitnessStartPageData,
    },
    {
        id: 2,
        name: 'Ernährung',
        coverImage: FitnessStartPageImage,
        identifier: 'nutrition',
        icon: coursesIcons['nutrition'],
        videoId: '6281301959001',
        dashboardData: fitnessDashboardData,
        startPageData: fitnessStartPageData,
    },
    {
        id: 3,
        name: 'Entspannung',
        coverImage: FitnessStartPageImage,
        identifier: 'relax',
        icon: coursesIcons['relax'],
        videoId: '6281301959001',
        dashboardData: fitnessDashboardData,
        startPageData: fitnessStartPageData,
    },
    {
        id: 4,
        name: 'Energie',
        coverImage: FitnessStartPageImage,
        identifier: 'energy',
        icon: coursesIcons['energy'],
        videoId: '6281301959001',
        dashboardData: fitnessDashboardData,
        startPageData: fitnessStartPageData,
    },
    {
        id: 5,
        name: 'Schlaf',
        coverImage: FitnessStartPageImage,
        identifier: 'sleep',
        icon: coursesIcons['sleep'],
        videoId: '6281301959001',
        dashboardData: fitnessDashboardData,
        startPageData: fitnessStartPageData,
    },
    {
        id: 6,
        name: 'Emotionale Balance',
        coverImage: FitnessStartPageImage,
        identifier: 'balance',
        icon: coursesIcons['balance'],
        videoId: '6281301959001',
        dashboardData: fitnessDashboardData,
        startPageData: fitnessStartPageData,
    },
];
