import React from 'react';

import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';
import header from 'mindset-mentor/src/assets/images/header/img_header_reminder.jpg';
import Flex from 'shared-ui/components/Layout/Flex';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {color} from 'shared-ui/styles/variables';
import Link from 'components/Elements/Link';

import {StyledCard} from './styles';
import Headline from 'shared-ui/components/Elements/Headline';
import {service} from 'lang/global.lang';

const ServiceCenter = () => {
    return (
        <PageLayout pageName="Einstellungen" pageImage={header}>
            <Flex justifyContent="center" width="100%">
                <Headline type="h4" textAlign="center">
                    {service.headline}
                </Headline>
            </Flex>

            <Flex flexWrap="wrap" justifyContent="center" width="100%">
                <StyledCard
                    size="240px"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Icon name="contact" size="64px" color={color.red} />
                    <Paragraph textAlign="center">
                        {service.telIntro}
                        <Link href="tel:+49800034345">{service.tel}</Link>
                    </Paragraph>
                </StyledCard>
                <StyledCard
                    size="240px"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Icon name="mail" size="64px" color={color.red} />
                    <Paragraph textAlign="center" margin="16px 0 0 0">
                        {service.mailIntro}
                        <Link href="mailto:info@loremipsum.de">
                            {service.mail}
                        </Link>
                    </Paragraph>
                </StyledCard>
            </Flex>
        </PageLayout>
    );
};

export default ServiceCenter;
