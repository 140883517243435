import React from 'react';

export const step2_2 = {
    mainTitle: {
        fitness: 'Wie oft möchtest du deinen Habit umsetzen?',
        nutrition: 'Wie oft möchtest du deinen Habit umsetzen?',
        relax: 'Wie oft möchtest du deinen Habit umsetzen?',
        energy: 'Wie oft möchtest du deinen Habit umsetzen?',
        sleep: 'Wie oft möchtest du deinen Habit umsetzen?',
        balance: 'Wie oft möchtest du deinen Habit umsetzen?',
    },
    mainParagraph: {
        fitness:
            'Nun kannst du dein Ziel weiter konkretisieren und in verschiedene Schwierigkeitslevel einteilen. Frage dich dafür:',
        nutrition:
            'Nun kannst du dein Ziel weiter konkretisieren und in verschiedene Schwierigkeitslevel einteilen. Frage dich dafür:',
        relax: 'Nun kannst du dein Ziel weiter konkretisieren und in verschiedene Schwierigkeitslevel einteilen. Frage dich dafür:',
        energy: 'Nun kannst du dein Ziel weiter konkretisieren und in verschiedene Schwierigkeitslevel einteilen. Frage dich dafür:',
        sleep: 'Nun kannst du dein Ziel weiter konkretisieren und in verschiedene Schwierigkeitslevel einteilen. Frage dich dafür:',
        balance:
            'Nun kannst du dein Ziel weiter konkretisieren und in verschiedene Schwierigkeitslevel einteilen. Frage dich dafür:',
    },
    listItems: {
        fitness: [
            'Was kann ich fast sicher erreichen (Minimalziel)?',
            'Was ist realistisch (Optimalziel)?',
            'Welches Ziel wäre überragend (Maximalziel) für mich?',
        ],
        nutrition: [
            'Was kann ich fast sicher erreichen (Minimalziel)?',
            'Was ist realistisch (Optimalziel)?',
            'Welches Ziel wäre überragend (Maximalziel) für mich?',
        ],
        relax: [
            'Was kann ich fast sicher erreichen (Minimalziel)?',
            'Was ist realistisch (Optimalziel)?',
            'Welches Ziel wäre überragend (Maximalziel) für mich?',
        ],
        energy: [
            'Was kann ich fast sicher erreichen (Minimalziel)?',
            'Was ist realistisch (Optimalziel)?',
            'Welches Ziel wäre überragend (Maximalziel) für mich?',
        ],
        sleep: [
            'Was kann ich fast sicher erreichen (Minimalziel)?',
            'Was ist realistisch (Optimalziel)?',
            'Welches Ziel wäre überragend (Maximalziel) für mich?',
        ],
        balance: [
            'Was kann ich fast sicher erreichen (Minimalziel)?',
            'Was ist realistisch (Optimalziel)?',
            'Welches Ziel wäre überragend (Maximalziel) für mich?',
        ],
    },
    subParagraph: {
        fitness: (
            <>
                Denke daran, dass deine Ziele realistisch und umsetzbar sein
                müssen. Du solltest dich fordern, aber nicht über- oder
                unterfordern.
                <br />
                <br />
                Je nachdem welches Ziel du umgesetzt hast, erhältst du am Ende
                der Woche verschiedene Auszeichnungen.
            </>
        ),
        nutrition: (
            <>
                Denke daran, dass deine Ziele realistisch und umsetzbar sein
                müssen. Du solltest dich fordern, aber nicht über- oder
                unterfordern.
                <br />
                <br />
                Je nachdem welches Ziel du umgesetzt hast, erhältst du am Ende
                der Woche verschiedene Auszeichnungen.
            </>
        ),
        relax: (
            <>
                Denke daran, dass deine Ziele realistisch und umsetzbar sein
                müssen. Du solltest dich fordern, aber nicht über- oder
                unterfordern.
                <br />
                <br />
                Je nachdem welches Ziel du umgesetzt hast, erhältst du am Ende
                der Woche verschiedene Auszeichnungen.
            </>
        ),
        energy: (
            <>
                Denke daran, dass deine Ziele realistisch und umsetzbar sein
                müssen. Du solltest dich fordern, aber nicht über- oder
                unterfordern.
                <br />
                <br />
                Je nachdem welches Ziel du umgesetzt hast, erhältst du am Ende
                der Woche verschiedene Auszeichnungen.
            </>
        ),
        sleep: (
            <>
                Denke daran, dass deine Ziele realistisch und umsetzbar sein
                müssen. Du solltest dich fordern, aber nicht über- oder
                unterfordern.
                <br />
                <br />
                Je nachdem welches Ziel du umgesetzt hast, erhältst du am Ende
                der Woche verschiedene Auszeichnungen.
            </>
        ),
        balance: (
            <>
                Denke daran, dass deine Ziele realistisch und umsetzbar sein
                müssen. Du solltest dich fordern, aber nicht über- oder
                unterfordern.
                <br />
                <br />
                Je nachdem welches Ziel du umgesetzt hast, erhältst du am Ende
                der Woche verschiedene Auszeichnungen.
            </>
        ),
    },
    infoListItems: {
        fitness: [
            {
                icon: 'hourglass',
                text: (
                    <>
                        <strong>Optimalziel:</strong> Wie oft möchte ich meinen
                        Habit ausführen?
                    </>
                ),
            },
            {
                icon: 'calendar2',
                text: (
                    <>
                        <strong>Minimalziel:</strong> Wie oft möchte ich meinen
                        Habit mindestens ausführen?
                    </>
                ),
            },
            {
                icon: 'trophy',
                text: (
                    <>
                        <strong>Maximalziel:</strong> Was wäre für mich eine
                        überragende Zielerreichung?
                    </>
                ),
            },
        ],
        nutrition: [
            {
                icon: 'hourglass',
                text: (
                    <>
                        <strong>Optimalziel:</strong> Wie oft möchte ich meinen
                        Habit ausführen?
                    </>
                ),
            },
            {
                icon: 'calendar2',
                text: (
                    <>
                        <strong>Minimalziel:</strong> Wie oft möchte ich meinen
                        Habit mindestens ausführen?
                    </>
                ),
            },
            {
                icon: 'trophy',
                text: (
                    <>
                        <strong>Maximalziel:</strong> Was wäre für mich eine
                        überragende Zielerreichung?
                    </>
                ),
            },
        ],
        relax: [
            {
                icon: 'hourglass',
                text: (
                    <>
                        <strong>Optimalziel:</strong> Wie oft möchte ich meinen
                        Habit ausführen?
                    </>
                ),
            },
            {
                icon: 'calendar2',
                text: (
                    <>
                        <strong>Minimalziel:</strong> Wie oft möchte ich meinen
                        Habit mindestens ausführen?
                    </>
                ),
            },
            {
                icon: 'trophy',
                text: (
                    <>
                        <strong>Maximalziel:</strong> Was wäre für mich eine
                        überragende Zielerreichung?
                    </>
                ),
            },
        ],
        energy: [
            {
                icon: 'hourglass',
                text: (
                    <>
                        <strong>Optimalziel:</strong> Wie oft möchte ich meinen
                        Habit ausführen?
                    </>
                ),
            },
            {
                icon: 'calendar2',
                text: (
                    <>
                        <strong>Minimalziel:</strong> Wie oft möchte ich meinen
                        Habit mindestens ausführen?
                    </>
                ),
            },
            {
                icon: 'trophy',
                text: (
                    <>
                        <strong>Maximalziel:</strong> Was wäre für mich eine
                        überragende Zielerreichung?
                    </>
                ),
            },
        ],
        sleep: [
            {
                icon: 'hourglass',
                text: (
                    <>
                        <strong>Optimalziel:</strong> Wie oft möchte ich meinen
                        Habit ausführen?
                    </>
                ),
            },
            {
                icon: 'calendar2',
                text: (
                    <>
                        <strong>Minimalziel:</strong> Wie oft möchte ich meinen
                        Habit mindestens ausführen?
                    </>
                ),
            },
            {
                icon: 'trophy',
                text: (
                    <>
                        <strong>Maximalziel:</strong> Was wäre für mich eine
                        überragende Zielerreichung?
                    </>
                ),
            },
        ],
        balance: [
            {
                icon: 'hourglass',
                text: (
                    <>
                        <strong>Optimalziel:</strong> Wie oft möchte ich meinen
                        Habit ausführen?
                    </>
                ),
            },
            {
                icon: 'calendar2',
                text: (
                    <>
                        <strong>Minimalziel:</strong> Wie oft möchte ich meinen
                        Habit mindestens ausführen?
                    </>
                ),
            },
            {
                icon: 'trophy',
                text: (
                    <>
                        <strong>Maximalziel:</strong> Was wäre für mich eine
                        überragende Zielerreichung?
                    </>
                ),
            },
        ],
    },
};
