import PropTypes from 'prop-types';
import React from 'react';

import header from 'mindset-mentor/src/assets/images/header/img-header-visualisierung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import {queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {navigate} from 'shared-utils';

import {page, pageRoute} from 'routes/pages';

import {habitcheck1} from 'lang/global.lang';

import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';

import {StyledBadge} from './styles';

const HabitCheck1Template = ({currentWeekData}) => {
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    return (
        <PageLayout
            pageName="Habit-Check 1/2"
            pageIcon="pen"
            subLine="Habit-Check"
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {habitcheck1.mainTitle}
                </Headline>
                <Paragraph bold textAlign={textAlign}>
                    {habitcheck1.subTitle1}
                </Paragraph>
                <StyledBadge text={currentWeekData.habit} />
                <Paragraph margin="0 0 16px" textAlign={textAlign}>
                    {habitcheck1.mainParagraph}
                </Paragraph>
                <Paragraph bold>{habitcheck1.subTitle2}</Paragraph>

                <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    width="100%"
                >
                    <Button
                        label="Ja, weiter"
                        maxWidth="320px"
                        margin="16px auto"
                        onClick={() => {
                            navigate(
                                pageRoute.courseWeekItem(page.habitCheck2)
                            );
                        }}
                    />
                    <Button
                        label="Nein, zurück"
                        maxWidth="320px"
                        onClick={() => {
                            navigate(
                                pageRoute.courseWeekItem(page.individualAction)
                            );
                        }}
                        theme="outline"
                    />
                </Flex>
            </Flex>
        </PageLayout>
    );
};

HabitCheck1Template.propTypes = {
    currentWeekData: PropTypes.object.isRequired,
};

export const ConnectedHabitCheck1Template = () => {
    const {data: currentWeekData} = useGetCurrentWeek();

    if (!currentWeekData) {
        return null;
    }

    return <HabitCheck1Template currentWeekData={currentWeekData} />;
};

export default HabitCheck1Template;
