import React, {useState} from 'react';
import {navigate} from 'shared-utils';

import Headline from 'shared-ui/components/Elements/Headline';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';

import HorizontalIndicators from 'components/Elements/HorizontalIndicators';

import {page} from 'routes/pages';

import {screens} from 'lang/onboarding.lang';

import {useOnboarding} from 'context/OnboardingContext';

import {
    BackgroundImage,
    Content,
    StyledButton,
    StyledOnboarding,
} from './styles';

const Onboarding = () => {
    const [screen, setScreen] = useState(0);

    const {setIsDisableOnboarding} = useOnboarding();

    const handleScreenChange = () => {
        if (screen === 3) {
            if (setIsDisableOnboarding) {
                setIsDisableOnboarding(true);
            }
            navigate(`/${page.mainPath}`);
            localStorage.setItem('isOnboarding', 'isOnboarding');
        } else {
            setScreen((prev) => prev + 1);
        }
    };

    return (
        <StyledOnboarding>
            <BackgroundImage />
            <Content
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <HorizontalIndicators activeStep={screen} />
                <Headline type="h2" theme="white" textAlign="center">
                    {screens[screen].headline}
                </Headline>
                <Flex
                    height="100%"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Div>{screens[screen].content}</Div>

                    <StyledButton
                        label={screens[screen].buttonLabel}
                        onClick={handleScreenChange}
                    />
                </Flex>
            </Content>
        </StyledOnboarding>
    );
};

export default Onboarding;
