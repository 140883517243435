import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useMediaQuery} from 'shared-utils';

import {icons} from 'shared-ui/components/Elements/Icon';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {color, queries} from 'shared-ui/styles/variables';

import CreatableInput from 'components/Elements/CreatableInput';

import AnimatedItem from './AnimatedItem';
import {StyledCard, StyledIcon, StyledParagraph} from './styles';

const ActionCard = ({
    headline,
    iconName,
    handleOnChange,
    onDragEnd,
    selectedValues,
    inputValue,
    handleOnInputChange,
    handleKeyDown,
    initialOptions,
    handleSetSelectedValues,
    isDisabled,
}) => {
    const isXl = useMediaQuery(queries.xl);
    const inputRef = useRef(null);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Flex
                marginLeft="auto"
                marginRight="auto"
                flexDirection={isXl ? 'row' : 'column'}
                alignItems="flex-start"
                width={isXl ? '90%' : '100%'}
            >
                <StyledCard theme="secondary">
                    <StyledIcon
                        isActive
                        bg
                        bgColor={color.button.primary}
                        name={iconName}
                        size="64px"
                        color="red"
                        onClick={() => inputRef.current.focus()}
                    />
                    <StyledParagraph theme="darkBlue" bold>
                        {headline}
                    </StyledParagraph>

                    <Div marginTop="48px">
                        <Droppable
                            droppableId="EndTarget"
                            type="vision"
                            isDropDisabled={isDisabled}
                        >
                            {(provided) => (
                                <div
                                    className="target-card"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <CreatableInput
                                        inputRef={inputRef}
                                        isDisabled={true}
                                        value={selectedValues}
                                        inputValue={inputValue}
                                        menuIsOpen={false}
                                        handleOnChange={handleOnChange}
                                        handleOnInputChange={
                                            handleOnInputChange
                                        }
                                        handleKeyDown={handleKeyDown}
                                    />
                                </div>
                            )}
                        </Droppable>
                    </Div>
                </StyledCard>

                <Div marginBottom="32px">
                    <Droppable
                        droppableId="StartTarget"
                        type="vision"
                        direction="horizontal"
                    >
                        {(provided) => (
                            <div
                                style={{
                                    width: 'inherit',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {initialOptions?.map((option) => {
                                    return (
                                        <AnimatedItem
                                            key={option.id}
                                            value={option.value}
                                            id={option.id}
                                            onClick={handleSetSelectedValues}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </Droppable>
                </Div>
            </Flex>
        </DragDropContext>
    );
};

ActionCard.propTypes = {
    headline: PropTypes.string.isRequired,
    iconName: PropTypes.oneOf(Object.keys(icons)),
    handleOnChange: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
    selectedValues: PropTypes.array.isRequired,
    inputValue: PropTypes.string.isRequired,
    handleOnInputChange: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    handleSetSelectedValues: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
};

ActionCard.defaultProps = {
    headline:
        'Klicken Sie auf den Stift, um Ihre individuelle Vision aufzuschreiben oder wählen Sie diese aus.',
    iconName: 'pen',
};

export default ActionCard;
