import {useReducer} from 'react';

export const useNotificationsReducer = ({
    emailNotification = false,
    emailTime,
    smsNotification = false,
    smsTime,
    isPrivacyPolicy = true,
}) => {
    const initialNotifications = {
        emailNotification,
        emailTime,
        smsNotification,
        smsTime,
        isPrivacyPolicy,
    };
    const [notifications, setNotifications] = useReducer(
        (notifications, newData) => ({...notifications, ...newData}),
        initialNotifications
    );
    return {notifications, setNotifications};
};
