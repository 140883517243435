import Jump from 'assets/gifs/jump.gif';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { navigate } from 'shared-utils';
import header from 'mindset-mentor/src/assets/images/header/img-header-auswertung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';
import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import { queries } from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import AlertBox from 'components/Layout/AlertBox';
import { pageRoute } from 'routes/pages';
import { lang, prizes } from 'lang/global.lang';
import { useGetCurrentCourse } from 'services/data-hooks/course/useGetCurrentCourse';
import { useGetHabit } from 'services/data-hooks/habit/useGetHabit';
import { useGetCurrentWeek } from 'services/data-hooks/week/useGetCurrentWeek';
import { GifWrapper, StyledImage } from './styles';
import { useAward } from './useAward';

const AwardTemplate = ({ currentWeekData, habits, handleNextStep }) => {
    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const isMd = useMediaQuery(queries.md);

    const { awardResult } = useAward({ currentWeekData, habits });
    return (
        <PageLayout
            pageName="Auszeichnung"
            pageImage={header}
            subLine={lang.currentWeek(currentWeekData.weekNumber, 3)}
        >
            {!isMd && (
                <Headline marginTop="0">
                    {prizes[awardResult].intro(currentWeekData.firstName)}
                </Headline>
            )}

            <Flex
                flexDirection="column"
                alignItems="center"
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <GifWrapper width="100%">
                    <StyledImage src={Jump} alt="Award GIF" />
                </GifWrapper>

                {isMd && (
                    <Headline>
                        {prizes[awardResult].intro(currentWeekData.firstName)}
                    </Headline>
                )}

                <Paragraph textAlign="center">
                    {lang.daysTrained(habits ? habits.length : 'x')}
                </Paragraph>
                <Paragraph textAlign="center" margin="16px 0 0 0">
                    {prizes[awardResult].text}
                </Paragraph>

                <Flex marginTop="32px" width="100%" justifyContent="center">
                    <Button
                        label={
                            currentWeekData.weekNumber === 3
                                ? 'Kurs Beendet'
                                : lang.weekEnded(currentWeekData.weekNumber)
                        }
                        onClick={() => setIsOpenLightbox(true)}
                        maxWidth="320px"
                    />
                </Flex>
            </Flex>
            <AlertBox
                setIsOpenLightbox={setIsOpenLightbox}
                isOpenLightbox={isOpenLightbox}
                text={lang.confirmTrackedHabits}
                buttonHandler={handleNextStep}
                buttonText="Fortfahren"
            />
        </PageLayout>
    );
};

AwardTemplate.propTypes = {
    currentWeekData: PropTypes.object.isRequired,
    habits: PropTypes.array.isRequired,
    handleNextStep: PropTypes.func.isRequired,
};

export const ConnectedAwardTemplate = () => {
    const { data: currentWeekData } = useGetCurrentWeek();
    const { data: currentCourseData } = useGetCurrentCourse();
    const { data: habits } = useGetHabit();

    const handleNextStep = () => {
        navigate(pageRoute.reflection);
    };

    if (!currentWeekData || !currentCourseData) {
        return null;
    }

    return (
        <AwardTemplate
            currentWeekData={currentWeekData}
            currentCourseData={currentCourseData}
            habits={habits}
            handleNextStep={handleNextStep}
        />
    );
};

export default AwardTemplate;