export const normalizeCurrentWeek = async (response) => {
    if (!response) return null;

    const {data} = response;

    const normalizedMap = {
        ...(data.week_number && {weekNumber: data.week_number}),
        ...(data.vision && {vision: data.vision}),
        ...(data.habit && {habit: data.habit}),
        ...(data.goal && {goal: data.goal}),
        ...(data.has_increasing_amounts && {
            hasIncreasingAmounts: data.has_increasing_amounts,
        }),
        ...(data.minimum_amount && {minimumAmount: data.minimum_amount}),
        ...(data.expected_amount && {expectedAmount: data.expected_amount}),
        ...(data.maximum_amount && {maximumAmount: data.maximum_amount}),
        ...(data.obstacles && {obstacles: data.obstacles}),
        ...(data.solutions && {solutions: data.solutions}),
        ...(data.todos && {todos: data.todos}),
        ...(data.start_date && {startDate: data.start_date}),
        ...(data.email_notification && {
            emailNotification: data.email_notification,
        }),
        ...(data.email_time && {emailTime: data.email_time}),
        ...(data.sms_notification && {smsNotification: data.sms_notification}),
        ...(data.sms_time && {smsTime: data.sms_time}),
        ...(data.badge && {badge: data.badge}),
        ...(data.confirmed_at && {confirmedAt: data.confirmed_at}),
        ...(data.completed_at && {completedAt: data.completed_at}),
    };

    return normalizedMap;
};

export const unNormalizeCurrentWeek = async ({data}) => {
    if (!data) return null;
    const normalizedMap = {
        week_number: data?.weekNumber,
        vision: data?.vision,
        habit: data?.habit,
        goal: data?.goal,
        has_increasing_amounts: data?.hasIncreasingAmounts,
        minimum_amount: data?.minimumAmount,
        expected_amount: data?.expectedAmount,
        maximum_amount: data?.maximumAmount,
        obstacles: data?.obstacles,
        solutions: data?.solutions,
        todos: data?.todos,
        start_date: data?.startDate,
        email_notification: data?.emailNotification,
        email_time: data?.emailTime,
        sms_notification: data?.smsNotification,
        sms_time: data?.smsTime,
        badge: data?.badge,
        confirmed_at: data?.confirmedAt,
        completed_at: data?.completedAt,
    };
    return normalizedMap;
};
