export const normalizeCurrentCourse = async ({data}) => {
    if (!data) return null;
    const normalizedMap = {
        currentCourse: data.current_course,
        currentWeekNumber: data.current_week_number,
        badges: {
            week1:
                data.badges.week_1 === 'participation_medal'
                    ? 'participationMedal'
                    : data.badges.week_1 || null,
            week2:
                data.badges.week_2 === 'participation_medal'
                    ? 'participationMedal'
                    : data.badges.week_2 || null,
            week3:
                data.badges.week_3 === 'participation_medal'
                    ? 'participationMedal'
                    : data.badges.week_3 || null,
        },
    };
    return normalizedMap;
};
