import PropTypes from 'prop-types';
import React from 'react';
import {navigate} from 'shared-utils';

import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {Column, Row} from 'shared-ui/components/Layout/Grid/';
import {queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import List from 'components/Elements/List';
import Success from 'components/Elements/Success';
import {ConnectedHabitTracker} from 'components/Layout/HabitTracker';
import ConnectedTips from 'components/Layout/Tips';
import WeeklyOverview from 'components/Layout/WeeklyOverview';



import { pageRoute } from 'routes/pages';



import { lang } from 'lang/firstWeek-page.lang';
import { lang as globalLang } from 'lang/global.lang';



import { courses } from 'data/courses-list.data';



import { useGetCompletedSteps } from 'services/data-hooks/course/useGetCompletedSteps';
import { useGetCurrentCourse } from 'services/data-hooks/course/useGetCurrentCourse';
import { useGetCurrentWeek } from 'services/data-hooks/week/useGetCurrentWeek';



import { HabitWrapper, StyledBadge } from './styles';


const CourseBulletBoardTemplate = ({
    completedSteps,
    currentWeek,
    currentCourse,
    handleNextStep,
}) => {
    const isLg = useMediaQuery(queries.lg);

    const course = courses.find(
        (course) => course.identifier === currentCourse.currentCourse
    );

    const subLine = isLg
        ? currentWeek?.vision
            ? lang.withCourse.title
            : lang.title
        : null;

    return (
        <PageLayout
            pageName={`Kurs: ${course.name}`}
            pageIcon="fitness"
            subLine={subLine}
            pageImage={lang.withCourse.headerImage}
        >
            <Flex
                flexDirection="column"
                alignItems="center"
                width={isLg ? '80%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                {currentWeek?.vision ? (
                    <>
                        <Flex
                            flexDirection="row"
                            justifyContent={isLg ? 'center' : 'flex-start'}
                            alignItems="center"
                            width="100%"
                        >
                            <Paragraph margin="0 32px" theme="red" bold>
                                {lang.vision}
                            </Paragraph>
                            <StyledBadge text={currentWeek.vision} />
                        </Flex>
                        {!isLg && (
                            <Headline textAlign="center">
                                {lang.withCourse.title}
                            </Headline>
                        )}
                        <Paragraph
                            textAlign="center"
                            margin={isLg ? '32px 0 0 0' : null}
                        >
                            {lang.withCourse.paragraph}{' '}
                            {currentCourse?.currentWeekNumber}
                        </Paragraph>
                    </>
                ) : (
                    <>
                        {!isLg && (
                            <Headline textAlign="center">{lang.title}</Headline>
                        )}
                        <Paragraph textAlign="center">
                            {lang.welcomeText}
                        </Paragraph>
                    </>
                )}
                <HabitWrapper marginBottom="30px" marginTop="30px">
                    <ConnectedHabitTracker />
                </HabitWrapper>

                {currentWeek.startDate ? (
                    <>
                        <Paragraph textAlign="center">
                            Hast du alle Habits der vergangenen 7 Tage getrackt?
                            <br />
                            Wenn ja, kannst du deine Woche bestätigen um zur
                            Auswertung zu kommen!
                        </Paragraph>
                        <Flex
                            justifyContent="center"
                            marginBottom="40px"
                            marginTop="40px"
                        >
                            <Button
                                label="Bestätigen"
                                onClick={() => navigate(pageRoute.award)}
                                maxWidth="320px"
                                margin="0 auto"
                            />
                        </Flex>
                    </>
                ) : null}

                {currentWeek.solutions && (
                    <Div marginBottom="30px" marginTop="30px">
                        <Headline>{globalLang.weekPlan}</Headline>
                        <Row>
                            <Column cols={{sm: 12, lg: 6}}>
                                <Paragraph>
                                    {lang.solutionstoPotentialHurdles}
                                </Paragraph>
                                <List items={currentWeek?.solutions} />
                            </Column>
                            {currentWeek.todos ? (
                                <Column cols={{sm: 12, lg: 6}}>
                                    <Paragraph>
                                        {lang.TodosForPreparation}
                                    </Paragraph>
                                    <List items={currentWeek?.todos} />
                                </Column>
                            ) : null}
                        </Row>
                    </Div>
                )}
            </Flex>

            <Div
                width={isLg ? '90%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Row>
                    <Column cols={{sm: 12, xl: 6}}>
                        <Headline
                            textAlign={isLg ? 'center' : 'left'}
                            marginBottom="0"
                        >
                            Status
                        </Headline>
                        <WeeklyOverview
                            currentWeek={currentWeek}
                            completedSteps={completedSteps}
                        />
                    </Column>
                    <Column cols={{sm: 12, xl: 6}}>
                        <Headline
                            textAlign={isLg ? 'center' : 'left'}
                            marginBottom="0"
                        >
                            Deine Erfolge
                        </Headline>
                        <Flex alignItems="center" justifyContent="center">
                            <Success currentCourseData={currentCourse} />
                        </Flex>
                    </Column>
                </Row>
            </Div>

            <Flex
                marginTop="40px"
                width={isLg ? '75%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <ConnectedTips />
            </Flex>
        </PageLayout>
    );
};

CourseBulletBoardTemplate.propTypes = {
    completedSteps: PropTypes.array.isRequired,
    currentWeek: PropTypes.object.isRequired,
    currentCourse: PropTypes.object.isRequired,
    handleNextStep: PropTypes.func.isRequired,
};

export const ConnectedCourseBulletBoard = () => {
    const {data: completedSteps} = useGetCompletedSteps();
    const {data: currentWeek} = useGetCurrentWeek();
    const {data: currentCourse} = useGetCurrentCourse();

    if (!completedSteps || !currentWeek) return null;

    return (
        <CourseBulletBoardTemplate
            completedSteps={completedSteps}
            currentWeek={currentWeek}
            currentCourse={currentCourse}
        />
    );
};

export default CourseBulletBoardTemplate;