import React from 'react';
import PropTypes from 'prop-types';

import {StyledLink} from './styles';
import {font} from 'shared-ui/styles/variables';

const Link = ({href, children, textDecoration, theme, bold, target}) => {
    const color = font.color[theme];

    return (
        <StyledLink
            href={href}
            target={target}
            textDecoration={textDecoration}
            color={color}
            bold={bold}
        >
            {children}
        </StyledLink>
    );
};

Link.propTypes = {
    href: PropTypes.string,
    children: PropTypes.any,
    textDecoration: PropTypes.string,
    theme: PropTypes.oneOf(Object.keys(font.color)),
    target: PropTypes.string,
    bold: PropTypes.bool,
};

Link.defaultProps = {
    href: '',
    children: 'Link',
    textDecoration: 'none',
    theme: 'red',
    target: null,
    bold: false,
};

export default Link;
