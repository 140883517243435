import styled from 'styled-components';

import {color} from 'shared-ui/styles/variables';

export const VideoStyled = styled.div`
    border-radius: 15px;
    overflow: hidden;

    .brightcove-react-player-loader {
        width: 100%;
        height: auto;

        .vjs-big-play-button {
            background: ${color.button.primary};
            font-size: 32px;
        }
        .vjs-play-progress {
            background: ${color.button.primary};
        }
    }
`;
