import PropTypes from 'prop-types';
import React from 'react';

import {StyledInput} from './styles';

const Input = ({
    name,
    value,
    placeholder,
    onChange,
    disabled,
    type,
    theme,
    width,
    className,
    min,
    max,
}) => {
    return (
        <StyledInput
            type={type}
            name={name}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            theme={theme}
            width={width}
            min={min}
            max={max}
            className={className}
        />
    );
};

Input.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    theme: PropTypes.oneOf(['primary', 'secondary']),
    width: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    className: PropTypes.string,
};

Input.defaultProps = {
    name: 'ap-input',
    value: '',
    placeholder: '',
    onChange: () => {},
    disabled: false,
    type: 'text',
    theme: 'primary',
    width: '100%',
    min: null,
    max: null,
    className: 'ap-input',
};

export default Input;
