import {useGetCompletedSteps} from 'services/data-hooks/course/useGetCompletedSteps';

export const useActiveChapter = (chapterFinalStep) => {
    const {data: completedSteps} = useGetCompletedSteps();
    if (!completedSteps) return false;
    const isChapterDisabled = completedSteps?.some(
        (step) => step === chapterFinalStep
    );
    return {isChapterDisabled};
};
