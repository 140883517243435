import styled from 'styled-components';

export const StyledIndicators = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export const Indicator = styled.div`
    position: relative;
    width: 16px;
    height: 16px;
    margin: 16px;
    background: ${(props) => (props.isActive ? props.color : 'transparent')};
    border: 4px solid ${(props) => props.color};
    border-radius: 100px;

    &::after {
        content: '';
        position: absolute;
        left: 8px;
        height: 8px;
        width: 40px;
        background: ${(props) => props.color};
        opacity: 0.5;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
`;
