import {motion} from 'framer-motion';
import styled from 'styled-components';

import Badge from 'shared-ui/components/Elements/Badge';
import Card from 'shared-ui/components/Elements/Card';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {font, queries} from 'shared-ui/styles/variables';

export const StyledCard = styled(Card)`
    position: relative;
    padding-bottom: 32px;
    width: 100%;
    margin-bottom: 16px;

    @media ${queries.xl} {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
        margin-right: 16px;
        margin-bottom: 32px;
    }
`;

export const StyledIcon = styled(Icon)`
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 8px;
`;

export const StyledParagraph = styled(Paragraph)`
    padding-right: 80px;
`;

export const StyledBadge = styled(Badge)`
    margin: 8px;
    color: ${font.color.grey};
    padding: 8px 16px;
    font-size: ${font.size.xsmall};
`;

export const Motion = styled(motion.div)`
    cursor: pointer;
    display: inline-block;
    border-radius: 18px;
`;
