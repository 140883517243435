import React from 'react';

export const step2_1 = {
    mainTitle: {
        fitness: 'Was möchtest du diese Woche erreichen?',
        nutrition: 'Was möchtest du diese Woche erreichen?',
        relax: 'Was möchtest du diese Woche erreichen?',
        energy: 'Was möchtest du diese Woche erreichen?',
        sleep: 'Was möchtest du diese Woche erreichen?',
        balance: 'Was möchtest du diese Woche erreichen?',
    },
    mainParagraph: {
        fitness: (
            <>
                <strong>Eine neue Gewohnheit</strong>
                <br />
                <br />
                Im nächsten Schritt legst du deinen Habit für die erste Woche
                fest, welcher dich deiner Vision näherbringt.
            </>
        ),
        nutrition: (
            <>
                <strong>Eine neue Gewohnheit</strong>
                <br />
                <br />
                Im nächsten Schritt legst du deinen Habit für die erste Woche
                fest, welcher dich deiner Vision näherbringt.
            </>
        ),
        relax: (
            <>
                <strong>Eine neue Gewohnheit</strong>
                <br />
                <br />
                Im nächsten Schritt legst du deinen Habit für die erste Woche
                fest, welcher dich deiner Vision näherbringt.
            </>
        ),
        energy: (
            <>
                <strong>Eine neue Gewohnheit</strong>
                <br />
                <br />
                Im nächsten Schritt legst du deinen Habit für die erste Woche
                fest, welcher dich deiner Vision näherbringt.
            </>
        ),
        sleep: (
            <>
                <strong>Eine neue Gewohnheit</strong>
                <br />
                <br />
                Im nächsten Schritt legst du deinen Habit für die erste Woche
                fest, welcher dich deiner Vision näherbringt.
            </>
        ),
        balance: (
            <>
                <strong>Eine neue Gewohnheit</strong>
                <br />
                <br />
                Im nächsten Schritt legst du deinen Habit für die erste Woche
                fest, welcher dich deiner Vision näherbringt.
            </>
        ),
    },
    tipsText: {
        fitness: (
            <>
                Mit einem Habit werden in der Psychologie Verhaltensweisen
                bezeichnet, die mit der Zeit zu Gewohnheiten werden. Das
                jeweilige Verhalten wird dabei nach häufigen Wiederholungen so
                in den Alltag integriert, dass nicht mehr darüber nachgedacht
                werden muss. Es läuft also beinahe automatisch ab.
                <sup>1</sup>
            </>
        ),
        nutrition: (
            <>
                Mit einem Habit werden in der Psychologie Verhaltensweisen
                bezeichnet, die mit der Zeit zu Gewohnheiten werden. Das
                jeweilige Verhalten wird dabei nach häufigen Wiederholungen so
                in den Alltag integriert, dass nicht mehr darüber nachgedacht
                werden muss. Es läuft also beinahe automatisch ab.
                <sup>1</sup>
            </>
        ),
        relax: (
            <>
                Mit einem Habit werden in der Psychologie Verhaltensweisen
                bezeichnet, die mit der Zeit zu Gewohnheiten werden. Das
                jeweilige Verhalten wird dabei nach häufigen Wiederholungen so
                in den Alltag integriert, dass nicht mehr darüber nachgedacht
                werden muss. Es läuft also beinahe automatisch ab.
                <sup>1</sup>
            </>
        ),
        energy: (
            <>
                Mit einem Habit werden in der Psychologie Verhaltensweisen
                bezeichnet, die mit der Zeit zu Gewohnheiten werden. Das
                jeweilige Verhalten wird dabei nach häufigen Wiederholungen so
                in den Alltag integriert, dass nicht mehr darüber nachgedacht
                werden muss. Es läuft also beinahe automatisch ab.
                <sup>1</sup>
            </>
        ),
        sleep: (
            <>
                Mit einem Habit werden in der Psychologie Verhaltensweisen
                bezeichnet, die mit der Zeit zu Gewohnheiten werden. Das
                jeweilige Verhalten wird dabei nach häufigen Wiederholungen so
                in den Alltag integriert, dass nicht mehr darüber nachgedacht
                werden muss. Es läuft also beinahe automatisch ab.
                <sup>1</sup>
            </>
        ),
        balance: (
            <>
                Mit einem Habit werden in der Psychologie Verhaltensweisen
                bezeichnet, die mit der Zeit zu Gewohnheiten werden. Das
                jeweilige Verhalten wird dabei nach häufigen Wiederholungen so
                in den Alltag integriert, dass nicht mehr darüber nachgedacht
                werden muss. Es läuft also beinahe automatisch ab.
                <sup>1</sup>
            </>
        ),
    },
    mainDescription: {
        fitness: (
            <>
                Welchen Habit möchtest du dir aneignen?
                <br />
                <br />
                Mit diesem Tool lernst du eine neue Handlung regelmäßig
                durchzuführen, sodass sie in wenigen Wochen zu einer Gewohnheit
                wird. Was soll dein neuer Habit sein? Eventuell hast du schon
                eine Handlung im Kopf, die du gerne regelmäßiger im Alltag
                durchführen möchtest. Weiter unten findest du auch einige
                Vorschläge.
                <br />
                <br />
                <strong>
                    Wichtig! Bitte beachte, dass dieses Tool nicht dazu geeignet
                    ist, sich eine Handlung abzugewöhnen, zum Beispiel das
                    Rauchen. Hierfür gibt es andere spezialisierte Hilfsmittel
                    und Angebote. Sprich dazu bei Bedarf gerne dein
                    Ärzte*Ärztinnen-Team an.
                </strong>
            </>
        ),
        nutrition: (
            <>
                Welchen Habit möchtest du dir aneignen?
                <br />
                <br />
                Mit diesem Tool lernst du eine neue Handlung regelmäßig
                durchzuführen, sodass sie in wenigen Wochen zu einer Gewohnheit
                wird. Was soll dein neuer Habit sein? Eventuell hast du schon
                eine Handlung im Kopf, die du gerne regelmäßiger im Alltag
                durchführen möchtest. Weiter unten findest du auch einige
                Vorschläge.
                <br />
                <br />
                <strong>
                    Wichtig! Bitte beachte, dass dieses Tool nicht dazu geeignet
                    ist, sich eine Handlung abzugewöhnen, zum Beispiel das
                    Rauchen. Hierfür gibt es andere spezialisierte Hilfsmittel
                    und Angebote. Sprich dazu bei Bedarf gerne dein
                    Ärzte*Ärztinnen-Team an.
                </strong>
            </>
        ),
        relax: (
            <>
                Welchen Habit möchtest du dir aneignen?
                <br />
                <br />
                Mit diesem Tool lernst du eine neue Handlung regelmäßig
                durchzuführen, sodass sie in wenigen Wochen zu einer Gewohnheit
                wird. Was soll dein neuer Habit sein? Eventuell hast du schon
                eine Handlung im Kopf, die du gerne regelmäßiger im Alltag
                durchführen möchtest. Weiter unten findest du auch einige
                Vorschläge.
                <br />
                <br />
                <strong>
                    Wichtig! Bitte beachte, dass dieses Tool nicht dazu geeignet
                    ist, sich eine Handlung abzugewöhnen, zum Beispiel das
                    Rauchen. Hierfür gibt es andere spezialisierte Hilfsmittel
                    und Angebote. Sprich dazu bei Bedarf gerne dein
                    Ärzte*Ärztinnen-Team an.
                </strong>
            </>
        ),
        energy: (
            <>
                Welchen Habit möchtest du dir aneignen?
                <br />
                <br />
                Mit diesem Tool lernst du eine neue Handlung regelmäßig
                durchzuführen, sodass sie in wenigen Wochen zu einer Gewohnheit
                wird. Was soll dein neuer Habit sein? Eventuell hast du schon
                eine Handlung im Kopf, die du gerne regelmäßiger im Alltag
                durchführen möchtest. Weiter unten findest du auch einige
                Vorschläge.
                <br />
                <br />
                <strong>
                    Wichtig! Bitte beachte, dass dieses Tool nicht dazu geeignet
                    ist, sich eine Handlung abzugewöhnen, zum Beispiel das
                    Rauchen. Hierfür gibt es andere spezialisierte Hilfsmittel
                    und Angebote. Sprich dazu bei Bedarf gerne dein
                    Ärzte*Ärztinnen-Team an.
                </strong>
            </>
        ),
        sleep: (
            <>
                Welchen Habit möchtest du dir aneignen?
                <br />
                <br />
                Mit diesem Tool lernst du eine neue Handlung regelmäßig
                durchzuführen, sodass sie in wenigen Wochen zu einer Gewohnheit
                wird. Was soll dein neuer Habit sein? Eventuell hast du schon
                eine Handlung im Kopf, die du gerne regelmäßiger im Alltag
                durchführen möchtest. Weiter unten findest du auch einige
                Vorschläge.
                <br />
                <br />
                <strong>
                    Wichtig! Bitte beachte, dass dieses Tool nicht dazu geeignet
                    ist, sich eine Handlung abzugewöhnen, zum Beispiel das
                    Rauchen. Hierfür gibt es andere spezialisierte Hilfsmittel
                    und Angebote. Sprich dazu bei Bedarf gerne dein
                    Ärzte*Ärztinnen-Team an.
                </strong>
            </>
        ),
        balance: (
            <>
                Welchen Habit möchtest du dir aneignen?
                <br />
                <br />
                Mit diesem Tool lernst du eine neue Handlung regelmäßig
                durchzuführen, sodass sie in wenigen Wochen zu einer Gewohnheit
                wird. Was soll dein neuer Habit sein? Eventuell hast du schon
                eine Handlung im Kopf, die du gerne regelmäßiger im Alltag
                durchführen möchtest. Weiter unten findest du auch einige
                Vorschläge.
                <br />
                <br />
                <strong>
                    Wichtig! Bitte beachte, dass dieses Tool nicht dazu geeignet
                    ist, sich eine Handlung abzugewöhnen, zum Beispiel das
                    Rauchen. Hierfür gibt es andere spezialisierte Hilfsmittel
                    und Angebote. Sprich dazu bei Bedarf gerne dein
                    Ärzte*Ärztinnen-Team an.
                </strong>
            </>
        ),
    },
    infoBoxText: {
        fitness: (
            <>
                <strong>Hinweis:</strong>
                <br />
                Die Umsetzung deiner Habits erfolgt auf eigenes Risiko. Stimme
                dein Ziel gegebenenfalls mit deinem behandelnden ärztlichen
                Fachpersonal ab und erkundige dich, wie oft du eine bestimmte
                Übung ausführen solltest.
            </>
        ),
        nutrition: (
            <>
                <strong>Hinweis:</strong>
                <br />
                Die Umsetzung deiner Habits erfolgt auf eigenes Risiko. Stimme
                dein Ziel gegebenenfalls mit deinem behandelnden ärztlichen
                Fachpersonal ab und erkundige dich, wie oft du eine bestimmte
                Übung ausführen solltest.
            </>
        ),
        relax: (
            <>
                <strong>Hinweis:</strong>
                <br />
                Die Umsetzung deiner Habits erfolgt auf eigenes Risiko. Stimme
                dein Ziel gegebenenfalls mit deinem behandelnden ärztlichen
                Fachpersonal ab und erkundige dich, wie oft du eine bestimmte
                Übung ausführen solltest.
            </>
        ),
        energy: (
            <>
                <strong>Hinweis:</strong>
                <br />
                Die Umsetzung deiner Habits erfolgt auf eigenes Risiko. Stimme
                dein Ziel gegebenenfalls mit deinem behandelnden ärztlichen
                Fachpersonal ab und erkundige dich, wie oft du eine bestimmte
                Übung ausführen solltest.
            </>
        ),
        sleep: (
            <>
                <strong>Hinweis:</strong>
                <br />
                Die Umsetzung deiner Habits erfolgt auf eigenes Risiko. Stimme
                dein Ziel gegebenenfalls mit deinem behandelnden ärztlichen
                Fachpersonal ab und erkundige dich, wie oft du eine bestimmte
                Übung ausführen solltest.
            </>
        ),
        balance: (
            <>
                <strong>Hinweis:</strong>
                <br />
                Die Umsetzung deiner Habits erfolgt auf eigenes Risiko. Stimme
                dein Ziel gegebenenfalls mit deinem behandelnden ärztlichen
                Fachpersonal ab und erkundige dich, wie oft du eine bestimmte
                Übung ausführen solltest.
            </>
        ),
    },
    habitText: {
        fitness: (
            <>
                <strong>Was ist diese Woche dein Habit?</strong>
                <br />
                <br />
                Was möchtest du nächste Woche öfter machen? Trage deinen Habit
                in das Textfeld ein. Du kannst dich alternativ an den
                Vorschlägen bedienen.
            </>
        ),
        nutrition: (
            <>
                <strong>Was ist diese Woche dein Habit?</strong>
                <br />
                <br />
                Was möchtest du nächste Woche öfter machen? Trage deinen Habit
                in das Textfeld ein. Du kannst dich alternativ an den
                Vorschlägen bedienen.
            </>
        ),
        relax: (
            <>
                <strong>Was ist diese Woche dein Habit?</strong>
                <br />
                <br />
                Was möchtest du nächste Woche öfter machen? Trage deinen Habit
                in das Textfeld ein. Du kannst dich alternativ an den
                Vorschlägen bedienen.
            </>
        ),
        energy: (
            <>
                <strong>Was ist diese Woche dein Habit?</strong>
                <br />
                <br />
                Was möchtest du nächste Woche öfter machen? Trage deinen Habit
                in das Textfeld ein. Du kannst dich alternativ an den
                Vorschlägen bedienen.
            </>
        ),
        sleep: (
            <>
                <strong>Was ist diese Woche dein Habit?</strong>
                <br />
                <br />
                Was möchtest du nächste Woche öfter machen? Trage deinen Habit
                in das Textfeld ein. Du kannst dich alternativ an den
                Vorschlägen bedienen.
            </>
        ),
        balance: (
            <>
                <strong>Was ist diese Woche dein Habit?</strong>
                <br />
                <br />
                Was möchtest du nächste Woche öfter machen? Trage deinen Habit
                in das Textfeld ein. Du kannst dich alternativ an den
                Vorschlägen bedienen.
            </>
        ),
    },
    actionCardOptions: {
        fitness: [
            {
                id: 1,
                value: 'Mit dem Fahrrad zur Arbeit/Uni/Schule fahren',
            },
            {
                id: 2,
                value: 'Joggen gehen',
            },
            {
                id: 3,
                value: 'Einen (Online-)Sportkurs besuchen',
            },
            {
                id: 4,
                value: 'Übungen aus dem Cleo-Programm machen',
            },
            {
                id: 5,
                value: 'Spazieren gehen',
            },
            {
                id: 6,
                value: 'Yoga',
            },
            {
                id: 7,
                value: 'Schwimmen gehen',
            },
            {
                id: 8,
                value: 'Gymnastik',
            },
            {
                id: 9,
                value: 'Die Treppen nutzen statt des Aufzugs',
            },
            {
                id: 10,
                value: '10.000 Schritte am Tag schaffen',
            },
            {
                id: 11,
                value: 'Ins Fitnessstudio gehen',
            },
            {
                id: 12,
                value: 'Workout in meiner Mittagspause',
            },
            {
                id: 13,
                value: '10 Minuten Fitness am Morgen',
            },
        ],
        nutrition: [
            {
                id: 1,
                value: 'Mit dem Fahrrad zur Arbeit/Uni/Schule fahren',
            },
            {
                id: 2,
                value: 'Joggen gehen',
            },
            {
                id: 3,
                value: 'Einen (Online-)Sportkurs besuchen',
            },
            {
                id: 4,
                value: 'Übungen aus dem Cleo-Programm machen',
            },
            {
                id: 5,
                value: 'Spazieren gehen',
            },
            {
                id: 6,
                value: 'Yoga',
            },
            {
                id: 7,
                value: 'Schwimmen gehen',
            },
            {
                id: 8,
                value: 'Gymnastik',
            },
            {
                id: 9,
                value: 'Die Treppen nutzen statt des Aufzugs',
            },
            {
                id: 10,
                value: '10.000 Schritte am Tag schaffen',
            },
            {
                id: 11,
                value: 'Ins Fitnessstudio gehen',
            },
            {
                id: 12,
                value: 'Workout in meiner Mittagspause',
            },
            {
                id: 13,
                value: '10 Minuten Fitness am Morgen',
            },
        ],
        relax: [
            {
                id: 1,
                value: 'Mit dem Fahrrad zur Arbeit/Uni/Schule fahren',
            },
            {
                id: 2,
                value: 'Joggen gehen',
            },
            {
                id: 3,
                value: 'Einen (Online-)Sportkurs besuchen',
            },
            {
                id: 4,
                value: 'Übungen aus dem Cleo-Programm machen',
            },
            {
                id: 5,
                value: 'Spazieren gehen',
            },
            {
                id: 6,
                value: 'Yoga',
            },
            {
                id: 7,
                value: 'Schwimmen gehen',
            },
            {
                id: 8,
                value: 'Gymnastik',
            },
            {
                id: 9,
                value: 'Die Treppen nutzen statt des Aufzugs',
            },
            {
                id: 10,
                value: '10.000 Schritte am Tag schaffen',
            },
            {
                id: 11,
                value: 'Ins Fitnessstudio gehen',
            },
            {
                id: 12,
                value: 'Workout in meiner Mittagspause',
            },
            {
                id: 13,
                value: '10 Minuten Fitness am Morgen',
            },
        ],
        energy: [
            {
                id: 1,
                value: 'Mit dem Fahrrad zur Arbeit/Uni/Schule fahren',
            },
            {
                id: 2,
                value: 'Joggen gehen',
            },
            {
                id: 3,
                value: 'Einen (Online-)Sportkurs besuchen',
            },
            {
                id: 4,
                value: 'Übungen aus dem Cleo-Programm machen',
            },
            {
                id: 5,
                value: 'Spazieren gehen',
            },
            {
                id: 6,
                value: 'Yoga',
            },
            {
                id: 7,
                value: 'Schwimmen gehen',
            },
            {
                id: 8,
                value: 'Gymnastik',
            },
            {
                id: 9,
                value: 'Die Treppen nutzen statt des Aufzugs',
            },
            {
                id: 10,
                value: '10.000 Schritte am Tag schaffen',
            },
            {
                id: 11,
                value: 'Ins Fitnessstudio gehen',
            },
            {
                id: 12,
                value: 'Workout in meiner Mittagspause',
            },
            {
                id: 13,
                value: '10 Minuten Fitness am Morgen',
            },
        ],
        sleep: [
            {
                id: 1,
                value: 'Mit dem Fahrrad zur Arbeit/Uni/Schule fahren',
            },
            {
                id: 2,
                value: 'Joggen gehen',
            },
            {
                id: 3,
                value: 'Einen (Online-)Sportkurs besuchen',
            },
            {
                id: 4,
                value: 'Übungen aus dem Cleo-Programm machen',
            },
            {
                id: 5,
                value: 'Spazieren gehen',
            },
            {
                id: 6,
                value: 'Yoga',
            },
            {
                id: 7,
                value: 'Schwimmen gehen',
            },
            {
                id: 8,
                value: 'Gymnastik',
            },
            {
                id: 9,
                value: 'Die Treppen nutzen statt des Aufzugs',
            },
            {
                id: 10,
                value: '10.000 Schritte am Tag schaffen',
            },
            {
                id: 11,
                value: 'Ins Fitnessstudio gehen',
            },
            {
                id: 12,
                value: 'Workout in meiner Mittagspause',
            },
            {
                id: 13,
                value: '10 Minuten Fitness am Morgen',
            },
        ],
        balance: [
            {
                id: 1,
                value: 'Mit dem Fahrrad zur Arbeit/Uni/Schule fahren',
            },
            {
                id: 2,
                value: 'Joggen gehen',
            },
            {
                id: 3,
                value: 'Einen (Online-)Sportkurs besuchen',
            },
            {
                id: 4,
                value: 'Übungen aus dem Cleo-Programm machen',
            },
            {
                id: 5,
                value: 'Spazieren gehen',
            },
            {
                id: 6,
                value: 'Yoga',
            },
            {
                id: 7,
                value: 'Schwimmen gehen',
            },
            {
                id: 8,
                value: 'Gymnastik',
            },
            {
                id: 9,
                value: 'Die Treppen nutzen statt des Aufzugs',
            },
            {
                id: 10,
                value: '10.000 Schritte am Tag schaffen',
            },
            {
                id: 11,
                value: 'Ins Fitnessstudio gehen',
            },
            {
                id: 12,
                value: 'Workout in meiner Mittagspause',
            },
            {
                id: 13,
                value: '10 Minuten Fitness am Morgen',
            },
        ],
    },
    infoText: {
        fitness:
            'Wenn du Unterstützung brauchst, melde dich gerne im MS Service-Center. Immer montags bis freitags von 8 bis 20 Uhr erreichst du die MS Coaches unter der kostenlosen Rufnummer 0800 030 77 30',
        nutrition:
            'Wenn du Unterstützung brauchst, melde dich gerne im MS Service-Center. Immer montags bis freitags von 8 bis 20 Uhr erreichst du die MS Coaches unter der kostenlosen Rufnummer 0800 030 77 30',
        relax: 'Wenn du Unterstützung brauchst, melde dich gerne im MS Service-Center. Immer montags bis freitags von 8 bis 20 Uhr erreichst du die MS Coaches unter der kostenlosen Rufnummer 0800 030 77 30',
        energy: 'Wenn du Unterstützung brauchst, melde dich gerne im MS Service-Center. Immer montags bis freitags von 8 bis 20 Uhr erreichst du die MS Coaches unter der kostenlosen Rufnummer 0800 030 77 30',
        sleep: 'Wenn du Unterstützung brauchst, melde dich gerne im MS Service-Center. Immer montags bis freitags von 8 bis 20 Uhr erreichst du die MS Coaches unter der kostenlosen Rufnummer 0800 030 77 30',
        balance:
            'Wenn du Unterstützung brauchst, melde dich gerne im MS Service-Center. Immer montags bis freitags von 8 bis 20 Uhr erreichst du die MS Coaches unter der kostenlosen Rufnummer 0800 030 77 30',
    },
};
