import { useLocation } from 'shared-utils';
import { pageRoute } from 'routes/pages';
import { useGetCurrentCourse } from 'services/data-hooks/course/useGetCurrentCourse';

const nonProtectedRoutes = [
    pageRoute.courseStartPage,
    pageRoute.courseDashboard,
    pageRoute.settings,
    pageRoute.cancelCourse,
    pageRoute.award,
];

export const useProtectedRoutes = () => {
    const location = useLocation();
    const { data, isLoading } = useGetCurrentCourse();
    const urlMatches = nonProtectedRoutes.map((route) =>
        location.pathname.match(`/${route}/:identifier`)
    );
    const isNonProtected = urlMatches.some((match) => match) || !!data;
    const isNavigate =
        !isNonProtected && location.pathname !== pageRoute.mainPath;

    return { isNavigateToStart: isLoading ? false : isNavigate };
};
