import {useQuery} from 'react-query';

import {TIPS} from 'services/config/query-keys';

const mockData = {
    text: 'Mit der Funktion “KW duplizieren” kannst du deinen fertigen Wochenplan ganz einfach in eine andere Woche mitnehmen!',
};

const getTips = async () => {
    return mockData;
};

export function useGetTips() {
    return useQuery(TIPS, getTips, {
        staleTime: Infinity,
    });
}
