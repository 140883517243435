import {useMutation} from 'react-query';
import {notify} from 'shared-utils';

import {trackedHabits} from 'services/config/endpoints';
import {makeRequest} from 'services/config/mindset-mentor-api';
import {TRACKED_HABITS} from 'services/config/query-keys';
import {unNormalizeHabit} from 'services/normalize/habit';

import {useSetQueryData} from 'hooks/useSetQueryData';

import {setErrorMessage} from 'helpers/error-message';

const putHabit = async (data) => {
    const unNormalize = unNormalizeHabit(data);
    const response = await makeRequest(trackedHabits, 'PUT', unNormalize);
    return response;
};

export function usePutHabit() {
    const {setData, getData} = useSetQueryData();
    const mutate = useMutation((data) => putHabit(data), {
        onError: (error, variables, previousValue) => {
            const errorMessage = setErrorMessage(error);
            notify.show(errorMessage, 'error');
            setData([TRACKED_HABITS], previousValue);
        },
        onMutate: async (data) => {
            const previousValue = await getData([TRACKED_HABITS]);
            setData([TRACKED_HABITS], data);

            return previousValue;
        },
    });
    return mutate;
}
