import PropTypes from 'prop-types';
import React from 'react';
import {navigate} from 'shared-utils';

import header from 'mindset-mentor/src/assets/images/header/img-header-rubikon.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {color, queries} from 'shared-ui/styles/variables';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import ConnectedTips from 'components/Layout/Tips';

import {page, pageFields, pageRoute} from 'routes/pages';

import {coursesSteps} from 'data/courses-list.data';

import {usePostCompletedSteps} from 'services/data-hooks/course/usePostCompletedSteps';

import {useCurrentIdentifier} from 'hooks/useCurrentIdentifier';

const Rubikon = ({identifier, step, handleCompletedStepsMutate}) => {
    const isMd = useMediaQuery(queries.md);

    const currentCourse = coursesSteps[step];
    if (!currentCourse) return null;

    return (
        <PageLayout
            pageName="Rubikon"
            pageIcon="book"
            subLine="Dein Wochenziel steht fest"
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems="center"
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Icon name="fireWorks" color={color.red} size="80px"></Icon>
                    <Headline type="h4" textAlign="center">
                        {currentCourse.mainTitle[identifier]}
                    </Headline>
                </Flex>
                <Paragraph
                    margin="0 0 16px 0"
                    textAlign={isMd ? 'center' : 'left'}
                >
                    {currentCourse.mainParagraph[identifier]}
                </Paragraph>
                <Div marginBottom="48px" marginTop="48px">
                    <ConnectedTips />
                </Div>

                <Flex justifyContent="center">
                    <Button
                        label="Weiter zur Zielplanung"
                        maxWidth="320px"
                        onClick={handleCompletedStepsMutate}
                    />
                </Flex>
            </Flex>
        </PageLayout>
    );
};

Rubikon.propTypes = {
    identifier: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    handleCompletedStepsMutate: PropTypes.func.isRequired,
};

export const ConnectedRubikon = ({step}) => {
    const {mutate: completedStepsMutate} = usePostCompletedSteps();
    const {identifier} = useCurrentIdentifier();
    const completedStep = pageFields.chapter2.rubicon;

    if (!identifier) return null;

    const handleCompletedStepsMutate = () => {
        completedStepsMutate(completedStep, {
            onSuccess: () => {
                navigate(pageRoute.courseWeekItem(page.goalPlanning));
            },
        });
    };

    return (
        <Rubikon
            identifier={identifier}
            step={step}
            handleCompletedStepsMutate={handleCompletedStepsMutate}
        />
    );
};

ConnectedRubikon.propTypes = {
    step: PropTypes.string.isRequired,
};

export default Rubikon;
