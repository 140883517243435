import React from 'react';
import {
    Notifications,
    QueryClient,
    QueryClientProvider,
    ReactQueryDevtools,
    Router,
} from 'shared-utils';

import GlobalStyle from 'shared-ui/styles/global';

import ErrorBoundary from 'components/Elements/ErrorBoundary';
import AppLayout from 'components/Layout/AppLayout';
import ErrorPage from 'pages/404';
import AwardPage from 'pages/Award';
import CancelCourse from 'pages/CancelCourse';
import CourseBulletBoard from 'pages/CourseBulletBoard';
import CourseDashboard from 'pages/CourseDashboard';
import CourseStartPage from 'pages/CourseStartPage';
import CourseWeek from 'pages/CourseWeek';
import Visualization from 'pages/Courses/1_1_Visualization';
import IndividualVision from 'pages/Courses/1_2_IndividualVision';
import SettingGoal from 'pages/Courses/1_3_SettingGoal';
import IndividualAction from 'pages/Courses/2_1_IndividualAction';
import GoalSpeed from 'pages/Courses/2_2_GoalSpeed';
import Target from 'pages/Courses/2_3_Target';
import Rubikon from 'pages/Courses/2_4_Rubikon';
import GoalPlanning from 'pages/Courses/3_1_GoalPlanning';
import IfPlanning from 'pages/Courses/3_2_IfPlanning';
import ThenPlanning from 'pages/Courses/3_3_ThenPlanning';
import FirstSteps from 'pages/Courses/3_4_FirstSteps';
import HabitCheck1 from 'pages/Courses/HabitCheck_1';
import HabitCheck2 from 'pages/Courses/HabitCheck_2';
import EvaluationPage from 'pages/Evaluation';
import GeneratePlan from 'pages/GeneratePlan';
import Motivation from 'pages/Motivation';
import OverviewWeekStart from 'pages/OverviewWeekStart';
import ReminderSettings from 'pages/ReminderSettings';
import ServiceCenter from 'pages/ServiceCenter';
import SettingsPage from 'pages/Settings';
import CourseStartDatePage from 'pages/StartDate';
import StartPage from 'pages/StartPage';
import PreparationEnd from "pages/PreparationEnd";
import Reflection from 'pages/Reflection';


import {page} from 'routes/pages';

import {OnboardingProvider} from 'context/OnboardingContext';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            refetchOnWindowFocus: false,
        },
    },
});
const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <GlobalStyle />
                <OnboardingProvider>
                    <Router basepath={`/${page.mainPath}`}>
                        <AppLayout path="/">
                            <ErrorPage default />
                            <StartPage path="/" />
                            <SettingsPage path={page.settings} />
                            <CancelCourse path={page.cancelCourse} />
                            <ServiceCenter path={page.serviceCenter} />
                            <CourseStartPage
                                path={`${page.courseStartPage}/:identifier`}
                            />
                            <CourseDashboard
                                path={`${page.courseDashboard}/:identifier`}
                            />
                            <ReminderSettings
                                path={`${page.reminderSettings}`}
                            />
                            <CourseWeek path={page.courseWeek} />
                            <Visualization
                                path={`/${page.courseWeek}/${page.visualization}`}
                            />
                            <IndividualVision
                                path={`/${page.courseWeek}/${page.vision}`}
                            />
                            <SettingGoal
                                path={`/${page.courseWeek}/${page.lessonTarget}`}
                            />
                            <IndividualAction
                                path={`/${page.courseWeek}/${page.individualAction}`}
                            />

                            <HabitCheck1
                                path={`/${page.courseWeek}/${page.habitCheck1}`}
                            />
                            <HabitCheck2
                                path={`/${page.courseWeek}/${page.habitCheck2}`}
                            />
                            <GoalSpeed
                                path={`/${page.courseWeek}/${page.timePlan}`}
                            />
                            <Target
                                path={`/${page.courseWeek}/${page.smartTarget}`}
                            />
                            <Rubikon
                                path={`/${page.courseWeek}/${page.rubicon}`}
                            />
                            <GoalPlanning
                                path={`/${page.courseWeek}/${page.goalPlanning}`}
                            />
                            <IfPlanning
                                path={`/${page.courseWeek}/${page.ifPlanning}`}
                            />
                            <ThenPlanning
                                path={`/${page.courseWeek}/${page.thenPlanning}`}
                            />
                            <FirstSteps
                                path={`/${page.courseWeek}/${page.firstSteps}`}
                            />
                            <OverviewWeekStart
                                path={`/${page.courseWeek}/${page.overviewWeekStart}`}
                            />
                            <Motivation
                                path={`/${page.courseWeek}/${page.motivation}`}
                            />
                            <EvaluationPage
                                path={`/${page.courseWeek}/${page.evaluation}`}
                            />
                            <PreparationEnd
                                path={`/${page.courseWeek}/${page.preparationEnd}`}
                            />
                            <AwardPage
                                path={`/${page.courseWeek}/${page.award}`}
                            />
                            <CourseStartDatePage
                                path={`/${page.courseWeek}/${page.startDate}`}
                            />
                            <CourseBulletBoard
                                path={`/${page.courseWeek}/${page.bulletBoard}`}
                            />
                            <GeneratePlan
                                path={`/${page.courseWeek}/${page.generationPlan}`}
                            />
                            <Reflection path={`/${page.courseWeek}/${page.reflection}`} />
                        </AppLayout>
                    </Router>
                </OnboardingProvider>
            </ErrorBoundary>
            <Notifications options={{zIndex: 1010}} />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export default App;
