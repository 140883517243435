import PropTypes from 'prop-types';
import React from 'react';
import {navigate, useMediaQuery} from 'shared-utils';

import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import {queries} from 'shared-ui/styles/variables';

import ConnectedTips from 'components/Layout/ConnectedTips';

import {pageRoute} from 'routes/pages';

import {lang} from 'lang/start-page.lang';

import {useGetCourses} from 'services/data-hooks/course/useGetCourses';

import ListItem from './ListItem';
import {Layout, StyledGrid, StyledTipsGrid, Wrapper} from './styles';

const StartPageTemplate = ({activeCourses, handleCourseSelect}) => {
    const isMd = useMediaQuery(queries.md);

    return (
        <PageLayout
            pageName="Willkommen beim MINDSETmentor"
            noHeader={isMd ? true : false}
        >
            <Layout>
                <Div
                    width={isMd ? '70%' : ''}
                    marginLeft="auto"
                    marginRight="auto"
                >
                    <Paragraph
                        bold
                        textAlign="center"
                        theme="darkBlue"
                        margin="0 auto"
                    >
                        {lang.selectCourse}
                    </Paragraph>
                    <Paragraph textAlign="center" margin="16px auto">
                        {lang.addCourse}
                    </Paragraph>
                </Div>

                <Wrapper>
                    <StyledGrid>
                        {activeCourses.map(
                            ({
                                name,
                                identifier,
                                userParticipationsCount,
                                badges,
                            }) => {
                                return (
                                    <ListItem
                                        key={identifier}
                                        identifier={identifier}
                                        name={name}
                                        handleCourseSelect={handleCourseSelect}
                                        userParticipationsCount={
                                            userParticipationsCount
                                        }
                                        badges={badges}
                                    />
                                );
                            }
                        )}
                    </StyledGrid>
                </Wrapper>
                <StyledTipsGrid>
                    <ConnectedTips />
                </StyledTipsGrid>
            </Layout>
        </PageLayout>
    );
};

StartPageTemplate.propTypes = {
    activeCourses: PropTypes.array.isRequired,
    handleCourseSelect: PropTypes.func.isRequired,
};

export const ConnectedStartPageTemplate = () => {
    const {data: activeCoursesData} = useGetCourses();

    const handleCourseSelect = (identifier) => {
        navigate(`${pageRoute.courseStartPage}/${identifier}`);
    };

    if (!activeCoursesData) return null;

    return (
        <StartPageTemplate
            activeCourses={activeCoursesData}
            handleCourseSelect={handleCourseSelect}
        />
    );
};

export default StartPageTemplate;