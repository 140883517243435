import React from 'react';

const Bronze = () => {
    return (
        <svg id="b-a" viewBox="0 0 40 57.73">
            <defs>
                <linearGradient
                    id="b-d"
                    x1="3.81"
                    y1="177.52"
                    x2="7.66"
                    y2="177.52"
                    gradientTransform="translate(0 207.39) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#eb6742" stopOpacity=".2" />
                    <stop offset=".1" stopColor="#eb653f" stopOpacity=".24" />
                    <stop offset=".28" stopColor="#ec6136" stopOpacity=".34" />
                    <stop offset=".49" stopColor="#ed5929" stopOpacity=".51" />
                    <stop offset=".75" stopColor="#ee4e16" stopOpacity=".74" />
                    <stop offset="1" stopColor="#ef4200" />
                </linearGradient>
                <linearGradient
                    id="b-f"
                    x1="62.4"
                    y1="175.34"
                    x2="100.63"
                    y2="175.34"
                    gradientTransform="translate(-61.42 197.71) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#b0521f" />
                    <stop offset=".21" stopColor="#cc885a" />
                    <stop offset=".41" stopColor="#f1c0a0" />
                    <stop offset=".63" stopColor="#d79d73" />
                    <stop offset=".65" stopColor="#dba179" />
                    <stop offset=".75" stopColor="#f5c1a6" />
                    <stop offset=".81" stopColor="#ffcdb7" />
                    <stop offset=".99" stopColor="#cc885a" />
                </linearGradient>
            </defs>
            <g id="b-b">
                <g id="b-c">
                    <rect
                        x="4.61"
                        y="2.16"
                        width="3.85"
                        height="55.44"
                        fill="#fff"
                    />
                    <rect
                        x="3.81"
                        y="2.16"
                        width="3.85"
                        height="55.44"
                        fill="#eb6742"
                    />
                    <rect
                        x="3.81"
                        y="2.16"
                        width="3.85"
                        height="55.44"
                        fill="url(#b-d)"
                    />
                    <rect
                        x="3.82"
                        y="35.46"
                        width="3.84"
                        height=".78"
                        fill="#ce4c2e"
                    />
                    <circle cx="5.74" cy="3.88" r="3.42" fill="#ce4c2e" />
                    <circle cx="5.74" cy="3.46" r="3.42" fill="#eb6742" />
                </g>
                <g id="b-e">
                    <path
                        d="M2.26,34.97c-.68,.02-1.25-.51-1.28-1.19V10.97c.03-.68,.6-1.2,1.28-1.18H37.7c.53-.02,1.01,.3,1.21,.79,.17,.44,.04,.94-.32,1.25l-10.43,9.35s-.05,.06-.06,.1c0,.04,.02,.08,.05,.11l10.74,11.61c.32,.33,.4,.81,.22,1.23-.21,.46-.68,.75-1.19,.74H2.26Z"
                        fill="url(#b-f)"
                    />
                    <path
                        d="M37.69,10.29c.37-.05,.72,.21,.77,.58,.03,.22-.05,.44-.21,.59l-10.43,9.35c-.27,.22-.3,.62-.08,.89,0,.01,.02,.02,.03,.03l10.75,11.61c.25,.29,.21,.73-.08,.97-.15,.12-.34,.18-.53,.16H2.26c-.41,.02-.75-.29-.78-.69V10.97c.03-.4,.37-.71,.78-.68,0,0,0,0,0,0H37.7m0-1H2.26c-.96-.03-1.75,.72-1.78,1.68,0,0,0,0,0,0v22.81c.03,.96,.82,1.71,1.78,1.69H37.92c.7,.01,1.34-.38,1.65-1,.26-.6,.14-1.3-.31-1.77l-10.51-11.39,10.17-9.11c.51-.45,.69-1.16,.45-1.8-.27-.69-.94-1.13-1.68-1.11h0Z"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Bronze;
