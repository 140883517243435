import React from 'react';

import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';
import Flex from 'shared-ui/components/Layout/Flex';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import {queries} from 'shared-ui/styles/variables';
import {ConnectedReminderSettings} from 'components/Layout/ReminderSettings';

import header from 'mindset-mentor/src/assets/images/header/img_header_reminder.jpg';

const ReminderSettingsTemplate = () => {
    const isMd = useMediaQuery(queries.md);

    return (
        <PageLayout pageName="Reminder: Mail &amp; SMS" pageImage={header}>
            <Flex
                flexDirection="column"
                alignItems={isMd ? 'center' : 'flex-start'}
                width={isMd ? '80%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <ConnectedReminderSettings />
            </Flex>
        </PageLayout>
    );
};

export default ReminderSettingsTemplate;
