import React, {useEffect} from 'react';

import {ConnectedStartPageTemplate} from 'components/Templates/StartPage';

import {navigate} from 'shared-utils';

import {pageRoute} from 'routes/pages';

import {useGetCurrentCourse} from 'services/data-hooks/course/useGetCurrentCourse';

const StartPage = () => {
    const {data: currentCourseData} = useGetCurrentCourse();

    useEffect(() => {
        if (currentCourseData) {
            navigate(pageRoute.bulletBoard);
        }
    }, [currentCourseData]);

    return <ConnectedStartPageTemplate />;
};

export default StartPage;
