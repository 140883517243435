import PropTypes from 'prop-types';
import React from 'react';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {color} from 'shared-ui/styles/variables';

import {Container, StyledFlex, StyledIcon} from './styles';

const InfoListing = ({items, iconShadow}) => {
    return (
        <StyledFlex justifyContent="space-around">
            {items.map(({icon, text}, index) => {
                return (
                    <Container
                        key={icon + index}
                        alignItems="center"
                        flexDirection="column"
                    >
                        <StyledIcon
                            size="64px"
                            name={icon}
                            color={color.red}
                            iconShadow={iconShadow}
                        />
                        <Paragraph
                            theme="black"
                            textAlign="center"
                            margin="16px 0 0 0"
                        >
                            {text}
                        </Paragraph>
                    </Container>
                );
            })}
        </StyledFlex>
    );
};

export default InfoListing;

InfoListing.propTypes = {
    items: PropTypes.array,
    iconShadow: PropTypes.bool,
};

InfoListing.defaultProps = {
    items: null,
    iconShadow: true,
};
