import {useQuery} from 'react-query';

import {trackedHabits} from 'services/config/endpoints';
import {makeRequest} from 'services/config/mindset-mentor-api';
import {TRACKED_HABITS} from 'services/config/query-keys';
import {normalizeHabit} from 'services/normalize/habit';

const getGetHabit = async () => {
    const response = await makeRequest(trackedHabits, 'GET');
    const normalize = normalizeHabit(response.data);
    return normalize;
};

export function useGetHabit() {
    return useQuery(TRACKED_HABITS, getGetHabit);
}
