import PropTypes from 'prop-types';
import React from 'react';

import Div from 'shared-ui/components/Layout/Div';

import Bronze from './variants/Bronze';
import Gold from './variants/Gold';
import Silver from './variants/Silver';
import White from './variants/White';

const variants = {white: White, bronze: Bronze, silver: Silver, gold: Gold};

const Flag = ({name, className}) => {
    const FlagVariant = variants[name];

    return FlagVariant ? (
        <Div className={className}>
            <FlagVariant />
        </Div>
    ) : null;
};

Flag.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
};

Flag.defaultProps = {
    className: 'ap-flag',
    name: '',
};

export default Flag;
