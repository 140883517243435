import React from 'react';
import PropTypes from 'prop-types';

import RadioButton from '../RadioButton';

import Flex from 'shared-ui/components/Layout/Flex';
import Headline from 'shared-ui/components/Elements/Headline';
import Icon, {icons} from 'shared-ui/components/Elements/Icon';
import {color} from 'shared-ui/styles/variables';

const RadioButtonGroup = ({
    items,
    name,
    label,
    iconName,
    setSelectedValue,
    selectedValue,
    onChange,
}) => {
    return (
        <Flex
            justifyContent="center"
            marginTop="32px"
            marginLeft="32px"
            marginRight="32px"
        >
            <Flex
                flexDirection="column"
                alignItems="center"
                width="128px"
                marginRight="32px"
            >
                <Icon name={iconName} color={color.red} />
                <Headline marginTop="16px">{label}</Headline>
            </Flex>

            {items.map(({value, label}, index) => {
                return (
                    <RadioButton
                        key={name + index}
                        id={name + value}
                        label={label}
                        checked={selectedValue === value}
                        name={name}
                        value={value}
                        onChange={(e) => {
                            setSelectedValue(value);
                            onChange(e);
                        }}
                    />
                );
            })}
        </Flex>
    );
};

RadioButtonGroup.propTypes = {
    items: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    iconName: PropTypes.oneOf(Object.keys(icons)),
    name: PropTypes.string,
    onChange: PropTypes.func,
    setSelectedValue: PropTypes.func,
    selectedValue: PropTypes.any,
};

RadioButtonGroup.defaultProps = {
    name: 'radio',
    selectedValue: null,
    iconName: null,
    items: [
        {label: 'Ja', value: true},
        {label: 'Nein', value: false},
    ],
    setSelectedValue: () => {},
    onChange: () => {},
};

export default RadioButtonGroup;
