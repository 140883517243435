import { useQuery } from 'react-query';



import { currentWeekCompletedSteps } from 'services/config/endpoints';
import { makeRequest } from 'services/config/mindset-mentor-api';
import { COMPLETED_STEPS } from 'services/config/query-keys';


const getCompletedSteps = async () => {
    const response = await makeRequest(currentWeekCompletedSteps, 'GET');
    const normalize = response.data;
    return normalize;
};

export function useGetCompletedSteps() {
    return useQuery([COMPLETED_STEPS], getCompletedSteps, {
        staleTime: Infinity,
    });
}