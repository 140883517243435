import PropTypes from 'prop-types';
import React from 'react';

import Headline from 'shared-ui/components/Elements/Headline';

import {courses} from 'data/courses-list.data';

import Mountains from './Mountains';
import {Container, StyledDiv, StyledFlag, StyledParagraph} from './styles';

const Success = ({currentCourseData}) => {
    const course = courses.find(
        (course) => course.identifier === currentCourseData.currentCourse
    );

    return (
        <StyledDiv marginTop="50px" marginBottom="50px">
            <Headline textAlign="center" type="h4">
                {course.name}
            </Headline>

            <Container>
                <Mountains week={currentCourseData?.currentWeekNumber} />

                <StyledParagraph theme="grey">START</StyledParagraph>
                <StyledParagraph theme="grey" top="-10px" right="0">
                    FINISH
                </StyledParagraph>

                {Object.keys(currentCourseData?.badges).map((key, index) => {
                    return (
                        <StyledFlag
                            key={key}
                            index={index}
                            name={currentCourseData?.badges[key]}
                        />
                    );
                })}
            </Container>
        </StyledDiv>
    );
};

Success.propTypes = {
    currentCourseData: PropTypes.object.isRequired,
};

export default Success;
