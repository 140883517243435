import {useQuery} from 'react-query';

import {CURRENT_USER} from 'services/config/query-keys';
import {currentUser} from 'services/config/endpoints';
import {makeRequest} from 'services/config/account-api';
import {normalizeCurrentUser} from 'services/normalize/current-user';

const getCurrentUser = async () => {
    const response = await makeRequest(currentUser, 'GET');
    const normalize = normalizeCurrentUser(response.data);
    return normalize;
};

export function useGetCurrentUser() {
    return useQuery([CURRENT_USER], getCurrentUser);
}
