import PropTypes from 'prop-types';
import React, { useState } from 'react';



import header from 'mindset-mentor/src/assets/images/header/img-header-dann-planung.jpg';
import PageLayout from 'mindset-mentor/src/components/Layout/PageLayout';



import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import { queries } from 'shared-ui/styles/variables';



import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';



import ActionCard from 'components/Layout/ActionCard';
import AlertBox from 'components/Layout/AlertBox';
import CompletedChapterValues from 'components/Layout/CompletedChapterValues';

import {pageRoute, protectedRoutes} from 'routes/pages';

import {lang} from 'lang/global.lang';

import {coursesSteps} from 'data/courses-list.data';

import {useGetCoursesSuggestions} from 'services/data-hooks/course/useGetCoursesSuggestions';
import {useGetCurrentWeek} from 'services/data-hooks/week/useGetCurrentWeek';
import {usePatchCurrentWeek} from 'services/data-hooks/week/usePatchCurrentWeek';

import {useActiveChapter} from 'hooks/useActiveChapter';
import useCreatableInput from 'hooks/useCreatableInput';
import {useCurrentIdentifier} from 'hooks/useCurrentIdentifier';

const FirstStepsTemplate = ({
    identifier,
    step,
    currentWeekData,
    coursesSuggestionsData,
    handleCurrentWeekMutate,
    isChapterDisabled,
}) => {
    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const isMd = useMediaQuery(queries.md);
    const textAlign = isMd ? 'center' : 'left';

    const {todos: weekTodos} = currentWeekData;
    const {todo: suggestionsTodos} = coursesSuggestionsData;

    const {
        handleOnChange,
        onDragEnd,
        selectedValues,
        inputValue,
        handleOnInputChange,
        handleKeyDown,
        initialOptions,
        handleSetSelectedValues,
        isDisabled,
    } = useCreatableInput(suggestionsTodos, 3, weekTodos);

    const currentCourse = coursesSteps[step];
    if (!currentCourse) return null;

    const handleClickGoToStartDate = () => {
        if (selectedValues.length > 0) {
            const data = {
                todos: selectedValues.map((value) => value.label),
            };
            handleCurrentWeekMutate(data);
        } else {
            setIsOpenLightbox(true);
        }
    };

    return (
        <PageLayout
            pageName="First Steps"
            pageIcon="pen"
            subLine="Die letzten Erledigungen, bevor es losgeht!"
            pageImage={header}
        >
            <Flex
                flexDirection="column"
                alignItems="center"
                width={isMd ? '70%' : '100%'}
                marginLeft="auto"
                marginRight="auto"
            >
                <Headline type="h4" textAlign={textAlign}>
                    {currentCourse.mainTitle[identifier]}
                </Headline>
                <Paragraph margin="0 0 32px 0" textAlign={textAlign}>
                    {currentCourse.mainParagraph[identifier]}
                </Paragraph>
            </Flex>

            <Flex
                flexDirection="column"
                alignItems="center"
                marginLeft="auto"
                marginRight="auto"
                width="100%"
            >
                {isChapterDisabled ? (
                    <CompletedChapterValues selectedValues={selectedValues} />
                ) : (
                    <>
                        {coursesSuggestionsData ? (
                            <ActionCard
                                headline={
                                    currentCourse.actionCardHeadline[identifier]
                                }
                                handleOnChange={handleOnChange}
                                onDragEnd={onDragEnd}
                                selectedValues={selectedValues}
                                inputValue={inputValue}
                                handleOnInputChange={handleOnInputChange}
                                handleKeyDown={handleKeyDown}
                                initialOptions={initialOptions}
                                handleSetSelectedValues={
                                    handleSetSelectedValues
                                }
                                isDisabled={isDisabled}
                            />
                        ) : null}
                    </>
                )}

                <Button
                    label={lang.saveAndGo()}
                    maxWidth="320px"
                    onClick={handleClickGoToStartDate}
                />
            </Flex>

            <AlertBox
                setIsOpenLightbox={setIsOpenLightbox}
                isOpenLightbox={isOpenLightbox}
                text="Bitte wähle zuerst deine Vorbereitungsschritte aus!"
                buttonHandler={() => setIsOpenLightbox(false)}
            />
        </PageLayout>
    );
};

FirstStepsTemplate.propTypes = {
    identifier: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    currentWeekData: PropTypes.object.isRequired,
    coursesSuggestionsData: PropTypes.object.isRequired,
    handleCurrentWeekMutate: PropTypes.func.isRequired,
    isChapterDisabled: PropTypes.bool.isRequired,
};

export const ConnectedFirstStepsTemplate = ({step}) => {
    const {mutate: currentWeekMutate} = usePatchCurrentWeek();
    const {data: currentWeekData} = useGetCurrentWeek();
    const {identifier} = useCurrentIdentifier();
    const {data: coursesSuggestionsData} = useGetCoursesSuggestions();
    const {isChapterDisabled} = useActiveChapter(protectedRoutes.part2Final);

    if (!currentWeekData || !coursesSuggestionsData) {
        return null;
    }

    const handleCurrentWeekMutate = (data) => {
        currentWeekMutate({
            data,
            step,
            route: pageRoute.startDate,
            isChapterDisabled,
        });
    };

    return (
        <FirstStepsTemplate
            identifier={identifier}
            step={step}
            handleCurrentWeekMutate={handleCurrentWeekMutate}
            currentWeekData={currentWeekData}
            coursesSuggestionsData={coursesSuggestionsData}
            isChapterDisabled={isChapterDisabled}
        />
    );
};

ConnectedFirstStepsTemplate.propTypes = {
    step: PropTypes.string.isRequired,
};

export default FirstStepsTemplate;