import {useQuery} from 'react-query';

import {COURSES} from 'services/config/query-keys';
import {courses} from 'services/config/endpoints';
import {normalizeCourses} from 'services/normalize/courses';
import {makeRequest} from 'services/config/mindset-mentor-api';

const getCourses = async () => {
    const response = await makeRequest(courses, 'GET');
    const normalized = await normalizeCourses(response);
    return normalized;
};

export const useGetCourses = () => useQuery([COURSES], getCourses);